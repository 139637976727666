import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import LoadingData from "../../loader/loader";
import axios from "../../axios/axios";
import "../plan-management/plan.css";
import { Box, Card, FormControl, FormLabel, Grid, Link } from "@mui/material";

const AddCredit = () => {
  const navigate = useNavigate();

  const [planData, setPlanData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState(false);
  const [creditSet, setCreditSet] = useState([]);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [partner, setPartner] = useState([]);

  const [credit, setCredit] = useState({
    partnerId: "",
    name: "",
    planId: "",
    // amount: "",
    creditAmount: "",
  });

  const [errors, setError] = useState({
    partnerIdError: false,
    planIdError: false,
    nameError: false,
    // amountError: false,
    creditAmountError: false,
  });

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification(false);
      }, 2000);
    }
  }, [notification]);

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setCredit((prevCredit) => ({
      ...prevCredit,
      [name]: value,
    }));
  };


  const getTVIPlane = async () => {
    setLoader(true);
    try {
      const res = await axios.get(
        "https://tapi.tripvaletincentives.com/get-tvi-product-details"
      );
      setPlanData(res.data.data);
    } catch (err) {
      console.error("Error fetching TVI plane data", err);
    } finally {
      setLoader(false);
    }
  };

  const getPartner = async () => {
    setLoader(true);
    try {
      const res = await axios.get("/admin/partners");
      setPartner(res.data.data);
    } catch (err) {
      console.error("Error fetching partners", err);
    } finally {
      setLoader(false);
    }
  };


  useEffect(() => {
    getTVIPlane();
    getPartner();
  }, []);

  const submitHandler = async (e) => {
    console.log(errors);
    e.preventDefault();

    if (!credit.partnerId) {
      setError({ ...errors, partnerIdError: true });
      return;
    }
    if (!credit.planId) {
      setError({ ...errors, planIdError: true });
      return;
    }
    if (!credit.name) {
      setError({ ...errors, nameError: true });
      return;
    }
    if (!credit.creditAmount) {
      setError({ ...errors, creditAmountError: true });
      return;
    }

    const paramsData = {
      partner_id: credit.partnerId,
      name: credit.name,
      plan_id: credit.planId,
      amount: 0,
      credit: parseInt(credit.creditAmount),
    };

    setLoader(true);

    try {
      const res = await axios.post("/admin/credit-plan-create", paramsData);
      setCreditSet(res.data);

      if (res.data.status === true) {
        setTimeout(() => {
          setIsRedirecting(true);
        }, 2000);
      }
    } catch (err) {
      console.error("Error submitting credit plan", err);
    } finally {
      setLoader(false);
    }

    // Reset errors
    setError({
      partnerIdError: false,
      planIdError: false,
      nameError: false,
      // amountError: false,
      creditAmountError: false,
    });

    setNotification(true);
  };

  if (isRedirecting) {
    navigate("/uses-management");
  }

  return (
    <>
      {loader && <LoadingData />}
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox className="primary_container" pt={6} pb={3}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={5}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between" // This aligns items to the left and right
                  alignItems="center" // This centers items vertically
                >
                  <MDTypography
                    variant="h5"
                    color="white"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Add Uses
                  </MDTypography>
                </MDBox>

                <MDBox py={4} px={2}>
                  {notification && (
                    <Box mb={2}>
                      <MDAlert color="error">
                        {creditSet.message ? creditSet.message : "Something went wrong!"}
                      </MDAlert>
                    </Box>
                  )}
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Select your partner<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                      <Select
                        labelId="formBasicOrganizationLabel"
                        id="formBasicOrganization"
                        name="partnerId"
                        value={credit.partnerId}
                        onChange={changeHandler}
                        disabled={loader} 
                        style={{height: 44}}
                      >
                        <MenuItem value="">Select</MenuItem>
                        {partner.map((item, i) => (
                          <MenuItem key={i} value={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>

                      {errors.partnerIdError && (
                        <MDTypography variant="caption" color="error">
                          The partner cannot be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Select your plan for uses set<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                      <Select
                        labelId="formBasicOrganizationLabel"
                        id="formBasicOrganization"
                        name="planId"
                        value={credit.planId}
                        onChange={changeHandler}
                        style={{height: 44}}
                      >
                        <MenuItem value="">Select</MenuItem>
                        {planData.map((val, i) => (
                          <MenuItem key={i} value={val.id}>
                            {val.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.planIdError && (
                        <MDTypography variant="caption" color="error">
                          The plan cannot be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Name<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                      <MDInput
                        type="text"
                        name="name"
                        value={credit.name}
                        onChange={changeHandler}
                        error={errors.nameError}
                      />
                      {errors.nameError && (
                        <MDTypography variant="caption" color="error">
                          The name field cannot be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Set default uses<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                      <MDInput
                        type="text"
                        name="creditAmount"
                        value={credit.creditAmount}
                        onChange={changeHandler}
                        error={errors.creditAmountError}
                      />
                      {errors.creditAmountError && (
                        <MDTypography variant="caption" color="error">
                          The uses field cannot be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>
                  <MDButton
                    onClick={submitHandler}
                    type="button"
                    color="info"
                  >
                    Submit
                  </MDButton>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default AddCredit;
