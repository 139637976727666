import { Link } from "react-router-dom";
import axios from "../../axios/axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from 'react-data-table-component';
import LoadingData from "../../loader/loader"
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useState, useEffect } from "react";
import {
  FormControl
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

// Data
import authorsTableData from "layouts/transactions/paydata";

const TransactionManagement = () => {
  const [loader, setLoader] = useState(false);
  const [organization, setOrganization] = useState([]);
  const [filterData, setfilterData] = useState({
    orgId: "all"
  });
  const { columns, rows, loaders, getFilterTransactions } = authorsTableData();
  const tableData = {
    columns: columns,
    data: rows
  };
  const tableStyle = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  };
  const getOrganization = async () => {
    setLoader(true);
    await axios
      .get("/admin/prompt-org-list")
      .then((res) => {
        setLoader(false);
        setOrganization(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };
  useEffect(() => {
    getOrganization();
  }, []);

  const changeHandler = (e) => {
    console.log(e);
    setfilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
    getFilterTransactions((e.target.value == 'all') ? '' : e.target.value);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox className="primary_container" pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {(loader || loaders) && <LoadingData />}
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white" sx={{ textTransform: 'uppercase' }}>
                  Transaction Management
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
              <FormControl sx={{ mx: 2, minWidth: 300 }}>
                    <InputLabel id="demo-simple-select-label" >Select Organization / Partners</InputLabel>
                    <Select 
                      labelId="demo-simple-select-label"
                      id="formBasicOrganization"
                      name="orgId"
                      value={filterData.orgId}
                      onChange={changeHandler}
                      className="member_select_box mt-2"
                      label="Select Organization / Partners"
                      style={{ height: 44 }}
                    >
                      <MenuItem key={0} value="all">All</MenuItem>
                      {organization.map((val, i) => (
                        <MenuItem key={i + 1} value={val._id}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                <DataTableExtensions {...tableData} filter={true} export={false} print={false} filterPlaceholder="Search Here..">
                  <DataTable
                    columns={columns}
                    data={rows}
                    pagination
                    customStyles={tableStyle}
                    highlightOnHover
                  />
                </DataTableExtensions>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default TransactionManagement;
