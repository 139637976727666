// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import axios from "../../axios/axios";
import Switch from "react-switch";
import moment from "moment";
import MDAlert from "components/MDAlert";
import Modal from "@mui/material/Modal";
import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Box,
  Stack
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import MDButton from "components/MDButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// Images
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

export default function orgdata() {
  const [loader, setLoader] = useState(false);
  const [organization, setOrganization] = useState([]);
  const [switchStates, setSwitchStates] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [orgDetails, setOrgDetails] = useState([]);
  const [notification, setNotification] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [nameModal, setNameModal] = useState(false)

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );

  // const Job = ({ title, description }) => (
  //   <MDBox lineHeight={1} textAlign="left">
  //     <MDTypography
  //       display="block"
  //       variant="caption"
  //       color="text"
  //       fontWeight="medium"
  //     >
  //       {title}
  //     </MDTypography>
  //     <MDTypography variant="caption">{description}</MDTypography>
  //   </MDBox>
  // );

  const getOrganization = async () => {
    setLoader(true);
    await axios
      .get("/admin/organizations")
      .then((res) => {
        setLoader(false);
        setOrganization(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };
  useEffect(() => {
    getOrganization();
  }, []);

  //org delete function
  const handleOrgDelete = () => {
    axios
      .post("/admin/delete-organization", { org_id: currentItemId })
      .then((res) => {
        if (res.data.status === true) {
          setTimeout(() => {
            closeDeleteModal();
            getOrganization();
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("deleteUser err", err);
      });
    setNotification(true);
  };
  // Status toggle function
  const handleChange = (id) => (nextChecked) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: nextChecked,
    }));

    const paramsData = {
      org_id: id,
      status: nextChecked === true ? "Active" : "Pending",
    };

    axios
      .post("/admin/update-organization", paramsData)
      .then((res) => {
        getOrganization();
      })
      .catch((err) => {
        console.log("update status err", err);
      });
  };

  useEffect(() => {
    // Initialize the switchStates with the API data
    const initialSwitchStates = organization.reduce((acc, item) => {
      acc[item._id] = item.status === "Active" ? true : false;
      return acc;
    }, {});

    setSwitchStates(initialSwitchStates);
  }, [organization]);

  const getOrgById = () => {
    setLoader(true);
    axios
      .get(`/admin/organization/${currentItemId}`)
      .then((res) => {
        setLoader(false);

        const responseData = res.data.data;

        setOrgDetails(responseData);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNameModal(false);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleClick = (event, getId) => {
    setAnchorEl(event.currentTarget);
    setCurrentItemId(getId._id);
  };

  const viewClickedName = (e, getId) => {
    setCurrentItemId(getId._id);
    setNameModal(true)
  };

  useEffect(() => {
    if (nameModal && currentItemId !== "") {
      getOrgById();
      openModal();
    }
  }, [nameModal, currentItemId])



  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // const rowsData = organization.map((item) => {
  //   return {
  //     name: <Author name={item.name} email="" />,
  //     phone: <Job title={item.phone} description="" />,
  //     email: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {item.email}
  //       </MDTypography>
  //     ),
  //     createdAt: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {moment(item.createdAt).format("MM-DD-YYYY HH:mm:ss")}
  //       </MDTypography>
  //     ),
  //     status: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         <Switch
  //           onChange={handleChange(item._id)}
  //           checked={switchStates[item._id]}
  //           className="react-switch"
  //           disabled={!item.name}
  //         />
  //       </MDTypography>
  //     ),
  //     action: (
  //       <MDBox>
  //         <IconButton
  //           aria-label="more"
  //           aria-controls="dot-menu"
  //           aria-haspopup="true"
  //           onClick={handleClick}
  //         >
  //           <MoreVertIcon />
  //         </IconButton>
  //         <Menu
  //           id="dot-menu"
  //           anchorEl={anchorEl}
  //           open={Boolean(anchorEl)}
  //           onClose={handleClose}
  //         >
  //           <MenuItem
  //             component="a"
  //             href="#"
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             mr={2}
  //             onClick={() => {
  //               openModal();
  //               getOrgById(item._id);
  //               SetViewItemId(item._id);
  //             }}
  //           >
  //             View
  //           </MenuItem>
  //           <MenuItem
  //             component={Link}
  //             to={`/organization-update/${item._id}`}
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             mr={2}
  //           >
  //             Edit
  //           </MenuItem>
  //           <MenuItem
  //             component="a"
  //             href="#"
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             onClick={() => {
  //               openDeleteModal();
  //               setDeleteItemId(item._id);
  //             }}
  //           >
  //             Delete
  //           </MenuItem>
  //         </Menu>

  //         {deleteItemId === item._id && (
  //           <Modal
  //             open={isDeleteModalOpen}
  //             onClose={closeDeleteModal}
  //             centered
  //             size="lg"
  //             aria-labelledby="modal-modal-title"
  //             aria-describedby="modal-modal-description"
  //           >
  //             <MDBox sx={style} className="email_modal org_modalll">
  //               {notification && (
  //                 <MDAlert color="info" mt="20px">
  //                   <MDTypography variant="body2" color="white">
  //                     Your organization has been deleted
  //                   </MDTypography>
  //                 </MDAlert>
  //               )}
  //               <MDTypography
  //                 className="text-center"
  //                 id="modal-modal-title"
  //                 variant="h6"
  //                 component="h2"
  //               >
  //                 Delete Confirmation
  //               </MDTypography>
  //               <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
  //                 Are you sure you want to delete this item?
  //               </MDTypography>
  //               <div className="btn__group">
  //                 <MDButton
  //                   variant="secondary"
  //                   className="yes_but"
  //                   onClick={() => {
  //                     handleOrgDelete(item._id);
  //                   }}
  //                 >
  //                   Yes
  //                 </MDButton>

  //                 <MDButton
  //                   variant="danger"
  //                   className="no_but"
  //                   onClick={() => {
  //                     closeDeleteModal();
  //                   }}
  //                 >
  //                   No
  //                 </MDButton>
  //               </div>
  //               <MDButton
  //                 variant="secondary"
  //                 className="close_buutt"
  //                 onClick={closeDeleteModal}
  //               >
  //                 X
  //               </MDButton>
  //             </MDBox>
  //           </Modal>
  //         )}
  //         {viewItemId === item._id && (
  //           <Modal
  //             open={isModalOpen}
  //             onClose={closeModal}
  //             centered
  //             size="lg"
  //             aria-labelledby="modal-modal-title"
  //             aria-describedby="modal-modal-description"
  //           >
  //             <MDBox sx={style} className="email_modal">
  //               <MDTypography
  //                 className="text-center"
  //                 id="modal-modal-title"
  //                 variant="h6"
  //                 component="h2"
  //               >
  //                 Organization Details
  //               </MDTypography>
  //               <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
  //                 <div className="typo_modal">
  //                   <h4>Org. Name : </h4>
  //                   <p>{orgDetails.name}</p>
  //                 </div>
  //                 <div className="typo_modal">
  //                   <h4>Org. Email : </h4>
  //                   <p>{orgDetails.email}</p>
  //                 </div>
  //                 <div className="typo_modal">
  //                   <h4>Org. Address : </h4>
  //                   <p>{orgDetails.address}</p>
  //                 </div>

  //                 <div className="typo_modal">
  //                   <h4>Org. Phone : </h4>
  //                   <p>{orgDetails.phone}</p>
  //                 </div>

  //                 <div className="typo_modal">
  //                   <h4>Org. Website : </h4>
  //                   <p>{orgDetails.url}</p>
  //                 </div>
  //                 <div className="typo_modal">
  //                   <h4>Org. Status : </h4>
  //                   <p>{orgDetails.status}</p>
  //                 </div>
  //                 <div className="typo_modal">
  //                   <h4>Created : </h4>
  //                   <p>
  //                     {moment(orgDetails.createdAt).format(
  //                       "MM-DD-YYYY HH:mm:ss"
  //                     )}
  //                   </p>
  //                 </div>
  //                 <br />
  //                 <MDButton
  //                   variant="secondary"
  //                   className="close_buutt"
  //                   onClick={closeModal}
  //                 >
  //                   X
  //                 </MDButton>
  //               </MDTypography>
  //             </MDBox>
  //           </Modal>
  //         )}
  //       </MDBox>
  //     ),
  //   };
  // });

  return {
    columns: [
      {
        name: "Sl No.",
        selector: (row) => row.sl_no,
        maxWidth: "80px",
        minWidth: "80px",
        sortable: true,
      },
      {
        name: 'Organization Logo',
        selector: row => {
          return (
            <div className="org_image">
              <img src={row?.profile_picture} />
            </div>
          )
        },
        // maxWidth: "120px",
        minWidth: "120px",
      },
      {
        name: "Organization Name",
        selector: (row) => {
          return (
            <MDTypography
              sx={{
                fontWeight: 'medium',
                fontSize: 14,
                textTransform: 'capitalize'
              }}
              onClick={(e) => viewClickedName(e, row)}
              variant="caption"
            >{row.name}</MDTypography>
          );
        },
        // maxWidth: "170px",
        minWidth: "170px",
        sortable: true,
      },
      {
        name: "Organization Contact Details",
        selector: (row) => {
          return (
            <MDBox lineHeight={1}>
              Email: <MDTypography variant="caption">{row.email}</MDTypography>
              <br />
              Phone: <MDTypography variant="caption">{row.phone}</MDTypography>
              <br />
              City: <MDTypography variant="caption">{row?.city}</MDTypography>
            </MDBox>
          );
        },
        // maxWidth: "250px",
        minWidth: "250px",
        sortable: true,
      },
      // {
      //   name: 'Org. Email',
      //   selector: row => row.email,
      //   width: '300px',
      //   sortable: true,
      // },
      // {
      //   name: 'Org. Phone',
      //   selector: row => row.phone,
      //   width: '200px',
      //   //sortable: true,
      // },
      // {
      //   name: 'Org. Address',
      //   selector: row => row?.address,
      //   width: '200px',
      //   //sortable: true,
      // },
      {
        name: "Content Generated by Organization",
        selector: (row) => row.content_generate,
        // maxWidth: "200px",
        minWidth: "200px",
      },
      {
        name: "Organization Total Members",
        selector: (row) => row.total_member,
        // maxWidth: "150px",
        minWidth: "150px",
        sortable: true,
      },
      {
        name: "Organization Created",
        selector: (row) => moment(row.createdAt).format("MM-DD-YYYY HH:mm:ss"),
        sortable: true,
        sortField: "created",
        // maxWidth: "170px",
        minWidth: "170px",
        sortable: true,
      },
      {
        name: "Organization Status",
        selector: (row) => {
          return (
            <Switch
              onChange={handleChange(row._id)}
              checked={switchStates[row._id]}
              className="react-switch"
              disabled={!row.name}
              onColor="#2e87ec"
            />
          );
        },
        // maxWidth: "100px",
        minWidth: "100px",
        sortable: true,
      },
      {
        name: "Action",
        selector: (row) => {
          return (
            <MDBox>
              <IconButton
                aria-label="more"
                aria-controls="dot-menu"
                aria-haspopup="true"
                onClick={(e) => handleClick(e, row)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="dot-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    openModal();
                    getOrgById();
                  }}
                >
                  View
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={`/organization-update/${currentItemId}`}
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  Edit
                </MenuItem>
                <MenuItem
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    openDeleteModal();
                  }}
                >
                  Archive
                </MenuItem>
              </Menu>

              {currentItemId === row._id && (
                <Modal
                  className="modal"
                  open={isDeleteModalOpen}
                  onClose={closeDeleteModal}
                >
                  <Paper className="modal-dialog modal-sm">
                    <Box className="modal-content">
                      <Box className="modal-header">
                        <MDTypography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Delete Confirmation
                        </MDTypography>
                        <IconButton
                          className="close_button"
                          onClick={closeDeleteModal}
                        >
                          <CloseOutlinedIcon />
                        </IconButton>
                      </Box>
                      <Box className="modal-body">
                        {notification && (
                          <MDAlert color="info" mt="20px">
                            <MDTypography variant="body2" color="white">
                              Your organization has been deleted
                            </MDTypography>
                          </MDAlert>
                        )}
                        <MDTypography id="modal-modal-description">
                          Are you sure you want to delete this item?
                        </MDTypography>
                      </Box>
                      <Box className="modal-footer">
                        <Stack spacing={2} direction="row">
                          <MDButton
                            mr={3}
                            color="info"
                            variant="text"
                            onClick={() => {
                              closeDeleteModal();
                            }}
                          >
                            No
                          </MDButton>
                          <MDButton
                            color="error"
                            onClick={() => {
                              handleOrgDelete();
                            }}
                          >
                            Yes
                          </MDButton>
                        </Stack>
                      </Box>
                    </Box>
                  </Paper>
                </Modal>
              )}
              {currentItemId === row._id && (
                <Modal
                  open={isModalOpen}
                  onClose={closeModal}
                  className="modal"
                >
                  <Paper className="modal-dialog">
                    <Box className="modal-content">
                      <Box className="modal-header">
                        <MDTypography
                          className="text-center"
                          id="modal-modal-title"
                          variant="h6"
                        >
                          Organization Details
                        </MDTypography>
                        <IconButton
                          className="close_button"
                          onClick={closeModal}
                        >
                          <CloseOutlinedIcon />
                        </IconButton>
                      </Box>
                      <Box className="modal-body">
                        <Box className="typo_modal" sx={{ mb: 2 }}>
                          <MDTypography variant="h6" sx={{ fontSize: 14 }}>Organization Name : </MDTypography>
                          <MDTypography variant="body2">{orgDetails.name}</MDTypography>
                        </Box>
                        <Box className="typo_modal" sx={{ mb: 2 }}>
                          <MDTypography variant="h6" sx={{ fontSize: 14 }}>Organization Email : </MDTypography>
                          <MDTypography variant="body2">{orgDetails.email}</MDTypography>
                        </Box>
                        <Box className="typo_modal" sx={{ mb: 2 }}>
                          <MDTypography variant="h6" sx={{ fontSize: 14 }}>Organization Address : </MDTypography>
                          <MDTypography variant="body2">{orgDetails.address}</MDTypography>
                        </Box>

                        <Box className="typo_modal" sx={{ mb: 2 }}>
                          <MDTypography variant="h6" sx={{ fontSize: 14 }}>Organization Phone : </MDTypography>
                          <MDTypography variant="body2">{orgDetails.phone}</MDTypography>
                        </Box>

                        <Box className="typo_modal" sx={{ mb: 2 }}>
                          <MDTypography variant="h6" sx={{ fontSize: 14 }}>Organization Website : </MDTypography>
                          <MDTypography variant="body2">{orgDetails.url}</MDTypography>
                        </Box>
                        <Box className="typo_modal" sx={{ mb: 2 }}>
                          <MDTypography variant="h6" sx={{ fontSize: 14 }}>Organization Status : </MDTypography>
                          <MDTypography variant="body2">{orgDetails.status}</MDTypography>
                        </Box>
                        <Box className="typo_modal" sx={{ mb: 2 }}>
                          <MDTypography variant="h6" sx={{ fontSize: 14 }}>Organization Created : </MDTypography>
                          <MDTypography variant="body2">
                            {moment(orgDetails.createdAt).format(
                              "MM-DD-YYYY HH:mm:ss"
                            )}
                          </MDTypography>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Modal>
              )}
            </MDBox>
          );
        },
        maxWidth: "100px",
        minWidth: "100px",
      },
    ],

    rows: organization,
    load: loader
  };
}
