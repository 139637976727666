import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Input, InputLabel, Button, Grid, Card, Box, FormControl, FormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import LoadingData from "../../loader/loader";
import axios from "../../axios/axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';

const AddPartner = () => {
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachFile, setattachFile] = useState("");
  const navigate = useNavigate();

  const [partner, setPartner] = useState({
    name: "",
    url: "",
    email: ""
  });
  const [errors, setError] = useState({
    nameError: false,
    emailError: false,
    urlError: false,
  });

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 2000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    setPartner({
      ...partner,
      [e.target.name]: e.target.value,
    });
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // The result attribute contains the data URL for the image
        const dataURL = reader.result;
        setSelectedFile(dataURL);
        setattachFile(file);
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const generateRandomString = (length) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };


  const submitHandler = async (e) => {
    const randomString = generateRandomString(18);
    e.preventDefault();

    if (partner.name.length === 0) {
      setError({ ...errors, nameError: true });
      return;
    }
    if (partner.email.length === 0) {
      setError({ ...errors, emailError: true });
      return;
    }
    if (partner.url.length === 0) {
      setError({ ...errors, urlError: true });
      return;
    }

    // const paramsData = {
    //   name: partner.name,
    //   url: partner.url,
    //   logo: selectedFile,
    //   api_key: partner.apiKey,
    // };

    const formData = new FormData();
    formData.append("name", partner.name)
    formData.append("logo", attachFile)
    formData.append("url", partner.url)
    formData.append("email", partner.email)
    formData.append("api_key", randomString)
    setLoader(true);
    axios
      .post("/admin/add-partner", formData)
      .then((res) => {
        setLoader(false);
        setPartner(res.data);
        if (res.data.status === true) {
          setTimeout(() => {
            setIsRedirecting(true);
          }, 2000);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });

    // reset errors
    setError({
      nameError: false,
      urlError: false,
    });

    setNotification(true);
  };

  if (isRedirecting) {
    navigate("/partner-management");
  }

  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 600,
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // };
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <>
    <DashboardNavbar />
    {loader && <LoadingData />}
    <DashboardLayout>
      <MDBox className="primary_container" pt={3} pb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={5}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white" sx={{ textTransform: 'uppercase' }}>
                  Add New Partner
                </MDTypography>
              </MDBox>            
              <Box py={4} px={2}>
                {notification && (
                  <Box mb={2}>
                    <MDAlert color="Success">
                        Your Partner is added
                    </MDAlert>
                  </Box>
                )}
                <Box mb={3}>
                  <FormControl fullWidth>
                    <FormLabel className="form-label">Partner Name<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                    <MDInput
                      type="text"
                      name="name"
                      value={partner.name}
                      onChange={changeHandler}
                      error={errors.nameError}
                    />
                    {errors.nameError && (
                      <MDTypography variant="caption" color="error">
                        The partner name can not be null
                      </MDTypography>
                    )}
                  </FormControl>
                </Box>
                <Box mb={3}>
                  <FormControl fullWidth>
                    <FormLabel className="form-label">Partner Email<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                    <MDInput
                      type="text"
                      name="email"
                      value={partner.email}
                      onChange={changeHandler}
                      error={errors.emailError}
                    />
                    {errors.emailError && (
                      <MDTypography variant="caption" color="error">
                        The partner email can not be null
                      </MDTypography>
                    )}
                  </FormControl>
                </Box>
                <Box mb={3}>
                  <FormControl fullWidth>
                    <FormLabel className="form-label">Partner Website<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                    <MDInput
                      type="text"
                      name="url"
                      value={partner.url}
                      onChange={changeHandler}
                      error={errors.urlError}
                    />
                    {errors.urlError && (
                      <MDTypography variant="caption" color="error">
                        The partner website can not be null
                      </MDTypography>
                    )}
                  </FormControl>
                </Box>
                <Box mb={3}>
                  <FormControl fullWidth>
                    <FormLabel className="form-label">Partner Logo (Optional)</FormLabel>
                    <Box display="flex" alignItems="center">
                        {selectedFile && (
                          <Box>
                            {/* <MDTypography>Selected Image:</MDTypography> */}
                              <img src={selectedFile} width={40} height={40} style={{borderRadius:'50%', objectFit: 'cover'}} />
                          </Box>
                        )}
                      <Button
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        startIcon={<BackupOutlinedIcon />}
                        onChange={handleFileChange}
                        accept="image/*"
                      >
                        Choose Logo
                        <VisuallyHiddenInput type="file" />
                      </Button>
                    </Box>
                  </FormControl>
                </Box>
                <Box>
                  <MDButton
                    onClick={submitHandler}
                    type="button"
                    color="info"
                  >
                    Submit
                  </MDButton>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    <Footer/>
    </DashboardLayout>
    </>
  );
};

export default AddPartner;
