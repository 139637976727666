import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useParams, Link, useNavigate } from "react-router-dom";
// import MultiSelect from 'react-select';
// import xlsxFile from '../../assets/sample.xlsx';

import axios from "../../axios/axios";
import { Box, Card, FormControl, FormLabel, Grid } from "@mui/material";

const UploadPrompt = () => {
    const { id } = useParams(); // Access URL parameters
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [notification, setNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [allOrgData, setallOrgData] = useState([]);
    const [orgReqData, setorgReqData] = useState([]);
    const [orgOptionData, setorgOptionData] = useState([]);
    // const [authData, setAuthData] = useState({});
    const [platformsReqData, setplatformsReqData] = useState([]);
    const [promptData, setpromptData] = useState({
        file: "",
    });
    const authData = JSON.parse(localStorage.getItem("adminAuth"));
    const [errors, setErrors] = useState({
        fileError: false,
    });

    useEffect(() => {
        if (notification === true) {
            setTimeout(() => {
                setNotification(false);
                setNotificationMsg("");
            }, 5000);
        }
    }, [notification]);

    useEffect(async () => {

    }, []);

    const changeHandler = (e) => {
        let value = e.target.files[0];
        console.log(value);
        var ext = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.') + 1);
        if (ext == 'xlsx' || ext == 'XLSX') {
            setpromptData({
                file: value
            });
        } else {
            setNotification(true);
            setNotificationMsg('Please upload valid file format')
        }
    };







    const submitHandler = async (e) => {
        e.preventDefault();
        if (promptData.file != '') {
            let formdata = new FormData();
            formdata.append("xlsx", promptData.file);

            axios
                .post("/admin/upload-prompt", formdata)
                .then((res) => {
                    console.log(res);
                    if (res.data.status === true) {
                        setNotification(true);
                        setNotificationMsg("Uploaded Successfully");
                        setIsRedirecting(true);
                    }
                })
                .catch((err) => {
                    console.log("Plan add", err);
                });
        } else {
            setNotification(true);
            setNotificationMsg('Please upload file!')
        }
    };

    if (isRedirecting) {
        navigate('/prompt-management');
    }

    return (
        <>
        <DashboardNavbar />
        <DashboardLayout>
            <MDBox className="primary_container" pt={6} pb={3}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={6}>                        
                        <Card>
                            <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center" 
                            >
                            <MDTypography
                                variant="h5"
                                color="white"
                                sx={{ textTransform: 'uppercase' }}
                            >
                                Upload Prompt
                            </MDTypography>                
                            </MDBox>                            
                            <MDBox
                                component="form"
                                role="form"
                                onSubmit={submitHandler}
                                px={2} py={3}
                            >
                                {notification && (
                                    <Box mb={2}>
                                        <MDAlert color="error">
                                            {notificationMsg}
                                        </MDAlert>
                                    </Box>
                                )}

                                <Box mb={2}>
                                    <FormControl fullWidth>
                                        <FormLabel className="form-label">Upload XLSX File</FormLabel>
                                        <MDInput
                                            type="file"
                                            fullWidth
                                            name="file"
                                            onChange={changeHandler}
                                            error={errors.fileError}
                                        />
                                        {errors.fileError && (
                                            <MDTypography
                                                variant="caption"
                                                color="error"
                                                fontWeight="light"
                                            >
                                                The file can not be null
                                            </MDTypography>
                                        )}                                        
                                    </FormControl>                                    
                                </Box>
                                <Box mb={2}>
                                    <MDTypography variant="body2" fontWeight="medium">
                                        <a href="https://gptgoodnews-prod-api.s3.us-east-2.amazonaws.com/1706171998643.xlsx"
                                        download="Example-xlsx-file"
                                        target="_blank"
                                        rel="noreferrer">Click here</a> to download sample xlsx file
                                    </MDTypography>
                                </Box>

                                <MDButton color="info" type="submit" >
                                    Upload Prompt
                                </MDButton>

                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        <Footer />
        </DashboardLayout>
        </>
    );
};

export default UploadPrompt;
