/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from 'react-data-table-component';
import LoadingData from "../../loader/loader";
import { useParams, Link, useNavigate } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

// Data
import authorsTableData from "layouts/plan-management/planData";

function PlanManagement() {
  const { columns, rows, loader } = authorsTableData();
  const tableData = {
    columns: columns,
    data: rows
  };
  const tableStyle = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  };
  return (
    <>
      {loader && <LoadingData />}
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between" // This aligns items to the left and right
                  alignItems="center" // This centers items vertically
                  sx={{flexWrap: 'wrap'}}
                >
                  <MDTypography
                    variant="h5"
                    color="white"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Plan Management
                  </MDTypography>

                  <Link to="/add-plan">
                    <MDButton
                      variant="gradient"
                      color="dark"
                      type="button"
                    >
                      Add New Plan
                    </MDButton>
                  </Link>
                </MDBox>
                <MDBox pt={3}>
                  <DataTableExtensions {...tableData} filter={true} export={false} print={false} filterPlaceholder="Search Here..">
                    <DataTable
                      columns={columns}
                      data={rows}
                      pagination
                      customStyles={tableStyle}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default PlanManagement;
