import React, { useEffect, useState } from "react";
import "./management.css";
// import { Link } from "react-router-dom";
// import axios from "../../axios/axios";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Modal from "@mui/material/Modal";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from 'react-data-table-component';
import LoadingData from "../../loader/loader";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

// Data
import authorsTableData from "layouts/organization-management/orgdata";
import Ragistration from "./registration";


const OrgManagement = () => {
  const { columns, rows, load } = authorsTableData();

  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const tableData = {
    columns: columns,
    data: rows
  };
  const tableStyle = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };
  return (
    <>
      {(load || loader) && <LoadingData />}
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox className="primary_container" pt={3} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between" 
                  alignItems="center"
                  sx={{flexWrap: 'wrap'}}
                >
                  <MDTypography 
                    variant="h5" 
                    color="white"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Organization Management
                  </MDTypography>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    type="button"
                    onClick={handleOpen}
                  >
                    Add Organization
                  </MDButton>
                  <Ragistration open={open} handleClose={handleClose} />
                </MDBox>
                <MDBox pt={3}>
                  <DataTableExtensions {...tableData} filter={true} export={false} print={false} filterPlaceholder="Search Here..">
                    <DataTable
                      className="data-table"
                      columns={columns}
                      data={rows}
                      pagination
                      customStyles={tableStyle}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default OrgManagement;
