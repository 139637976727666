// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  Menu, 
  MenuItem,
  Paper,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import axios from "../../axios/axios";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MDButton from "components/MDButton";
import moment from "moment";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Stack } from "@mui/system";

export default function data() {
  const [user, setUser] = useState([]);
  const [loader, setLoader] = useState(false);
  const [switchStates, setSwitchStates] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState([]);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [setionForNameModal, setsetionForNameModal] = useState(false);

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );

  // const Job = ({ title, description }) => (
  //   <MDBox lineHeight={1} textAlign="left">
  //     <MDTypography
  //       display="block"
  //       variant="caption"
  //       color="text"
  //       fontWeight="medium"
  //     >
  //       {title}
  //     </MDTypography>
  //     <MDTypography variant="caption">{description}</MDTypography>
  //   </MDBox>
  // );

  const getFilterUsers = async (orgIdAttr) => {
    setLoader(true);
    await axios
      .get("/admin/users/" + orgIdAttr)
      .then((res) => {
        setLoader(false);
        setUser(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const getUsers = async () => {
    setLoader(true);
    await axios
      .get("/admin/users")
      .then((res) => {
        setLoader(false);
        setUser(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const getUserById = () => {
    setLoader(true);
    axios
      .get(`/admin/user/${currentItemId}`)
      .then((res) => {
        setLoader(false);

        const responseData = res.data.data;

        setUserDetails(responseData);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleUserDelete = () => {
    axios
      .post("/admin/delete-user", { user_id: currentItemId })
      .then((res) => {
        if (res.data.status === true) {
          setTimeout(() => {
            closeDeleteModal();
            getUsers();
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("deleteUser err", err);
      });
    setNotification(true);
  };

  const handleChange = (id) => (nextChecked) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: nextChecked,
    }));

    const paramsData = {
      user_id: id,
      status: nextChecked === true ? "Active" : "Pending",
    };

    axios
      .post("/admin/update-user", paramsData)
      .then((res) => {
        getUsers();
      })
      .catch((err) => {
        console.log("update status err", err);
      });
  };

  useEffect(() => {
    // Initialize the switchStates with the API data
    const initialSwitchStates = user.reduce((acc, item) => {
      acc[item._id] = item.status === "Active" ? true : false;
      return acc;
    }, {});

    setSwitchStates(initialSwitchStates);
  }, [user]);

  const handleClick = (event, getId) => {
    setAnchorEl(event.currentTarget);
    setCurrentItemId(getId._id)
  };


  useEffect(() => {
    if (setionForNameModal && currentItemId != '') {
      getUserById();
      setIsModalOpen(true);
    }
  }, [setionForNameModal, currentItemId]);

  const memberModalOpen = (row) => {
    console.log(row._id);
    setCurrentItemId(row._id);
    setsetionForNameModal(true);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setsetionForNameModal(false);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 600,
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // };

  // const rowsData = user.map((item) => {
  //   return {
  //     user: <Author name={item.name} email={`(${item.type})`} />,
  //     organization: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {item?.org_info?.name}
  //       </MDTypography>
  //     ),
  //     phone: <Job title={item.phone} description="" />,
  //     email: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {item.email}
  //       </MDTypography>
  //     ),
  //     createdAt: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {moment(item.createdAt).format("MM-DD-YYYY HH:mm:ss")}
  //       </MDTypography>
  //     ),
  //     updatedAt: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {moment(item.updatedAt).format("MM-DD-YYYY HH:mm:ss")}
  //       </MDTypography>
  //     ),
  //     status: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         <Switch
  //           onChange={handleChange(item._id)}
  //           checked={switchStates[item._id]}
  //           className="react-switch"
  //           disabled={!item.name}
  //         />
  //       </MDTypography>
  //     ),
  //     action: (
  //       <MDBox>
  //         <IconButton
  //           aria-label="more"
  //           aria-controls="dot-menu"
  //           aria-haspopup="true"
  //           onClick={handleMenuOpen}
  //         >
  //           <MoreVertIcon />
  //         </IconButton>
  //         <Menu
  //           id="dot-menu"
  //           anchorEl={anchorEl}
  //           keepMounted
  //           open={Boolean(anchorEl)}
  //           //onClose={handleClose}
  //           onClose={handleMenuClose}
  //         >
  //           <MenuItem
  //             component="a"
  //             href="#"
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             mr={2}
  //             onClick={() => {
  //               openModal();
  //               getUserById(item._id);
  //               SetViewItemId(item._id);
  //             }}
  //           >
  //             View
  //           </MenuItem>
  //           <MenuItem
  //             component={Link}
  //             to={`/member-update/${item._id}`}
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             mr={2}
  //             onClick={() => {
  //               setEditItemId(item._id); // Set the editItemId to the current item's ID
  //             }}
  //           >
  //             Edit
  //           </MenuItem>

  //           <MenuItem
  //             component="a"
  //             href="#"
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             onClick={() => {
  //               openDeleteModal();
  //               setDeleteItemId(item._id);
  //             }}
  //           >
  //             Delete
  //           </MenuItem>
  //         </Menu>

  //         {deleteItemId === item._id && (
  //           <Modal
  //             open={isDeleteModalOpen}
  //             onClose={closeDeleteModal}
  //             centered
  //             size="lg"
  //             aria-labelledby="modal-modal-title"
  //             aria-describedby="modal-modal-description"
  //           >
  //             <MDBox sx={style} className="email_modal org_modalll">
  //               {notification && (
  //                 <MDAlert color="info" mt="20px">
  //                   <MDTypography variant="body2" color="white">
  //                     Your member has been deleted
  //                   </MDTypography>
  //                 </MDAlert>
  //               )}
  //               <MDTypography
  //                 className="text-center"
  //                 id="modal-modal-title"
  //                 variant="h6"
  //                 component="h2"
  //               >
  //                 Delete Confirmation
  //               </MDTypography>
  //               <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
  //                 Are you sure you want to delete this item?
  //               </MDTypography>
  //               <div className="btn__group">
  //                 <MDButton
  //                   variant="secondary"
  //                   className="yes_but"
  //                   onClick={() => {
  //                     handleUserDelete(item._id);
  //                   }}
  //                 >
  //                   Yes
  //                 </MDButton>
  //                 <MDButton
  //                   variant="danger"
  //                   className="no_but"
  //                   onClick={() => {
  //                     closeDeleteModal();
  //                   }}
  //                 >
  //                   No
  //                 </MDButton>
  //               </div>
  //               <MDButton
  //                 variant="secondary"
  //                 className="close_buutt"
  //                 onClick={closeDeleteModal}
  //               >
  //                 X
  //               </MDButton>
  //             </MDBox>
  //           </Modal>
  //         )}
  //         {viewItemId === item._id && (
  //           <Modal
  //             open={isModalOpen}
  //             onClose={closeModal}
  //             centered
  //             size="lg"
  //             aria-labelledby="modal-modal-title"
  //             aria-describedby="modal-modal-description"
  //           >
  //             <MDBox sx={style} className="email_modal">
  //               <MDTypography
  //                 className="text-center"
  //                 id="modal-modal-title"
  //                 variant="h6"
  //                 component="h2"
  //               >
  //                 User Details
  //               </MDTypography>
  //               <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
  //                 <div className="typo_modal">
  //                   <h4>Name : </h4>
  //                   <p>{userDetails.name}</p>
  //                 </div>
  //                 <div className="typo_modal">
  //                   <h4>Email : </h4>
  //                   <p>{userDetails.email}</p>
  //                 </div>
  //                 <div className="typo_modal">
  //                   <h4>Type : </h4>
  //                   <p>{userDetails.type}</p>
  //                 </div>

  //                 <div className="typo_modal">
  //                   <h4>Phone : </h4>
  //                   <p>{userDetails.phone}</p>
  //                 </div>
  //                 <div className="typo_modal">
  //                   <h4>Status : </h4>
  //                   <p>{userDetails.status}</p>
  //                 </div>
  //                 <div className="typo_modal">
  //                   <h4>Created : </h4>
  //                   <p>
  //                     {moment(userDetails.createdAt).format(
  //                       "MM-DD-YYYY HH:mm:ss"
  //                     )}
  //                   </p>
  //                 </div>
  //                 <div className="typo_modal">
  //                   <h4>Updated : </h4>
  //                   <p>
  //                     {moment(userDetails.updatedAt).format(
  //                       "MM-DD-YYYY HH:mm:ss"
  //                     )}
  //                   </p>
  //                 </div>
  //                 <br />
  //                 <MDButton
  //                   variant="secondary"
  //                   className="close_buutt"
  //                   onClick={closeModal}
  //                 >
  //                   X
  //                 </MDButton>
  //               </MDTypography>
  //             </MDBox>
  //           </Modal>
  //         )}
  //       </MDBox>
  //     ),
  //   };
  // });

  return {
    // columns: [
    //   { Header: "user", accessor: "user", width: "45%", align: "left" },
    //   {
    //     Header: "organization",
    //     accessor: "organization",
    //     width: "45%",
    //     align: "left",
    //   },
    //   { Header: "email", accessor: "email", align: "left" },
    //   { Header: "phone", accessor: "phone", align: "left" },
    //   { Header: "created", accessor: "createdAt", align: "center" },
    //   { Header: "updated", accessor: "updatedAt", align: "center" },
    //   { Header: "status", accessor: "status", align: "center" },
    //   { Header: "action", accessor: "action", align: "center" },
    // ],



    columns: [
      {
        name: 'Sl No.',
        selector: row => row.sl_no,
        maxWidth: '80px',
        minWidth: '80px',
        sortable: true,
      },
      {
        name: 'Member Name',
        selector: row => {
          return (
            <MDBox onClick={() => memberModalOpen(row)} sx={{ cursor: 'pointer'}}>
              <MDTypography sx={{fontSize: 14}} variant="body2" fontWeight="medium">
                {row.name}
              </MDTypography>
              <MDTypography variant="caption">Type : {row.type}</MDTypography>
            </MDBox>
          )
        },
        //maxWidth: '230px',
        minWidth: '230px',
        sortable: true,
      },
      {
        name: 'Organization/Partners',
        selector: row => row?.org_info?.name,
        //maxWidth: '200px',
        minWidth: '200px',
        sortable: true,
      },
      {
        name: "Member Contact Details",
        selector: (row) => {
          return (
            <MDBox>
              <MDTypography sx={{display: 'block'}} variant="caption">Email : {row.email}</MDTypography>
              <MDTypography sx={{display: 'block'}} variant="caption">Phone: {row.phone}</MDTypography>
            </MDBox>
          );
        },
        //maxWidth: '250px',
        minWidth: '250px',
        sortable: true,
      },
      // {
      //   name: 'Member Email',
      //   selector: row => row.email,
      //   width: '200px',
      //   sortable: true,
      // },
      // {
      //   name: 'Member Phone',
      //   selector: row => row.phone,
      //   width: '150px',
      //   //sortable: true,
      // },
      {
        name: 'Member Uses',
        selector: row => row.credit,
        //maxWidth: '120px',
        minWidth: '120px',
        //sortable: true,
      },
      {
        name: 'Member Created',
        selector: row => moment(row.createdAt).format("MM-DD-YYYY HH:mm:ss"),
        sortable: true,
        sortField: 'created',
        // maxWidth: '180px',
        minWidth: '180px',
        sortable: true,
      },
      {
        name: 'Member Status',
        selector: row => {
          return (
            <Box>
              <Switch
                onChange={handleChange(row._id)}
                checked={switchStates[row._id]}
                className="react-switch"
                disabled={!row.name}
                onColor="#49a3f1"
              />
              </Box>
          )
        },
        // maxWidth: '140px',
        minWidth: '140px',
        sortable: true,
      },
      {
        name: 'Action',
        selector: row => {
          return (
            <MDBox>
              <IconButton
                aria-label="more"
                aria-controls="dot-menu"
                aria-haspopup="true"
                onClick={(e) => handleClick(e, row)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="dot-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                //onClose={handleClose}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    openModal();
                    getUserById();
                  }}
                >
                  View
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={`/member-update/${currentItemId}`}
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  Edit
                </MenuItem>
                <MenuItem
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    openDeleteModal();
                  }}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  component="a"
                  href={`/credit-history/${currentItemId}`}
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  Uses History
                </MenuItem>
              </Menu>

              {currentItemId === row._id && (
                <Modal
                  class="modal"
                  open={isDeleteModalOpen}
                  onClose={closeDeleteModal}
                >
                  <Paper className="modal-dialog modal-sm">
                    <Box className="modal-content">
                      <Box className="modal-header">
                        <MDTypography
                          className="text-center"
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Delete Confirmation
                        </MDTypography>
                        <IconButton
                            className="close_button"
                            onClick={closeDeleteModal}
                        >
                          <CloseOutlinedIcon/>
                        </IconButton>
                      </Box>
                      <Box className="modal-body">
                        {notification && (
                          <MDAlert color="info" mt="20px">
                            <MDTypography variant="body2" color="white">
                              Your member has been deleted
                            </MDTypography>
                          </MDAlert>
                        )}                        
                        <MDTypography id="modal-modal-description">
                          Are you sure you want to delete this item?
                        </MDTypography>
                      </Box>
                      <Box className="modal-footer">
                        <Stack spacing={2} direction="row">
                          <MDButton
                            color="info"
                            variant="text"
                            onClick={() => {
                              closeDeleteModal();
                            }}
                          >
                            No
                          </MDButton>
                          <MDButton
                            color="error"                          
                            onClick={() => {
                              handleUserDelete();
                            }}
                          >
                            Yes
                          </MDButton>
                        </Stack>
                      </Box>
                      
                    </Box>
                  </Paper>
                </Modal>
              )}
              {currentItemId === row._id && (
                <Modal
                  class="modal"
                  open={isModalOpen}
                  onClose={closeModal}
                >
                  <Paper className="modal-dialog">
                    <Box className="modal-content">
                      <Box className="modal-header">
                        <MDTypography
                          className="text-center"
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Member Details
                        </MDTypography>
                        <IconButton 
                            className="close_button"
                            onClick={closeModal}
                        >
                          <CloseOutlinedIcon/>
                        </IconButton>
                      </Box>
                      <Box className="modal-body">
                          <Box className="typo_modal" sx={{mb:2}}>
                            <MDTypography variant="h6" sx={{fontSize:14}}>Member Name : </MDTypography>
                            <MDTypography variant="body2">{userDetails.name}</MDTypography>
                          </Box>
                          <Box className="typo_modal" sx={{mb:2}}>
                            <MDTypography variant="h6" sx={{fontSize:14}}>Member Email : </MDTypography>
                            <MDTypography variant="body2">{userDetails.email}</MDTypography>
                          </Box>
                          <Box className="typo_modal" sx={{mb:2}}>
                            <MDTypography variant="h6" sx={{fontSize:14}}>Member Type : </MDTypography>
                            <MDTypography variant="body2">{userDetails.type}</MDTypography>
                          </Box>

                          <Box className="typo_modal" sx={{mb:2}}>
                            <MDTypography variant="h6" sx={{fontSize:14}}>Member  Phone : </MDTypography>
                            <MDTypography variant="body2">{userDetails.phone}</MDTypography>
                          </Box>
                          <Box className="typo_modal" sx={{mb:2}}>
                            <MDTypography variant="h6" sx={{fontSize:14}}>Member Status : </MDTypography>
                            <MDTypography variant="body2">{userDetails.status}</MDTypography>
                          </Box>
                          <Box className="typo_modal" sx={{mb:2}}>
                            <MDTypography variant="h6" sx={{fontSize:14}}>Member Created : </MDTypography>
                            <MDTypography variant="body2">
                              {moment(userDetails.createdAt).format(
                                "MM-DD-YYYY HH:mm:ss"
                              )}
                            </MDTypography>
                          </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Modal>
              )}
            </MDBox>
          )
        },
        //sortable: true,
        maxWidth: '80px',
        minWidth: '80px',
      }
    ],

    rows: user,
    load: loader,
    getFilterUsers: getFilterUsers
  };
}
