import { useContext, useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import axios from "../../axios/axios";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import AuthService from "services/auth-service";
import { AuthContext } from "context";
import LoadingData from "../../loader/loader";
import { Box, FormControl, FormLabel } from "@mui/material";
import { Stack } from "@mui/system";

function Login() {
  const authContext = useContext(AuthContext);

  const [credentialsErros, setCredentialsError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [notification, setNotification] = useState(false);
  const [errorMess, setErrorMess] = useState("");
  const [loader, setLoader] = useState(false);

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
  });

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (
      inputs.email.trim().length === 0 ||
      !inputs.email.trim().match(mailFormat)
    ) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    const userCredentials = {
      email: inputs.email,
      password: inputs.password,
    };

    try {
      setLoader(true)

      await AuthService.loginPost(userCredentials).then(async (responseData) => {
        // Handle the response here

        // Example: Check if the response contains an access_token
        if (responseData.data.hasOwnProperty("token")) {
          // Call a function to handle successful login and store the tokens
          authContext.login(responseData.data.token);

          // Clear input fields and reset error states
          setInputs({
            email: "",
            password: "",
          });

          setErrors({
            emailError: false,
            passwordError: false,
          });
        } else {
          setLoader(false)
          // Handle the response in case the token is missing
          console.error("Access token is missing in the response");
          setNotification(true)
          
          setErrorMess("Invalid email address or password")
        }
      }).catch((err) => {
        setLoader(false)
        console.error("missing in the response");
      })








      // Make sure you are sending the authentication token if required
      // const responseData = await AuthService.loginPost(userCredentials);
      // console.log(responseData, 'responseData');
      // // Handle the response here

      // // Example: Check if the response contains an access_token
      // if (responseData.data.hasOwnProperty("token")) {
      //   // Call a function to handle successful login and store the tokens
      //   authContext.login(responseData.data.token);

      //   // Clear input fields and reset error states
      //   setInputs({
      //     email: "",
      //     password: "",
      //   });

      //   setErrors({
      //     emailError: false,
      //     passwordError: false,
      //   });
      // } else {
      //   // Handle the response in case the token is missing
      //   console.error("Access token is missing in the response");
      // }
    } catch (error) {
      // Handle errors here
      if (error.response) {
        // The request was made, but the server responded with an error status code
        const { data } = error.response;
        if (data.hasOwnProperty("message")) {
          setCredentialsError(data.message);
        } else if (data.errors && data.errors.length > 0) {
          setCredentialsError(data.errors[0].detail);
        }
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something else happened while setting up the request
        console.error("An error occurred:", error.message);
      }
    }
  };


  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  return (
    <BasicLayoutLanding image={bgImage}>
      {loader && <LoadingData />}       
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
        >          
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Sign in
          </MDTypography>
        </MDBox>
       
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            method="POST"
            onSubmit={submitHandler}
            sx={{textAlign:'left'}}
          >
            {notification && (
              <Box mb={3}>
                <MDAlert color="error">{errorMess}</MDAlert>
              </Box>
            )}
            <Box mb={2}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Email</FormLabel>
                <MDInput
                  type="email"
                  
                  value={inputs.email}
                  name="email"
                  onChange={changeHandler}
                  error={errors.emailError}
                />
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Password</FormLabel>
                <MDInput
                  type="password"
                  name="password"
                  value={inputs.password}
                  onChange={changeHandler}
                  error={errors.passwordError}
                />
              </FormControl>
            </Box>
            <Box mb={2}>
              <MDTypography
                component={Link}
                to="/auth/forgot-password"
                variant="body2"
                color="info"
                fontWeight="medium"
                sx={{fontSize:'14px'}}
              >
                Forgot your password?
              </MDTypography>
            </Box>
            <MDButton variant="gradient" color="info" fullWidth type="submit">
              sign in
            </MDButton>

            {credentialsErros && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {credentialsErros}
              </MDTypography>
            )}

          </MDBox>
        </MDBox>
      </Card>
    </BasicLayoutLanding>
  );
}

export default Login;
