import React from 'react';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Footer from '.';

function privacyPolicy() {
  return (
    <div>
      <DashboardNavbar />
      <DashboardLayout>
        <h1>Privacy Policy</h1>
        <p>privacy policy</p>
        <Footer/>
        </DashboardLayout>
    </div>
  )
}

export default privacyPolicy