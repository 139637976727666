import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useParams, Link, useNavigate } from "react-router-dom";

import axios from "../../axios/axios";
import { MenuItem, Select, Grid, Card, FormControl, InputLabel, Box, FormLabel } from "@mui/material";

const PlanUpdate = () => {
  const { id } = useParams(); // Access URL parameters
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [notification, setNotification] = useState(false);
  const [fields, setFields] = useState(['']);
  const [planUpdate, setPlanUpdate] = useState({
    name: "",
    price: "",
    description: "",
    plan_type: "",
    featureDesc: [],
    for: "",
    credit: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
    priceError: false,
    descriptionError: false,
    plan_typeError: false,
    featureDescError: false,
    forError: false,
    creditError: false,
  });

  const getPlanById = async () => {
    setLoader(true);
    await axios
      .get(`/admin/plan/${id}`)
      .then((res) => {
        setLoader(false);

        const responseData = res.data.data;

        setPlanUpdate({
          name: responseData.name,
          price: responseData.price,
          description: responseData.description,
          plan_type: responseData.type,
          featureDesc: responseData.features,
          for: responseData.for,
          credit: responseData.credit
        });
        let pushData = [];
        for (let index = 0; index < responseData.features.length; index++) {
          pushData.push(responseData.features[index].description);
        }
        setFields(pushData);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getPlanById();
  }, [])

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    setPlanUpdate({
      ...planUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddField = () => {
    // Add a new empty field to the state array
    setFields([...fields, '']);
  };

  const handleRemoveField = (index) => {
    // Handle edge case: prevent removing the last field
    if (fields.length === 1) {
      alert('Cannot remove the last field!');
      return;
    }

    // Create a copy of the array, slice to exclude the field at `index`
    const updatedFields = fields.slice(0, index).concat(fields.slice(index + 1));
    setFields(updatedFields);
  };

  const handleChangeForMultipleField = (index, value) => {
    // Update the field value at `index` in the state array
    // setFields((prevFields) =>
    //   prevFields.map((field, i) => (i === index ? value : field))
    // );
    const updatedTextFields = [...fields];
    updatedTextFields[index] = value; // Update the value at the given index
    setFields(updatedTextFields);
  };

  function TextField({ value, onChange, onRemove }) {
    return (
      <div>
        <div style={{ width: "75%", float: "left" }}>
          <MDInput
            type="text"
            fullWidth
            name="features"
            value={value}
            onChange={onChange}
            multiline
            rows={3}
            maxRows={10}
            placeholder="Type your Features Description..."
          />
        </div>
        <div style={{ width: "20%", float: "right", marginLeft: "5px" }}>
          <MDButton variant="outlined" onClick={onRemove} color="error" type="button">
            Remove
          </MDButton>
        </div>
      </div>
    );
  }



  const submitHandler = async (e) => {
    e.preventDefault();

    if (planUpdate.name.trim().length === 0) {
      setErrors({ ...errors, nameError: true });
      return;
    }
    if (planUpdate.price.length === 0) {
      setErrors({ ...errors, priceError: true });
      return;
    }
    if (planUpdate.description.trim().length === 0) {
      setErrors({ ...errors, descriptionError: true });
      return;
    }
    if (fields.length === 0) {
      setErrors({ ...errors, featureDescError: true });
      return;
    }
    if (planUpdate.plan_type.trim().length === 0) {
      setErrors({ ...errors, plan_typeError: true });
      return;
    }
    if (planUpdate.for.trim().length === 0) {
      setErrors({ ...errors, forError: true });
      return;
    }
    if (planUpdate.credit.length === 0) {
      setErrors({ ...errors, creditError: true });
      return;
    }
    let fDescription = [];
    for (let index = 0; index < fields.length; index++) {
      const element = {
        description: fields[index],
        limit: 0
      };
      fDescription.push(element);
    }
    // call api for update
    const paramsData = {
      plan_id: id,
      name: planUpdate.name,
      description: planUpdate.description,
      type: planUpdate.plan_type,
      price: planUpdate.price,
      // features: [
      //   {
      //     description: planUpdate.featureDesc,
      //     "limit": 0
      //   }
      // ],
      features: fDescription,
      for: planUpdate.for,
      credit: planUpdate.credit
    }
    axios
      .post("/admin/plan-update", paramsData)
      .then((res) => {
        if (res.data.status === true) {
          setIsRedirecting(true);
        }
      })
      .catch((err) => {
        console.log("update err", err);
      });

    // reset errors
    setErrors({
      nameError: false,
      priceError: false,
      descriptionError: false,
      plan_typeError: false,
      featureDescError: false,
      forError: false,
      creditError: false,
    });

    setNotification(true);
  };

  if (isRedirecting) {
    setTimeout(() => {
      navigate('/plan-management');
    }, 2000)
  }

  return (
    <>
    <DashboardNavbar />
    <DashboardLayout>
      <MDBox className="primary_container" pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white" sx={{ textTransform: 'uppercase' }}>
                  Add New Plan
                </MDTypography>
              </MDBox>
              <MDBox
                component="form"
                role="form"
                onSubmit={submitHandler}
                py={4} px={2}
              >
                {notification && (
                  <Box mb={2}>
                    <MDAlert color="success">Your plan has been updated</MDAlert>
                  </Box>
                )}

                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Plan Name<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="name"
                        name="name"
                        value={planUpdate.name}
                        onChange={changeHandler}
                        error={errors.nameError}
                      />
                      {errors.nameError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The plan name can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Cost ($)<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="text"
                        name="price"
                        value={planUpdate.price}
                        onChange={changeHandler}
                        error={errors.priceError}
                      />
                      {errors.priceError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The cost can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Plan Description<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="text"
                        name="description"
                        value={planUpdate.description}
                        onChange={changeHandler}
                        error={errors.descriptionError}
                        multiline
                        rows={6}
                        maxRows={10}
                      />
                      {errors.descriptionError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The plan description can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Plan Type<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <Select
                        labelId="formBasicOrganizationLabel"
                        id="formBasicOrganization"
                        name="plan_type"
                        value={planUpdate.plan_type}
                        onChange={changeHandler}
                        style={{ height: 44 }}
                      >
                        <MenuItem value="Month">Monthly</MenuItem>
                        <MenuItem value="Year">Yearly</MenuItem>
                      </Select>
                      {errors.plan_typeError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The Plan Type can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                    
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Select User Type<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <Select
                        labelId="formBasicOrganizationLabel"
                        id="formBasicOrganization"
                        name="for"
                        value={planUpdate.for}
                        onChange={changeHandler}
                        className="member_select_box"
                        style={{ height: 44 }}
                      >
                        <MenuItem value="Individual">Individual</MenuItem>
                        <MenuItem value="Organization">Organization</MenuItem>
                      </Select>                    
                      {errors.forError && (
                        <MDTypography variant="caption" color="error">
                          The type can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Set Plan Credits<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="text"
                        name="credit"
                        value={planUpdate.credit}
                        onChange={changeHandler}
                        error={errors.creditError}
                      />
                      {errors.creditError && (
                        <MDTypography variant="caption" color="error" fontWeight="light">
                          The credit can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={3}
                    >
                      <MDTypography
                        variant="body2"
                        color="text"
                        fontWeight="medium"
                      >Features Description
                      </MDTypography>
                      <MDButton variant="outlined" onClick={handleAddField} color="success" type="button">
                        Add More
                      </MDButton>
                    </Box>
                    <Box>
                      {fields.map((field, index) => (
                        <Grid container spacing={3} alignItems="center" mb={2}>
                          <Grid item xs={12} md={10}>
                            <MDInput
                              type="text"
                              fullWidth
                              name="features"
                              value={field}
                              onChange={(e) => handleChangeForMultipleField(index, e.target.value)}
                              multiline
                              rows={3}
                              maxRows={10}
                              placeholder="Type your Features Description..."
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                          <MDButton variant="outlined" onClick={() => handleRemoveField(index)} color="error" type="button">
                            Remove
                          </MDButton>
                          </Grid>
                        </Grid>
                      ))}
                      {errors.featureDescError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          The features description can not be null
                        </MDTypography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <MDButton variant="gradient" color="info" type="submit">
                        Save changes
                      </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
    </MDBox>

    <Footer />
    </DashboardLayout>
    </>
  );
};

export default PlanUpdate;
