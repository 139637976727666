import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Checkbox from '@mui/material/Checkbox';
import Switch from "react-switch";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useParams, Link, useNavigate } from "react-router-dom";
import LoadingData from "../../loader/loader";
import axios from "../../axios/axios";
import { Box, Card, FormControl, FormLabel, Grid } from "@mui/material";

const UserUpdate = () => {
  const { id } = useParams(); // Access URL parameters
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [notification, setNotification] = useState(false);
  const [passwordDiv, setpasswordDiv] = useState(false);
  const [userUpdate, setUserUpdate] = useState({
    name: "",
    phone: "",
    newPass: "",
    newCpass: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
    phoneError: false,
    newPassError: false,
    newCpassError: false,
  });
  const [showNewPass, setShowNewPass] = useState(false);
  const [showNewCpass, setShowNewCpass] = useState(false);

  const getUserById = async () => {
    setLoader(true);
    await axios
      .get(`/admin/user/${id}`)
      .then((res) => {
        setLoader(false);

        const responseData = res.data.data;

        setUserUpdate({
          name: responseData.name,
          phone: responseData.phone,
        });
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getUserById();
  }, []);

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    setUserUpdate({
      ...userUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const handleNewPasswordVisibility = () => {
    setShowNewPass((prevShowPassword) => !prevShowPassword);
  };
  const handleNewCpasswordVisibility = () => {
    setShowNewCpass((prevShowPassword) => !prevShowPassword);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let newErrors = {};
    if (passwordDiv) {

      newErrors = {
        nameError: userUpdate.name ? userUpdate.name.trim().length === 0 : true,
        phoneError: userUpdate.phone ? userUpdate.phone.length === 0 : true,
        newPassError: userUpdate.newPass
          ? userUpdate.newPass.trim().length === 0 ||
          userUpdate.newPass.trim().length < 6
          : true,
        newCpassError:
          userUpdate.newCpass === "" ||
          userUpdate.newCpass !== userUpdate.newPass,
      };
    } else {
      newErrors = {
        nameError: userUpdate.name ? userUpdate.name.trim().length === 0 : true,
        phoneError: userUpdate.phone ? userUpdate.phone.length === 0 : true,
      };
    }

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return;
    }

    // call api for update
    let paramsData = {};
    if (passwordDiv) {
      paramsData = {
        org_id: id,
        name: userUpdate.name,
        phone: userUpdate.phone,
        new_password: userUpdate.newPass,
      };
    } else {
      paramsData = {
        org_id: id,
        name: userUpdate.name,
        phone: userUpdate.phone
      };
    }

    axios
      .post("/admin/update-user", paramsData)
      .then((res) => {
        if (res.data.status === true) {
          setIsRedirecting(true);
        }
      })
      .catch((err) => {
        console.log("update err", err);
      });

    // reset errors
    setErrors({
      nameError: false,
      phoneError: false,
      newPassError: false,
      newCpassError: false,
    });

    setNotification(true);
  };

  if (isRedirecting) {
    setTimeout(() => {
      navigate("/member-management");
    }, 2000);
  }

  const passwordDivShowHide = () => {
    if (passwordDiv) {
      setpasswordDiv(false);
    } else {
      setpasswordDiv(true);
    }
  }


  return (
    <>
      {loader && <LoadingData />}
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox className="primary_container" pt={3} pb={3}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={5}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h5" color="white" sx={{ textTransform: 'uppercase' }}>Edit Member</MDTypography>
                  </MDBox>                  
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={submitHandler}
                    px={2}
                    py={4}
                  >
                    {notification && (
                      <Box mb={3}>
                        <MDAlert color="success">Your user has been updated</MDAlert>
                      </Box>
                    )}

                    <Box mb={3}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Member Name<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MDInput
                          type="name"
                          name="name"
                          value={userUpdate.name}
                          onChange={changeHandler}
                          error={errors.nameError}
                        />
                        {errors.nameError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The member name can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>
                    <Box mb={3}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Member Phone<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MDInput
                          type="text"
                          name="phone"
                          value={userUpdate.phone}
                          onChange={changeHandler}
                          error={errors.phoneError}
                        />
                        {errors.phoneError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The member phone can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>                    
                    <Box mb={3} display="flex" alignItems="center">
                      <Switch
                        onChange={passwordDivShowHide}
                        checked={passwordDiv}
                        className="react-switch"
                        onColor="#49a3f1"
                      />
                      <MDTypography sx={{display: 'inline-block', ml:2}}>Change password</MDTypography>
                    </Box >

                    {(passwordDiv) ? (<>
                      <>
                        <Box mb={3}>
                          <FormControl fullWidth>
                            <FormLabel className="form-label">Member Phone<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                            <MDInput
                              type={showNewPass ? "text" : "password"}
                              name="newPass"                           
                              value={userUpdate.newPass}
                              onChange={changeHandler}
                              error={errors.newPassError}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      onClick={handleNewPasswordVisibility}
                                    >
                                      {showNewPass ? (
                                        <VisibilityOffIcon />
                                      ) : (
                                        <VisibilityIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {errors.newPassError && (
                              <MDTypography
                                variant="caption"
                                color="error"
                                fontWeight="light"
                              >
                                The password can not be null
                              </MDTypography>
                            )}
                          </FormControl>
                        </Box>
                        <Box mb={3}>
                          <FormControl fullWidth>
                            <FormLabel className="form-label">Confirm Password<MDTypography sx={{display:'inline-block'}} color="error" variant="body2">*</MDTypography></FormLabel>
                            <MDInput
                              type={showNewCpass ? "text" : "password"}
                              name="newCpass"
                              value={userUpdate.newCpass}
                              onChange={changeHandler}
                              error={errors.newCpassError}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      onClick={handleNewCpasswordVisibility}
                                    >
                                      {showNewCpass ? (
                                        <VisibilityOffIcon />
                                      ) : (
                                        <VisibilityIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {errors.newCpassError && (
                              <MDTypography
                                variant="caption"
                                color="error"
                              >
                                Passwords do not match
                              </MDTypography>
                            )}
                          </FormControl>
                        </Box>
                      </>
                    </>) : (<></>)}

                    <MDButton color="info" type="submit">Save changes</MDButton>
                  </MDBox>
                </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default UserUpdate;
