// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import axios from "../../axios/axios"
import moment from "moment";
import { useParams } from "react-router-dom";

export default function creditData() {
  const [loader, setLoader] = useState(false);
  const [creditHistory, setCreditHistory] = useState([]);
  const [allDetails, setAllDetails] = useState([]);
  const params = useParams();

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );

  // const Job = ({ title, description }) => (
  //   <MDBox lineHeight={1} textAlign="left">
  //     <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
  //       {title}
  //     </MDTypography>
  //     <MDTypography variant="caption">{description}</MDTypography>
  //   </MDBox>
  // );

  const getCredit = async () => {
    setLoader(true);
    await axios
      .get(`/admin/credit-history/${params.id}`)
      .then((res) => {
        setLoader(false);
        setCreditHistory(res.data.data)
        setAllDetails(res.data.user_info)
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  }
  useEffect(() => {
    getCredit()
  }, [])

  // useEffect(() => {
  //   const arr = []
  //   arr.push(allDetails.org_info, allDetails.user_info)
  //   console.log("err",arr)
  // },[])

  // const rowsData = creditHistory.map((item) => {
  //   return (
  //     {
  //       name: <Author name={`${item.user_info.name}`} email="" />,
  //       phone: <Job title={`${item.user_info.phone}`} description="" />,
  //       email: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {`${item.user_info.email}`}
  //         </MDTypography>
  //       ),
  //       user_type: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.user_info.type}
  //         </MDTypography>
  //       ),
  //       affiliated_from: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.org_info.name}
  //         </MDTypography>
  //       ),
  //       // user_status: (
  //       //   <MDTypography variant="caption" color="text" fontWeight="medium">
  //       //   {item.user_info.status}
  //       // </MDTypography>
  //       // ),

  //       description: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.description}
  //         </MDTypography>
  //       ),
  //       credit: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.credit}
  //         </MDTypography>
  //       ),

  //       status: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.status}
  //         </MDTypography>
  //       ),
  //     }
  //   )

  // })

  return {
    // columns: [
    //     { Header: "name", accessor: "name", width: "45%", align: "left" },
    //     { Header: "email", accessor: "email", align: "left" },
    //     { Header: "phone", accessor: "phone", align: "left" },
    //     { Header: "user type", accessor: "user_type", align: "left" },
    //     { Header: "Organization/Partners", accessor: "affiliated_from", align: "left" },
    //     // { Header: "user status", accessor: "user_status", align: "left" },
    //     { Header: "credit", accessor: "credit", align: "left" },
    //     { Header: "description", accessor: "description", align: "left" },
    //     // { Header: "created at", accessor: "created_At", align: "left" },
    //     { Header: "status", accessor: "status", align: "center" },
    //   ],

    columns: [     
      {
        name: 'Sl No.',
        selector: row => row?.[0]?.sl_no,
        width: '70px',
        sortable: true,
      },
      {
          name: 'Credit Used',
          selector: row => row?.credit,
          width: '200px',
          sortable: true,
        },
      {
        name: 'Credit Used Date',
        selector: row => moment(row?.createdAt).format("MM-DD-YYYY HH:mm:ss"),
        sortable: true,
        sortField: 'created',
        width: '200px',
      },
    ],

    rows: creditHistory,
    loader: loader,
    allDetails:allDetails.name

  };
}
