import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Paper,
  FormControl,
  FormLabel,
  IconButton,
} from "@mui/material";
// import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "../../axios/axios";
import MDAlert from "components/MDAlert";
import LoadData from "../../loader/loader";
import { useNavigate } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const addTone = ({ open, handleClose }) => {
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState(false);
  const [toneName, setToneName] = useState("");
  const [toneNameErr, setToneNameErr] = useState(false);
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const getTones = async () => {
    setLoader(true);
    await axios
      .get("/admin/tones")
      .then((res) => {
        setLoader(false);
        // setOrganization(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const validationStep = () => {
    setToneNameErr("")
    if (!toneName) {
      setToneNameErr("Tone Name field is required");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  const handleSubmit = async (e) => {
    if (validationStep()) {
      // Perform step 2 submission here if needed
      e.preventDefault();

      const paramsData = {
        name: toneName,
      };
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post("/admin/add-tone", paramsData)
        .then((res) => {
          setLoader(false);
          if (res.data.status === true) {
            setTimeout(() => {
              handleClose();
              //getTones();
              window.location.reload();
            }, 2000);
          } else {
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
      setNotification(true);
    }
  }


  const changeHandler = (e) => {
    setToneName(e.target.value);
  };

  return (

    <Modal
      open={open}
      onClose={handleClose}
      className="modal"
    >
      <Paper className="modal-dialog modal-sm">
        <Box className="modal-content">
          <Box className="modal-header">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Tone
            </Typography>
            <IconButton
              className="close_button"
              onClick={handleClose}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className="modal-body">
            {notification && (
              <MDAlert color="info" mt="20px">
                <MDTypography variant="body2" color="white">
                  Your tone has been created
                </MDTypography>
              </MDAlert>
            )}
            <FormControl fullWidth>
              <FormLabel className="form-label">Enter Tone<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
              <MDInput
                type="text"
                name="name"
                value={toneName}
                onChange={changeHandler}
                error={toneNameErr}
              />
              {toneNameErr && (
                <MDTypography variant="caption" color="error">
                  {toneNameErr}
                </MDTypography>
              )}
            </FormControl>          
          </Box>
          <Box className="modal-footer">
            <MDButton variant="text" color="info" onClick={handleClose}>
              Close
            </MDButton>
            <MDButton variant="contained" color="info" onClick={handleSubmit}>
              Add Tone
            </MDButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default addTone;
