// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import axios from "../../axios/axios";
import Switch from "react-switch";
import moment from "moment";
import { useParams, Link } from "react-router-dom";

// Images
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

import { Box, IconButton, Menu, MenuItem, Paper, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MDAlert from "components/MDAlert";
import Modal from "@mui/material/Modal";
import MDButton from "components/MDButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function data() {
  const [loader, setLoader] = useState(false);
  const [plans, setPlans] = useState([]);
  const [switchStates, setSwitchStates] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleClick = (event, getId) => {
    setAnchorEl(event.currentTarget);
    setCurrentItemId(getId._id)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );

  // const Job = ({ title, description }) => (
  //   <MDBox lineHeight={1} textAlign="left">
  //     <MDTypography
  //       display="block"
  //       variant="caption"
  //       color="text"
  //       fontWeight="medium"
  //     >
  //       {title}
  //     </MDTypography>
  //     <MDTypography variant="caption">{description}</MDTypography>
  //   </MDBox>
  // );

  const getPlans = async () => {
    setLoader(true);
    await axios
      .get("/admin/plans")
      .then((res) => {
        setLoader(false);
        setPlans(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handlePlanDelete = () => {
    axios
      .post("/admin/delete-plan", { plan_id: currentItemId })
      .then((res) => {
        if (res.data.status === true) {
          setTimeout(() => {
            closeDeleteModal();
            getPlans();
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("deleteUser err", err);
      });
    setNotification(true);
  };

  const handleChange = (id) => (nextChecked) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: nextChecked,
    }));

    const paramsData = {
      plan_id: id,
      status: nextChecked === true ? "Active" : "Inactive",
    };

    axios
      .post("/admin/plan-status-update", paramsData)
      .then((res) => {
        getPlans();
      })
      .catch((err) => {
        console.log("update status err", err);
      });
  };

  useEffect(() => {
    // Initialize the switchStates with the API data
    const initialSwitchStates = plans.reduce((acc, item) => {
      acc[item._id] = item.status === "Active" ? true : false;
      return acc;
    }, {});

    setSwitchStates(initialSwitchStates);
  }, [plans]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // const rowsData = plans.map((item) => {
  //   return {
  //     plans: <Author name={item.name} email="" />,
  //     type: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {item.type}
  //       </MDTypography>
  //     ),
  //     price: <Job title={item.price} description="" />,
  //     description: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {item.features[0].description}
  //       </MDTypography>
  //     ),
  //     credit: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {item.credit}
  //       </MDTypography>
  //     ),
  //     createdAt: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {moment(item.createdAt).format("MM-DD-YYYY HH:mm:ss")}
  //       </MDTypography>
  //     ),
  //     updatedAt: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {moment(item.updatedAt).format("MM-DD-YYYY HH:mm:ss")}
  //       </MDTypography>
  //     ),
  //     status: (
  //       <MDTypography
  //         variant="caption"
  //         color="text"
  //         fontWeight="medium"
  //         key={item._id}
  //       >
  //         <Switch
  //           onChange={handleChange(item._id)}
  //           checked={switchStates[item._id]}
  //           className="react-switch"
  //         />
  //       </MDTypography>
  //     ),
  //     action: (
  //       <MDBox>
  //         <IconButton
  //           aria-label="more"
  //           aria-controls="dot-menu"
  //           aria-haspopup="true"
  //           onClick={handleClick}
  //         >
  //           <MoreVertIcon />
  //         </IconButton>
  //         <Menu
  //           id="dot-menu"
  //           anchorEl={anchorEl}
  //           open={Boolean(anchorEl)}
  //           onClose={handleClose}
  //         >
  //           <MenuItem
  //             component={Link} // Use Link component here
  //             to={`/plan-update/${item._id}`}
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             mr={2}
  //           >
  //             Edit
  //           </MenuItem>
  //           <MenuItem
  //             component="a"
  //             href="#"
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             onClick={() => {
  //               openDeleteModal();
  //               setDeleteItemId(item._id);
  //             }}
  //           >
  //             Delete
  //           </MenuItem>
  //         </Menu>
  //         {deleteItemId === item._id && (
  //           <Modal
  //             open={isDeleteModalOpen}
  //             onClose={closeDeleteModal}
  //             centered
  //             size="lg"
  //             aria-labelledby="modal-modal-title"
  //             aria-describedby="modal-modal-description"
  //           >
  //             <MDBox sx={style} className="email_modal org_modalll">
  //               {notification && (
  //                 <MDAlert color="info" mt="20px">
  //                   <MDTypography variant="body2" color="white">
  //                     Your plan has been deleted
  //                   </MDTypography>
  //                 </MDAlert>
  //               )}
  //               <MDTypography
  //                 className="text-center"
  //                 id="modal-modal-title"
  //                 variant="h6"
  //                 component="h2"
  //               >
  //                 Delete Confirmation
  //               </MDTypography>
  //               <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
  //                 Are you sure you want to delete this item?
  //               </MDTypography>
  //               <div className="btn__group">
  //                 <MDButton
  //                   variant="secondary"
  //                   className="yes_but"
  //                   onClick={() => {
  //                     handlePlanDelete(item._id);
  //                   }}
  //                 >
  //                   Yes
  //                 </MDButton>

  //                 <MDButton
  //                   variant="danger"
  //                   className="no_but"
  //                   onClick={() => {
  //                     closeDeleteModal();
  //                   }}
  //                 >
  //                   No
  //                 </MDButton>
  //               </div>
  //               <MDButton
  //                 variant="secondary"
  //                 className="close_buutt"
  //                 onClick={closeDeleteModal}
  //               >
  //                 X
  //               </MDButton>
  //             </MDBox>
  //           </Modal>
  //         )}
  //       </MDBox>
  //     ),
  //   };
  // });

  return {
    // columns: [
    //   { Header: "plans", accessor: "plans", width: "45%", align: "left" },
    //   { Header: "type", accessor: "type", align: "center" },
    //   { Header: "price", accessor: "price", align: "left" },
    //   { Header: "description", accessor: "description", align: "center" },
    //   { Header: "credit", accessor: "credit", align: "center" },
    //   { Header: "created", accessor: "createdAt", align: "center" },
    //   { Header: "updated", accessor: "updatedAt", align: "center" },
    //   { Header: "status", accessor: "status", align: "center" },
    //   { Header: "action", accessor: "action", align: "center" },
    // ],

    columns: [
      {
        name: 'Sl No.',
        selector: row => row.sl_no,
        minWidth: '70px',
        maxWidth: '70px',
        sortable: true,
      },
      {
        name: 'Plan Name',
        selector: row => row.name,
        minWidth: '120px',
        //maxWidth: '120px',
        sortable: true,
      },
      {
        name: 'Plan Type',
        selector: row => row.type,
        minWidth: '120px',
        //maxWidth: '120px',
        sortable: true,
      },
      {
        name: 'Cost',
        selector: row => "$"+row.price,
        minWidth: '80px',
        //maxWidth: '80px',
        sortable: true,
      },
      {
        name: 'Plan Description',
        selector: row => row.features[0].description,
        minWidth: '150px',
        //maxWidth: '150px',
        //sortable: true,
      },
      {
        name: 'Plan Credits',
        selector: row => row.credit,
        minWidth: '120px',
        maxWidth: '120px',
        sortable: true,
      },
      {
        name: 'Plan Created',
        selector: row => moment(row.createdAt).format("MM-DD-YYYY HH:mm:ss"),
        sortable: true,
        sortField: 'created',
        minWidth: '170px',
        //maxWidth: '170px',
      },
      {
        name: 'Plan Status',
        selector: row => {
          return (
            <Switch
              onChange={handleChange(row._id)}
              checked={switchStates[row._id]}
              className="react-switch"
              onColor="#49a3f1"
            />
          )
        },
        minWidth: '100px',
        //maxWidth: '100px',
      },
      {
        name: 'Action',
        selector: row => {
          return (
            <MDBox>
              <IconButton
                aria-label="more"
                aria-controls="dot-menu"
                aria-haspopup="true"
                onClick={(e)=>handleClick(e,row)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="dot-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem
                  component={Link} // Use Link component here
                  to={`/plan-update/${currentItemId}`}
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  mr={2}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    openDeleteModal();
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
              {currentItemId === row._id && (
                <Modal
                  className="modal"
                  open={isDeleteModalOpen}
                  onClose={closeDeleteModal}
                >
                  <Paper className="modal-dialog modal-sm">
                    <Box className="modal-content">                      
                      <Box className="modal-header">
                        <MDTypography
                          variant="h6"
                          component="h2"
                        >
                          Delete Confirmation
                        </MDTypography>
                        <IconButton
                          color="error"
                          className="close_button"
                          onClick={closeDeleteModal}
                        >
                          <CloseOutlinedIcon />
                        </IconButton>
                      </Box>
                      <Box className="modal-body">
                        {notification && (
                          <MDAlert color="info">
                            <MDTypography variant="body2" color="white">
                              Your plan has been deleted
                            </MDTypography>
                          </MDAlert>
                        )}
                        <MDTypography id="modal-modal-description">
                          Are you sure you want to delete this item?
                        </MDTypography>
                      </Box>
                      
                      <Box className="modal-footer">
                        <Stack spacing={2} direction="row">
                          <MDButton
                            variant="text"
                            color="info"
                            onClick={() => {
                              closeDeleteModal();
                            }}
                          >
                            No
                          </MDButton>
                          <MDButton
                            color="error"
                            onClick={() => {
                              handlePlanDelete();
                            }}
                          >
                            Yes
                          </MDButton>
                          
                        </Stack>
                      </Box>
                      
                    </Box>
                  </Paper>
                </Modal>
              )}
            </MDBox>
          )
        },
        //sortable: true,
        maxWidth: '80px',
        minWidth: '80px'
      }
    ],

    rows: plans,

    loader: loader,
  };
}
