import { Link } from "react-router-dom";
import axios from "../../axios/axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from 'react-data-table-component';
import LoadingData from "../../loader/loader"
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

// Data
import authorsTableData from "layouts/credit-history/creditData";

const creditHistory = () => {
  const { columns, rows, loader, allDetails } = authorsTableData();
  const tableData = {
    columns: columns,
    data: rows
  };
  return (
    <>
    <DashboardNavbar />
    <DashboardLayout>
      <MDBox className="primary_container" pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {loader && <LoadingData />}
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white" sx={{ textTransform: 'uppercase' }}>
                  Credit History
                </MDTypography>
                <MDTypography variant="h6" color="white">Member Name: {allDetails}</MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableExtensions {...tableData} filter={true} export={false} print={false} filterPlaceholder="Search Here..">

                  <DataTable
                    // table={{ columns, rows }}
                    // isSorted={true}
                    // entriesPerPage={true}
                    // showTotalEntries={true}
                    // noEndBorder
                    columns={columns}
                    data={rows}
                    pagination
                  />
                </DataTableExtensions>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
    </>
  );
};

export default creditHistory;
