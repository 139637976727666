import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://smartpostai-api.webgen.me/api/v1/api/v1", // prod
  headers: {
    "x-access-token": localStorage.getItem("token")
  }
});


export default axios;