import React from 'react';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Footer from '.';

function termsAndConditions() {
  return (
    <div>
      <DashboardNavbar />
      <DashboardLayout>
        <h1>Terms And Conditions</h1>
        <p>terms and conditions</p>
        <Footer/>
        </DashboardLayout>
    </div>
  )
}

export default termsAndConditions