// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import React from 'react'
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from 'react-data-table-component';
import LoadingData from "../../loader/loader";

import { useParams, Link, useNavigate } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

// Data
import authorsTableData from "layouts/coupon-management/couponData";

function CouponManagement() {
  const { columns, rows, loader } = authorsTableData();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const tableData = {
    columns: columns,
    data: rows
  };
  const tableStyle = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  };

  return (
    <>
    {loader && <LoadingData />}
    <DashboardNavbar />
    <DashboardLayout>
      <MDBox className="primary_container" pt={6} pb={3}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center" 
                sx={{flexWrap: 'wrap'}}
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  sx={{ textTransform: 'uppercase' }}
                >
                  Coupon Management
                </MDTypography>
                <Link to="/add-coupon-management">
                  <MDButton
                    variant="gradient"
                    color="dark"
                    type="button"
                  >
                    Add New Coupon
                  </MDButton>
                </Link>
              </MDBox>

              <MDBox pt={3}>
                <DataTableExtensions {...tableData} filter={true} export={false} print={false} filterPlaceholder="Search Here..">

                  <DataTable
                    columns={columns}
                    data={rows}
                    pagination
                    customStyles={tableStyle}
                    highlightOnHover
                  />
                </DataTableExtensions>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
    </>
  );
}

export default CouponManagement;
