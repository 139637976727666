import React from 'react';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Footer from '.';

function about() {
  return (
    <div>
      <DashboardNavbar />
      <DashboardLayout>
        <h1>About Us</h1>
        <p>about us</p>
        <Footer />
        </DashboardLayout>
    </div>
  )
}

export default about