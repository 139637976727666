// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import PlanManagement from "layouts/plan-management";
import TransactionManagement from "layouts/transactions";
import LoginHistory from "layouts/login-history";
import CreditManagement from "layouts/credit-management/credit"

import OrganizationManagement from "layouts/organization-management";
import UserManagement from "layouts/user-management";
import CreditHistory from "layouts/credit-history/creditHistory";

import PromptManagement from "layouts/prompt-management";
import ToneManagement from "layouts/tone-management";
import Logout from "layouts/logout/logout"

import Login from "auth/login";
import ForgotPassword from "auth/login/forgotPassword";
import ResetPassword from "auth/login/resetPassword";

// @mui icons
import Icon from "@mui/material/Icon";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
// import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
// import CreditCardIcon from '@mui/icons-material/CreditCard';
// import HistoryIcon from '@mui/icons-material/History';
import CouponManagement from "layouts/coupon-management";
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import DiscountIcon from '@mui/icons-material/Discount';


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <DashboardOutlinedIcon />,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Organization Management",
    key: "organization-management",
    icon: <BusinessIcon />,
    route: "/organization-management",
    component: <OrganizationManagement />,
  },
  {
    type: "collapse",
    name: "Member Management",
    key: "member-management",
    icon: <ManageAccountsRoundedIcon />,
    route: "/member-management",
    component: <UserManagement />,
  },
  {
    type: "collapse",
    name: "Plan Management",
    key: "plan-management",
    icon: <ArticleOutlinedIcon />,
    route: "/plan-management",
    component: <PlanManagement />,
  },
  // {
  //   type: "collapse",
  //   name: "Credit Management",
  //   key: "credit-management",
  //   icon: <CreditCardIcon fontSize="small">credit</CreditCardIcon>,
  //   route: "/credit-management",
  //   component: <CreditManagement />,
  // },
  {
    type: "collapse",
    name: "Partner Management",
    key: "partner-management",
    icon: <HandshakeOutlinedIcon />,
    route: "/partner-management",
    component: <CreditManagement />,
  },
  {
    type: "collapse",
    name: "Transaction History",
    key: "transaction-history",
    icon: <PaidOutlinedIcon />,
    route: "/transaction-history",
    component: <TransactionManagement />,
  },
  // {
  //   type: "collapse",
  //   name: "Credit History",
  //   key: "credit-history",
  //   icon: <HistoryIcon fontSize="small">credit</HistoryIcon>,
  //   route: "/credit-history",
  //   component: <CreditHistory />,
  // },
  {
    type: "collapse",
    name: "Login History",
    key: "login-history",
    icon: <HistoryOutlinedIcon />,
    route: "/login-history",
    component: <LoginHistory />,
  },
  {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  {
    type: "auth",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">forgot</Icon>,
    route: "/auth/forgot-password",
    component: <ForgotPassword />,
  },
  {
    type: "auth",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">reset</Icon>,
    route: "/auth/reset-password",
    component: <ForgotPassword />,
  },
  {
    type: "collapse",
    name: "Prompt Management",
    key: "prompt-management",
    icon: <ManageSearchRoundedIcon fontSize="small">prompt_management</ManageSearchRoundedIcon>,
    route: "/prompt-management",
    component: <PromptManagement />,
  },
  {
    type: "collapse",
    name: "Coupon Management",
    key: "coupon-management",
    icon: <DiscountIcon />,
    route: "/coupon-management",
    component: <CouponManagement />,
  },
  {
    type: "collapse",
    name: "Tone Management",
    key: "tone-management",
    icon: <RecordVoiceOverOutlinedIcon />,
    route: "/tone-management",
    component: <ToneManagement />,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <LogoutOutlinedIcon />,
    route: "/logout",
    component: <Logout />,
  },
];

export default routes;
