import Axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
Axios.defaults.baseURL = API_URL;

export class HttpService {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (url) => await this.request(this.getOptionsConfig("get", url));

  post = async (url, data) =>
    await this.request(this.getOptionsConfig("post", url, data));

  loginPost = async (url, data) => {
    let dataaaa = JSON.stringify(data);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataaaa,
    };

    return new Promise(async (resolve, reject) => {
      await Axios.request(config)
        .then((res) => {
          localStorage.setItem("adminAuth", JSON.stringify(res.data));
          resolve(res.data);
        })
        .catch((ex) => {
          reject(ex.response.data);
        });
    });

    // await Axios.request(config)
    //   .then((response) => {
    //     console.log(response.data);
    //     localStorage.setItem("adminAuth", JSON.stringify(res.data));
    //     resolve(response.data);
    //   })
    //   .catch((error) => {
    //     reject(error.response.data)
    //   });
  };

  logoutPost = async (url) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    };

    return new Promise(async (resolve, reject) => {
      await Axios.request(config)
        .then((res) => {
          localStorage.removeItem("adminAuth");
          resolve(res.data);
        })
        .catch((ex) => {
          reject(ex.response.data);
        });
    });

    // Axios
    //   .get(url)
    //   .then((res) => {
    //     localStorage.removeItem("adminAuth");
    //     resolve(res.data);
    //   })
    //   .catch((err) => {
    //     reject(err.response.data)
    //   });
  };

  forgotPasswordPost = async (url) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    };

    return new Promise(async (resolve, reject) => {
      await Axios.request(config)
        .then((res) => {
          localStorage.removeItem("adminAuth");
          resolve(res.data);
        })
        .catch((ex) => {
          reject(ex.response.data);
        });
    });
  };

  resetPasswordPost = async (url) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    };

    return new Promise(async (resolve, reject) => {
      await Axios.request(config)
        .then((res) => {
          localStorage.removeItem("adminAuth");
          resolve(res.data);
        })
        .catch((ex) => {
          reject(ex.response.data);
        });
    });
  };

  put = async (url, data) =>
    await this.request(this.getOptionsConfig("put", url, data));

  patch = async (url, data) =>
    await this.request(this.getOptionsConfig("patch", url, data));

  delete = async (url) =>
    await this.request(this.getOptionsConfig("delete", url));

  getOptionsConfig = (method, url, data) => {
    return {
      method,
      url,
      data,
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        "Access-Control-Allow-Credentials": true,
      },
    };
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => resolve(res.data))
        .catch((ex) => reject(ex.response.data));
    });
  }
}

export default new HttpService();
