import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Paper,
  Grid,
  FormControl,
  Card,
  FormLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "../../axios/axios";
import MDAlert from "components/MDAlert";
import LoadData from "../../loader/loader";
import { useNavigate, useParams } from "react-router-dom";
import MultiSelect from 'react-select';
import Select from "@mui/material/Select";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import LoadingData from "../../loader/loader";
import MenuItem from "@mui/material/MenuItem";
import Moment from 'moment';

const CouponUpdate = ({ open, handleClose, toneData }) => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState(false);
  const [toneName, setToneName] = useState("");
  const [toneNameErr, setToneNameErr] = useState(false);
  const [planData, setplanData] = useState([]);
  const navigate = useNavigate();
  const [planReqData, setplanReqData] = useState([]);
  const [couponTypeAttr, setcouponTypeAttr] = useState(true);
  const [durationAttr, setdurationAttr] = useState(false);
  const [planDataSelected, setplanDataSelected] = useState([]);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [changePlan, setchangePlan] = useState(false);
  const [reqData, setreqData] = useState({
    code: "",
    type: "percentage",
    percent_off: "",
    amount_off: "",
    duration: "",
    duration_in_months: "",
    max_redemptions: "",
    expires_at: "",
    user: "",
    status: "",
    plans: []
  });
  const [reqDataError, setreqDataError] = useState({
    code: false,
    type: false,
    percent_off: false,
    amount_off: false,
    duration: false,
    duration_in_months: false,
    max_redemptions: false,
    expires_at: false,
    user: false,
    plans: false
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const getPlans = async () => {
    setLoader(true);
    await axios
      .get("/admin/plans")
      .then(async (res) => {
        setLoader(false);
        let allPlans = res.data.data;
        let option = [];
        for (let index = 0; index < res.data.data.length; index++) {
          let attrData = { value: res.data.data[index]._id, label: res.data.data[index].name };
          option.push(attrData);
        }
        setplanData(option);

        await axios
          .get(`/admin/coupon/${id}`)
          .then((response) => {

            let plSelectedData = [];
            setreqData({
              code: response.data.data.code,
              type: (response.data.data.percent_off) ? 'percentage' : 'flat',
              amount_off: (response.data.data.percent_off) ? '' : response.data.data.amount_off,
              duration: response.data.data.duration,
              duration_in_months: response.data.data.duration_in_months,
              expires_at: response.data.data.expires_at,
              max_redemptions: response.data.data.max_redemptions,
              percent_off: (response.data.data.percent_off) ? response.data.data.percent_off : '',
              status: response.data.data.status
            });

            for (let planIndex = 0; allPlans.length > planIndex; planIndex++) {
              if (response.data.data.plans.includes(allPlans[planIndex]._id)) {
                plSelectedData.push({
                  value: allPlans[planIndex]._id,
                  label: allPlans[planIndex].name
                });
              }

            }
            setplanDataSelected(plSelectedData);


            if (response.data.data.percent_off) {
              setcouponTypeAttr(true);
            } else {
              setcouponTypeAttr(false);
            }


            if (response.data.data.duration == 'repeating') {
              setdurationAttr(true);
            } else {
              setdurationAttr(false);
            }



          })
          .catch((err) => {
            console.log("err", err);
          });


      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };





  useEffect(() => {
    getPlans();
  }, []);


  const validationStep = () => {
    setToneNameErr("")
    if (!toneName) {
      setToneNameErr("Tone Name field is required");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);




  const changeHandler = (e) => {
    if (e.target.name == 'type') {
      if (e.target.value == 'flat') {
        setcouponTypeAttr(false);
      } else {
        setcouponTypeAttr(true);
      }
    }
    if (e.target.name == 'duration') {
      if (e.target.value == 'repeating') {
        setdurationAttr(true);
      } else {
        setdurationAttr(false);
      }
    }
    setreqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };

  const planchangeHandler = (e) => {
    setchangePlan(true);
    setplanReqData(e);
    setplanDataSelected(e);
  };
  const submitHandler = async (e) => {

    e.preventDefault();
    let allPlValue = [];
    let dataPush = [];

    console.log(planDataSelected);

    if (changePlan) {
      dataPush.push(planReqData);
      for (let plIndex = 0; dataPush.length > plIndex; plIndex++) {
        allPlValue.push(dataPush[plIndex].value);
      }
    } else {

      for (let plIndex = 0; planDataSelected.length > plIndex; plIndex++) {
        allPlValue.push(planDataSelected[plIndex].value);
      }
    }



    console.log(allPlValue);
    if (reqData.code != '' && reqData.duration != '' && reqData.expires_at != '') {
      setreqDataError({
        code: false,
        max_redemptions: false,
        duration: false,
        expires_at: false,
        plans: false
      })
      let flag = true;
      let flag_v2 = true;
      if (reqData.type == 'percentage') {
        if (reqData.percent_off == '') {
          flag = false;
        }
      } else if (reqData.type == 'flat') {
        if (reqData.amount_off == '') {
          flag = false;
        }
      }
      if (flag) {
        if (reqData.duration == 'repeating') {
          if (reqData.duration_in_months == '') {
            flag_v2 = false;
          }
        }

        setreqDataError({
          amount_off: false,
          percent_off: false,
        })

        if (flag_v2) {
          setreqDataError({
            duration_in_months: false,
          })

          let paramsData = {};
          if (reqData.type == 'percentage') {
            paramsData = {
              code: reqData.code,
              percent_off: reqData.percent_off,
              duration: reqData.duration,
              duration_in_months: (reqData.duration == 'repeating') ? reqData.duration_in_months : 0,
              max_redemptions: reqData.max_redemptions,
              plans: allPlValue,
              expires_at: Moment(reqData.expires_at).format('YYYY-MM-DD'),
              coupon_id: id,
              status: reqData.status
            };
          } else {
            paramsData = {
              code: reqData.code,
              amount_off: reqData.amount_off,
              duration: reqData.duration,
              duration_in_months: (reqData.duration == 'repeating') ? reqData.duration_in_months : 0,
              max_redemptions: reqData.max_redemptions,
              plans: planDataValue,
              expires_at: Moment(reqData.expires_at).format('YYYY-MM-DD'),
              coupon_id: id,
              status: reqData.status
            };
          }



          axios
            .post("/admin/coupon-update", paramsData)
            .then((res) => {
              console.log(res.data)
              if (res.data.status === true) {
                setchangePlan(false);
                setNotification(true);
                setIsRedirecting(true);
              }
            })
            .catch((err) => {
              console.log("coupon add", err);
            });



        } else {
          setreqDataError({
            duration_in_months: true,
          })
        }

      } else {
        setreqDataError({
          amount_off: true,
          percent_off: true,
        })
      }

    } else {
      setreqDataError({
        code: true,
        max_redemptions: true,
        duration: true,
        expires_at: true,
        plans: true
      })
    }
  }

  if (isRedirecting) {
    navigate('/coupon-management');
  }

  return (
    <>
    {loader && <LoadingData />}
    <DashboardNavbar />
    <DashboardLayout>
    <MDBox className="primary_container" pt={6} pb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center" 
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  sx={{ textTransform: 'uppercase' }}
                >
                  Edit Coupon
                </MDTypography>
              </MDBox>
      
              <MDBox
                component="form"
                role="form"
                onSubmit={submitHandler}
                px={2} py={3}
              >
                {notification && (
                  <Box mb={2}>
                    <MDAlert color="success">
                        Your coupon has been updated
                    </MDAlert>
                  </Box>
                )}
                <Box>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Plans (optional)</FormLabel>
                      <MultiSelect
                        onChange={planchangeHandler}
                        name="plans"
                        value={planDataSelected}
                        options={planData}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: '14px',
                            minHeight: '44px',
                            height: '44px',
                            
                          }),
                          option: (base) => ({
                            ...base,
                            fontSize: '14px',
                          }),
                        }}
                      />
                      {reqDataError.plans && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The plan can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Coupon Code<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="code"
                        name="code"
                        value={reqData.code}
                        onChange={changeHandler}
                        error={reqDataError.code}
                      />
                      {reqDataError.code && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The coupon code can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Coupon Type<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="formBasicOrganization"
                        name="type"
                        value={reqData.type}
                        onChange={changeHandler}
                        style={{ height: 44 }}
                      >
                        <MenuItem value="percentage">
                          Percentage
                        </MenuItem>
                        <MenuItem value="flat">
                          Flat
                        </MenuItem>
                      </Select>
                      {reqDataError.type && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The coupon type can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  {(couponTypeAttr) ? (
                    <Box mb={2}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Percentage Off (%)<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MDInput
                          type="number"
                          name="percent_off"
                          value={reqData.percent_off}
                          onChange={changeHandler}
                          error={reqDataError.percent_off}
                        />
                        {reqDataError.percent_off && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The coupon percentage can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>                    
                  ) : (
                    <Box mb={2}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Flat Off ($)<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MDInput
                          type="number"
                          name="amount_off"
                          value={reqData.amount_off}
                          onChange={changeHandler}
                          error={reqDataError.amount_off}
                        />
                        {reqDataError.amount_off && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The coupon flat off can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>
                  )}

                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Coupon Duration<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="formBasicOrganization"
                        name="duration"
                        value={reqData.duration}
                        onChange={changeHandler}
                        style={{ height: 44 }}
                      >
                        <MenuItem value="forever">
                          Forever
                        </MenuItem>
                        <MenuItem value="once">
                          Once
                        </MenuItem>
                        <MenuItem value="repeating">
                          Repeating
                        </MenuItem>
                      </Select>
                      {reqDataError.duration && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The Coupon Duration can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  {(durationAttr) ? (
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Duration in Month<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="number"
                        fullWidth
                        name="duration_in_months"
                        value={reqData.duration_in_months}
                        onChange={changeHandler}
                        error={reqDataError.duration_in_months}
                      />
                      {reqDataError.duration_in_months && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          The coupon Duration can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>
                  ) : (<></>)}


                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Coupon Expire Date<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="date"
                        name="expires_at"
                        value={reqData.expires_at}
                        onChange={changeHandler}
                        error={reqDataError.expires_at}
                      />
                      {reqDataError.expires_at && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The coupon expire can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Coupon Status Date<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="formBasicOrganization"
                        name="status"
                        value={reqData.status}
                        onChange={changeHandler}
                        style={{ height: 44 }}
                      >
                        <MenuItem value="Active">
                          Active
                        </MenuItem>
                        <MenuItem value="Inactive">
                          Inactive
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <MDButton variant="gradient" color="info" type="submit">
                    Save changes
                  </MDButton>

                </Box>

              </MDBox>

            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
    </>
  );
};

export default CouponUpdate;
