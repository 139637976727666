import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Footer from "examples/Footer";
import LoadingData from "../../loader/loader";
import axios from "../../axios/axios";

function resetPassword() {
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showNewCpass, setShowNewCpass] = useState(false);
  const [profileData, setProfileData] = useState({
    newPassword: "",
    newCpassword: "",
  });
  const [errors, setErrors] = useState({
    newPasswordError: false,
    newCpasswordError: false,
  });

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 2000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  const handleNewPasswordVisibility = () => {
    setShowNewPass((prevShowPassword) => !prevShowPassword);
  };
  const handleNewCpasswordVisibility = () => {
    setShowNewCpass((prevShowPassword) => !prevShowPassword);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let newErrors = {
      newPasswordError: profileData.newPassword
        ? profileData.newPassword.trim().length === 0 ||
          profileData.newPassword.trim().length < 6
        : true,
      newCpasswordError:
        profileData.newCpassword === "" ||
        profileData.newCpassword !== profileData.newPassword,
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return;
    }
    const paramsData = {
      new_password: profileData.newPassword,
    };

    setLoader(true);
    axios
      .post("/admin/reset-password", paramsData)
      .then((res) => {
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
    setErrors({
    //   adminEmailError: false,
      newPasswordError: false,
      newCpasswordError: false,
    });

    setNotification(true);
  };
  return (
    <>
      {loader && <LoadingData />}
      {/* <DashboardNavbar /> */}
      {notification && (
        <MDAlert color="info" mt="20px">
          <MDTypography variant="body2" color="white">
            Password reset successfuly
          </MDTypography>
        </MDAlert>
      )}
      <MDBox
        component="form"
        role="form"
        onSubmit={submitHandler}
        display="flex"
        flexDirection="column"
      >
        <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            mr={2}
          >
            <MDTypography
              variant="body2"
              color="text"
              ml={1}
              fontWeight="regular"
            >
              Your new password
            </MDTypography>
            <MDBox mb={2} width="100%">
              <MDInput
                type={showNewPass ? "text" : "password"}
                fullWidth
                name="newPassword"
                value={profileData.newPassword}
                onChange={changeHandler}
                error={errors.newPasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleNewPasswordVisibility}
                      >
                        {showNewPass ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.newPasswordError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The new password can not be null
                </MDTypography>
              )}
            </MDBox>
            <MDTypography
              variant="body2"
              color="text"
              ml={1}
              fontWeight="regular"
            >
              Your new confirm password
            </MDTypography>
            <MDBox mb={2} width="100%">
              <MDInput
                type={showNewCpass ? "text" : "password"}
                fullWidth
                name="newCpassword"
                value={profileData.newCpassword}
                onChange={changeHandler}
                error={errors.newCpasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleNewCpasswordVisibility}
                      >
                        {showNewCpass ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.newCpasswordError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  Passwords do not match
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mt={4} sx={{ pt: 2 }} display="flex" justifyContent="end">
          <MDButton variant="gradient" color="info" type="submit">
            Save Changes
          </MDButton>
        </MDBox>
      </MDBox>
      <Footer />
    </>
  );
}

export default resetPassword;
