// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import axios from "../../axios/axios";
// import Switch from "react-switch";
import moment from "moment";
// import { useParams, Link } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, IconButton, Menu, MenuItem, Paper } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MDAlert from "components/MDAlert";
import Modal from "@mui/material/Modal";
import MDButton from "components/MDButton";
// import UpdateTone from "./update";

export default function data() {
  const [loader, setLoader] = useState(false);
  const [tone, setTone] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [toneData, setToneData] = useState("");
  const [currentItemId, setCurrentItemId] = useState(null);

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleUpdateOpen = () => {
    setUpdateModalOpen(true);
  };

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  // const Author = ({ name }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );

  // const getToneById = async (_id) => {
  //   setLoader(true);
  //   await axios
  //     .get(`/admin/tone/${_id}`)
  //     .then((res) => {
  //       setLoader(false);
  //       const responseData = res.data.data;
  //       setToneData(responseData);
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       console.log("err", err);
  //     });
  // };

  const getTone = async () => {
    setLoader(true);
    await axios
      .get("/admin/tones")
      .then((res) => {
        setLoader(false);
        setTone(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getTone();
  }, []);

  const handleToneDelete = () => {
    axios
      .post("/admin/delete-tone", { tone_id: currentItemId })
      .then((res) => {
        if (res.data.status === true) {
          setTimeout(() => {
            closeDeleteModal();
            getTone();
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("deleteUser err", err);
      });
    setNotification(true);
  };

  const handleClick = (event, getId) => {
    setAnchorEl(event.currentTarget);
    setCurrentItemId(getId._id)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 600,
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // };

  // const rowsData = tone.map((item) => {
  //   return {
  //     tones: <Author name={item.name} email="" />,
  //     createdAt: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {moment(item.createdAt).format("MM-DD-YYYY HH:mm:ss")}
  //       </MDTypography>
  //     ),
  //     updatedAt: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {moment(item.updatedAt).format("MM-DD-YYYY HH:mm:ss")}
  //       </MDTypography>
  //     ),
  //     action: (
  //       <MDBox>
  //         <IconButton
  //           aria-label="more"
  //           aria-controls="dot-menu"
  //           aria-haspopup="true"
  //           onClick={(e) => handleClick(e, item._id)}
  //         >
  //           <MoreVertIcon />
  //         </IconButton>
  //         <Menu
  //           id="dot-menu"
  //           anchorEl={anchorEl}
  //           open={Boolean(anchorEl)}
  //           onClose={handleClose}
  //         >
  //           {/* <MenuItem
  //             component="a"
  //             href="#"
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             onClick={(e) => {
  //               handleUpdateOpen();
  //               getToneById(selectedItemId);
  //             }} >
  //             Edit
  //           </MenuItem>
  //           <UpdateTone open={updateModalOpen} handleClose={handleClose} toneData={toneData} /> */}
  //           <MenuItem
  //             component="a"
  //             href="#"
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             onClick={() => {
  //               openDeleteModal();
  //             }}
  //           >
  //             Delete
  //           </MenuItem>
  //         </Menu>
  //         {currentItemId === item._id && (
  //           <Modal
  //             open={isDeleteModalOpen}
  //             onClose={closeDeleteModal}
  //             centered
  //             size="lg"
  //             aria-labelledby="modal-modal-title"
  //             aria-describedby="modal-modal-description"
  //           >
  //             <MDBox sx={style} className="email_modal org_modalll">
  //               {notification && (
  //                 <MDAlert color="info" mt="20px">
  //                   <MDTypography variant="body2" color="white">
  //                     Your plan has been deleted
  //                   </MDTypography>
  //                 </MDAlert>
  //               )}
  //               <MDTypography
  //                 className="text-center"
  //                 id="modal-modal-title"
  //                 variant="h6"
  //                 component="h2"
  //               >
  //                 Delete Confirmation
  //               </MDTypography>
  //               <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
  //                 Are you sure you want to delete this item?
  //               </MDTypography>
  //               <div className="btn__group">
  //                 <MDButton
  //                   variant="secondary"
  //                   className="yes_but"
  //                   onClick={() => {
  //                     handleToneDelete();
  //                   }}
  //                 >
  //                   Yes
  //                 </MDButton>

  //                 <MDButton
  //                   variant="danger"
  //                   className="no_but"
  //                   onClick={() => {
  //                     closeDeleteModal();
  //                   }}
  //                 >
  //                   No
  //                 </MDButton>
  //               </div>
  //               <MDButton
  //                 variant="secondary"
  //                 className="close_buutt"
  //                 onClick={closeDeleteModal}
  //               >
  //                 X
  //               </MDButton>
  //             </MDBox>
  //           </Modal>
  //         )}
  //       </MDBox>
  //     ),
  //   };
  // });

  return {
    // columns: [
    //   { Header: "tones", accessor: "tones", width: "45%", align: "left" },
    //   { Header: "created", accessor: "createdAt", align: "center" },
    //   { Header: "updated", accessor: "updatedAt", align: "center" },
    //   { Header: "action", accessor: "action", align: "center" },
    // ],

    columns: [
      {
        name: 'Sl No.',
        selector: (row) => row.sl_no,
        minWidth: '80px',
        maxWidth: '80px',
        sortable: true,
      },
      {
        name: 'Tones Name',
        selector: row => row.name,
        sortable: true,
        minWidth: '350px'
      },
      {
        name: 'Tone Created',
        selector: row => moment(row.createdAt).format("MM-DD-YYYY HH:mm:ss"),
        sortable: true,
        sortField: 'created',
        minWidth: '350px'
      },
      {
        name: 'Action',
        minWidth: '100px',
        maxWidth: '100px',
        selector: row => {
          return (
            <MDBox>
              <IconButton
                aria-label="more"
                aria-controls="dot-menu"
                aria-haspopup="true"
                onClick={(e) => handleClick(e, row)}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="dot-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  onClick={() => {
                    openDeleteModal();
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
              {currentItemId === row._id && (
                <Modal
                  open={isDeleteModalOpen}
                  onClose={closeDeleteModal}
                  className="modal"
                >
                  <Paper className="modal-dialog modal-sm">
                    <Box className="modal-content">                      
                      <Box className="modal-header">
                        <MDTypography
                          className="text-center"
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Delete Confirmation
                        </MDTypography>
                        <IconButton
                          className="close_button"
                          onClick={closeDeleteModal}
                        >
                          <CloseOutlinedIcon />
                        </IconButton>
                      </Box>
                      <Box className="modal-body">
                        {notification && (
                          <Box mb={2}>
                            <MDAlert color="info">
                                Your plan has been deleted
                            </MDAlert>
                          </Box>
                        )}
                        <MDTypography>
                          Are you sure you want to delete this item?
                        </MDTypography>
                      </Box>
                      <Box className="modal-footer">
                        <MDButton
                          variant="text"
                          color="info"
                          onClick={() => {
                            closeDeleteModal();
                          }}
                        >
                          No
                        </MDButton>
                        <MDButton
                          color="error"
                          onClick={() => {
                            handleToneDelete();
                          }}
                        >
                          Yes
                        </MDButton>
                      </Box>                    
                    </Box>
                  </Paper>
                </Modal>
              )}
            </MDBox>
          )
        },
      }

    ],

    rows: tone,

    loader: loader,
  };
}
