import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useParams, Link, useNavigate } from "react-router-dom";
import MultiSelect from 'react-select';
import LoadingData from "../../loader/loader";

import axios from "../../axios/axios";
import { Box, Card, FormControl, FormLabel, Grid } from "@mui/material";

const UpdatePrompt = () => {
  const { id } = useParams(); // Access URL parameters
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [notification, setNotification] = useState(false);
  const [allOrgData, setallOrgData] = useState([]);
  const [allOrgIds, setallOrgIds] = useState([]);
  const [orgReqData, setorgReqData] = useState([]);
  const [orgSelected, setorgSelected] = useState([]);
  const [platformsSelected, setplatformsSelected] = useState([]);
  const [platformsReqData, setplatformsReqData] = useState([]);
  const [promptData, setpromptData] = useState({
    type: "",
    platforms: [],
    description: "",
    organizations: [],
    hiddenPrompt: ""
  });
  const authData = JSON.parse(localStorage.getItem("adminAuth"));
  const [errors, setErrors] = useState({
    typeError: false,
    platformsError: false,
    descriptionError: false,
    organizationsError: false,
    // hiddenPrompt: false
  });

  const options = [
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'LinkedIn', label: 'LinkedIn' },
    { value: 'Twitter', label: 'Twitter' },
    { value: 'WordPress Blog', label: 'WordPress Blog' },
  ];

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  useEffect(async () => {
    setLoader(true);
    await axios
      .get("/admin/prompt-org-list")
      .then(async (res) => {
        setallOrgData(res.data.data);
        // console.log(res.data.data);
        let allData = res.data.data;

        await axios
          .get(`/admin/prompt/${id}`)
          .then((response) => {
            let orgSelectedData = [];
            let platformsSelected = [];
            setpromptData({
              description: response.data.data.description,
              type: response.data.data.type,
              hiddenPrompt: response.data.data.hidden_prompt
            });

            for (let OrgIndex = 0; allData.length > OrgIndex; OrgIndex++) {
              if (response.data.data.organizations.includes(allData[OrgIndex]._id)) {
                orgSelectedData.push({
                  value: allData[OrgIndex]._id,
                  label: allData[OrgIndex].name
                });
              }

            }
            setorgSelected(orgSelectedData);

            for (let platIndex = 0; options.length > platIndex; platIndex++) {
              if (response.data.data.platforms.includes(options[platIndex].value)) {
                platformsSelected.push({
                  value: options[platIndex].value,
                  label: options[platIndex].label
                });
              }
            }
            setplatformsSelected(platformsSelected);

            setLoader(false);
          })
          .catch((err) => {
            console.log("err", err);
          });


      })
      .catch((err) => {
        console.log("err", err);
      });



  }, []);

  const changeHandler = (e) => {
    setpromptData({
      ...promptData,
      [e.target.name]: e.target.value,
    });
  };

  const orgchangeHandler = (e) => {
    setorgSelected(e);
    setorgReqData(e);
  };

  const platformchangeHandler = (e) => {
    setplatformsSelected(e);
    setplatformsReqData(e);
  };



  // const submitHandler = async (e) => {
  //   e.preventDefault();

  //   if (planUpdate.name.trim().length === 0) {
  //     setErrors({ ...errors, nameError: true });
  //     return;
  //   }
  //   if (planUpdate.price.length === 0) {
  //     setErrors({ ...errors, priceError: true });
  //     return;
  //   }
  //   if (planUpdate.description.trim().length === 0) {
  //     setErrors({ ...errors, descriptionError: true });
  //     return;
  //   }
  //   if (planUpdate.featureDesc.trim().length === 0) {
  //     setErrors({ ...errors, featureDescError: true });
  //     return;
  //   }
  //   if (planUpdate.plan_type.trim().length === 0) {
  //     setErrors({ ...errors, plan_typeError: true });
  //     return;
  //   }

  //   // call api for update
  //   const paramsData = {
  //     plan_id: id,
  //     name: planUpdate.name,
  //     description: planUpdate.description,
  //     plan_type: planUpdate.plan_type,
  //     price: planUpdate.price,
  //     features: [
  //       {
  //         description: planUpdate.featureDesc,
  //         "limit": 1
  //       }
  //     ]
  //   }
  //   axios
  //     .post("/admin/plan-add", paramsData)
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         setIsRedirecting(true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Plan add", err);
  //     });

  //   // reset errors
  //   setErrors({
  //     nameError: false,
  //     priceError: false,
  //     descriptionError: false,
  //     plan_typeError: false,
  //     featureDescError: false,
  //   });

  //   setNotification(true);
  // };




  const submitHandler = async (e) => {
    e.preventDefault();
    if (promptData.description != '' && promptData.type != '' && (platformsReqData.length > 0 || platformsSelected.length > 0)) {
      let allOrgValue = [];
      let allPlatformsValue = [];
      if (platformsReqData.length > 0) {
        for (let pIndex = 0; platformsReqData.length > pIndex; pIndex++) {
          allPlatformsValue.push(platformsReqData[pIndex].value);
        }
      } else {
        for (let pIndex = 0; platformsSelected.length > pIndex; pIndex++) {
          allPlatformsValue.push(platformsSelected[pIndex].value);
        }
      }

      if (authData.data.user_info.type == "Admin" || authData.data.user_info.type == "Super Admin") {
        if (orgReqData.length > 0) {
          for (let OrgIndex = 0; orgReqData.length > OrgIndex; OrgIndex++) {
            allOrgValue.push(orgReqData[OrgIndex].value);
          }
        } else {
          for (let OrgIndex = 0; orgSelected.length > OrgIndex; OrgIndex++) {
            allOrgValue.push(orgSelected[OrgIndex].value);
          }
        }
      }



      const paramsData = {
        type: promptData.type,
        description: promptData.description,
        organizations: allOrgValue,
        platforms: allPlatformsValue,
        prompt_id: id,
        hidden_prompt: promptData.hiddenPrompt,
      }
      axios
        .post("/admin/prompt-update", paramsData)
        .then((res) => {
          if (res.data.status === true) {
            setNotification(true);
            setIsRedirecting(true);
          }
        })
        .catch((err) => {
          console.log("Plan add", err);
        });


    } else {
      if (authData.data.user_info.type == "Admin" || authData.data.user_info.type == "Super Admin") {
        setErrors({
          ...errors,
          descriptionError: (promptData.description != '') ? false : true,
          platformsError: (platformsReqData.length > 0) ? false : true,
          typeError: (promptData.type != '') ? false : true,
          organizationsError: (orgReqData.length > 0) ? false : true,
        })
      } else {
        setErrors({
          ...errors,
          descriptionError: (promptData.description != '') ? false : true,
          platformsError: (platformsReqData.length > 0) ? false : true,
          typeError: (promptData.type != '') ? false : true,
          organizationsError: false,
        })
      }
    }
  };

  if (isRedirecting) {
    navigate('/prompt-management');
  }

  return (
    <>
    {loader && <LoadingData />}
    <DashboardNavbar />
    <DashboardLayout>
      <MDBox className="primary_container" pt={6} pb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center" 
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  sx={{ textTransform: 'uppercase' }}
                >
                  Edit Prompt
                </MDTypography>                
              </MDBox>
                
              <MDBox
                component="form"
                role="form"
                onSubmit={submitHandler}
                py={3} px={2}
              >
                {notification && (
                  <Box mb={2}>
                    <MDAlert color="success">
                        Your Prompt has been updated
                    </MDAlert>
                  </Box>
                )}

                <Box>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Prompt Type<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="type"
                        name="type"
                        value={promptData.type}
                        onChange={changeHandler}
                        error={errors.typeError}
                      />
                      {errors.typeError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The type can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Prompt Description<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="text"
                        name="description"
                        value={promptData.description}
                        onChange={changeHandler}
                        error={errors.descriptionError}
                      />
                      {errors.descriptionError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The prompt description can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>                
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Add Hidden Prompt (Optional)</FormLabel>
                      <MDInput
                        type="type"
                        name="hiddenPrompt"
                        value={promptData.hiddenPrompt}
                        onChange={changeHandler}
                        error={errors.hiddenPromptErr}
                      />
                      {errors.hiddenPromptErr && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The hidden prompt type can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  {(authData.data.user_info.type == "Admin" || authData.data.user_info.type == "Super Admin") ? (
                    <Box mb={2}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Organization/Partners<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MultiSelect
                          onChange={orgchangeHandler}
                          name="organizations"
                          value={orgSelected}
                          options={allOrgData.map(e => ({ label: e.name, value: e._id }))}
                          isMulti
                        />

                        {errors.descriptionError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The organization can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>
                  ) : (
                  <></>
                  )}

                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Prompt Platforms<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MultiSelect
                          onChange={platformchangeHandler}
                          name="platforms"
                          value={platformsSelected}
                          defaultValue={promptData.platforms}
                          options={options}
                          isMulti
                        />
                        {errors.descriptionError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The platforms can not be null
                          </MDTypography>
                        )}
                    </FormControl>
                  </Box>

                  <MDButton color="info" type="submit" >
                    Update Prompt
                  </MDButton>

                </Box>

              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    <Footer />
    </DashboardLayout>
    </>
  );
};

export default UpdatePrompt;
