import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Paper,
  FormControl,
  FormLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import IconButton from "@mui/material/IconButton";
// import MenuItem from "@mui/material/MenuItem";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "../../axios/axios";
import MDAlert from "components/MDAlert";
// import LoadData from "../../loader/loader";
import Select from "react-select";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Stack, padding } from "@mui/system";
import { useParams, Link, useNavigate } from "react-router-dom";
import authff from "layouts/organization-management/orgdata";
import TextareaAutosize from '@mui/material/TextareaAutosize';

const Ragistration = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState(false);
  const [options, setOptions] = useState([]);
  const [orgcountry, setOrgCountry] = useState(null);
  const [mess, setMess] = useState("");
  const [addData, setaddData] = useState({
    orgname: "",
    orgemail: "",
    orgcity: "",
    orgstate: "",
    orgzipcode: "",
    orgphone: "",
    orgurl: "",
    orgaddress: "",
    // Add the missing fields for the second step
    name: "",
    email: "",
    phone: "",
    password: "",
    cPassword: "",
  });

  const [errors, setErrors] = useState({
    orgnameError: false,
    orgemailError: false,
    orgphoneError: false,
    orgcityError: false,
    orgstateError: false,
    orgzipcodeError: false,
    orgcountryError: false,
    orgaddressError: false,
    // Add the missing error states for the second step
    nameError: false,
    emailError: false,
    phoneError: false,
    passwordError: false,
    cPasswordError: false,
  });
  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 600,
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // };

  const getOrganization = async () => {
    setLoader(true);
    await axios
      .get("/admin/organizations")
      .then((res) => {
        setLoader(false);
        // setOrganization(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const getCountryList = async () => {
    await axios
      .get(`/country-list`)
      .then((res) => {
        setLoader(false);
        let arr = [];
        for (let i = 0; i < res.data.data.length; i++) {
          let newObj = { value: res.data.data[i], label: res.data.data[i] };
          arr.push(newObj);
        }
        setOptions(arr);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCountryList();
    //authff.getOrganization;
  }, []);

  const steps = ["Step 1", "Step 2"];

  const validationStep = () => {
    let newErrors = { ...errors };
    if (activeStep === 0) {
      newErrors.cPasswordError = false;
      newErrors.nameError = false;
      newErrors.passwordError = false;
      newErrors.phoneError = false;
      newErrors.emailError = false;

      // Validation for step 1
      newErrors.orgnameError =
        !addData.orgname || addData.orgname.trim().length === 0;
      newErrors.orgcityError =
        !addData.orgcity || addData.orgcity.trim().length === 0;
      newErrors.orgstateError =
        !addData.orgstate || addData.orgstate.trim().length === 0;
      newErrors.orgzipcodeError =
        !addData.orgzipcode || addData.orgzipcode.trim().length === 0;
      newErrors.orgcountryError = !orgcountry || orgcountry.trim().length === 0;

      newErrors.orgaddressError =
      !addData.orgaddress || addData.orgaddress.trim().length === 0;

      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      newErrors.orgemailError =
        !addData.orgemail.trim() || !emailRegex.test(addData.orgemail);

      const phoneRegex = /^\d{10}$/;
      newErrors.orgphoneError =
        !addData.orgphone.trim() || !phoneRegex.test(addData.orgphone);
    } else if (activeStep === 1) {
      // Validation for step 2
      newErrors.nameError = addData.name.trim().length === 0;

      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      newErrors.emailError =
        !addData.email.trim() || !emailRegex.test(addData.email);

      const phoneRegex = /^\d{10}$/;
      newErrors.phoneError =
        !addData.phone.trim() || !phoneRegex.test(addData.phone);
      console.log(addData.password.trim().length);
      console.log((addData.password.trim().length < 6) ? 'dd' : 'uuu', 'sss');
      if (addData.password.trim().length < 6) {
        newErrors.passwordError = true;
      } else {
        newErrors.passwordError = false;
      }

      if (
        addData.cPassword.trim().length < 6 ||
        addData.cPassword !== addData.password
      ) {
        newErrors.cPasswordError = true;
      } else {
        newErrors.cPasswordError = false;
      }
    }

    const hasErrors = Object.values(newErrors).some((error) => error);
    console.log(newErrors);
    setErrors(newErrors); // Update the error state
    return !hasErrors; // Return true if there are no errors
  };

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  const handleNext = async (e) => {
    console.log(activeStep);
    if (activeStep === 0) {
      if (validationStep()) {
        // Only proceed to the second step if validation for the first step passes
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (validationStep()) {
        // Perform step 2 submission here if needed
        e.preventDefault();

        const paramsData = {
          orgname: addData.orgname,
          orgcity: addData.orgcity,
          orgstate: addData.orgstate,
          orgcountry: orgcountry,
          orgaddress: orgaddress, 
          orgzipcode: addData.orgzipcode,
          orgphone: addData.orgphone,
          orgemail: addData.orgemail,
          orgurl: addData.orgurl,
          name: addData.name,
          email: addData.email,
          phone: addData.phone,
          password: addData.password,
        };
        setLoader(true);
        // Submit the form or perform other actions
        await axios
          .post("/admin/organization-add", paramsData)
          .then((res) => {
            setMess(res.data.message);
            setLoader(false);
            if (res.data.status === true) {
              setTimeout(() => {
                handleClose();
                //navigate("/organization-management");
                // getOrganization();
                window.location.href = '/organization-management';
              }, 2000);
            } else {
              setLoader(false);
            }
          })
          .catch((err) => {
            setLoader(false);
            console.log("err", err);
          });
        setNotification(true);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const changeHandler = (e) => {
    setaddData({
      ...addData,
      [e.target.name]: e.target.value,
    });
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Organization Name<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="text"
                  name="orgname"
                  value={addData.orgname}
                  onChange={changeHandler}
                  error={errors.orgnameError}
                />
                {errors.orgnameError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The organization name can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Organization Email<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="email"
                  name="orgemail"
                  value={addData.orgemail}
                  onChange={changeHandler}
                  error={errors.orgemailError}
                />
                {errors.orgemailError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                    fontWeight="light"
                  >
                    The organization email can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
            <FormControl fullWidth>
                <FormLabel className="form-label">Organization Address<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  name="orgaddress"
                  value={addData.orgaddress}
                  onChange={changeHandler}
                  multiline
                  error={errors.orgaddressError}
                />
                {/* <TextareaAutosize
                  rows={3}                  
                  style={{height: "50px !important",}}
                  name="orgaddress"
                  value={addData.orgaddress}
                  onChange={changeHandler}
                  error={errors.orgaddressError}
                /> */}
                {errors.orgaddressError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                    fontWeight="light"
                  >
                    The organization address can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Organization Phone<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="number"
                  name="orgphone"
                  value={addData.orgphone}
                  onChange={changeHandler}
                  error={errors.orgphoneError}
                />
                {errors.orgphoneError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The organization phone can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Organization City<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="text"
                  name="orgcity"
                  value={addData.orgcity}
                  onChange={changeHandler}
                  error={errors.orgcityError}
                />
                {errors.orgcityError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The organization city can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Organization State<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="text"
                  name="orgstate"
                  value={addData.orgstate}
                  onChange={changeHandler}
                  error={errors.orgstateError}
                />
                {errors.orgstateError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The organization state can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Organization Zip Code<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="number"
                  name="orgzipcode"
                  value={addData.orgzipcode}
                  onChange={changeHandler}
                  error={errors.orgzipcodeError}
                />
                {errors.orgzipcodeError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The organization zip code can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Select your country<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <Autocomplete
                  options={options}
                  value={
                    options.find((option) => option.value === orgcountry) ||
                    null
                  }
                  onChange={(event, selectedOption) =>
                    setOrgCountry(selectedOption?.value || "")
                  }
                  renderInput={(params) => (
                    <TextField {...params} />
                  )}
                />
                {errors.orgcountryError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The country cannot be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Organization Website (Optional)</FormLabel>
                <MDInput
                  type="text"
                  name="orgurl"
                  value={addData.orgurl}
                  onChange={changeHandler}
                />
              </FormControl>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={3}>
            {notification && (
              <Grid item xs={12}>
                <Box mb={2}>
                  <MDAlert color="info">{mess}</MDAlert>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Member Name<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="text"
                  name="name"
                  value={addData.name}
                  onChange={changeHandler}
                  error={errors.nameError}
                />
                {errors.nameError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The name can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Member Email<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="email"
                  name="email"
                  value={addData.email}
                  onChange={changeHandler}
                  error={errors.emailError}
                />
                {errors.emailError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The email can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Member Phone<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="number"
                  name="phone"
                  value={addData.phone}
                  onChange={changeHandler}
                  error={errors.phoneError}
                />
                {errors.phoneError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The phone can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Password<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="password"
                  name="password"
                  value={addData.password}
                  onChange={changeHandler}
                  error={errors.passwordError}
                />
                <small style={{ fontSize: '10px' }}>(Password must be at least 6 characters)</small>
                {errors.passwordError && (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The password can not be null
                  </MDTypography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel className="form-label">Confirm Password<MDTypography color="error" variant="body2" sx={{ display: 'inline-block' }}>*</MDTypography></FormLabel>
                <MDInput
                  type="password"
                  name="cPassword"
                  value={addData.cPassword}
                  onChange={changeHandler}
                  error={errors.cPasswordError}
                />
                {errors.cPasswordError ? (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The confirm password can not be null
                  </MDTypography>
                ) : errors.cPasswordError ? (
                  <MDTypography
                    variant="caption"
                    color="error"
                  >
                    The password do not match
                  </MDTypography>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <Modal className="modal" open={open} onClose={handleClose}>
      <Paper className="modal-dialog modal-lg">
        <Box className="modal-content">
          <Box className="modal-header">
            <MDTypography id="modal-modal-title" variant="h6" component="h2">
              Add Organization
            </MDTypography>
            <IconButton
              className="close_button"
              onClick={handleClose}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className="modal-body">
            {activeStep === steps.length ? (
              <>
                {notification && (
                  <Box mb={2}>
                    <MDAlert color="success">Your Organization has been Created</MDAlert>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Stepper activeStep={activeStep} className="stepper" sx={{ mb: 4, padding: 2 }}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {getStepContent(activeStep)}
              </>
            )}
          </Box>
          <Box className="modal-footer">
            <Stack spacing={2} direction="row">
              <MDButton variant="text" color="info" disabled={activeStep === 0} onClick={handleBack}>
                Back
              </MDButton>
              <MDButton variant="contained" color="info" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </MDButton>
            </Stack>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default Ragistration;
