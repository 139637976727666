import React, { useEffect, useState, useContext } from "react";
// import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Modal from "@mui/material/Modal";
import { Box, IconButton, Menu, MenuItem, Paper } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context";
import AuthService from "services/auth-service";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function logout() {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(true);    
    const [isRedirecting, setIsRedirecting] = useState(false);

      const closeModal = () => {
        setIsRedirecting(true);
      };
      if (isRedirecting) {
        navigate("/dashboard");
      }

      const handleLogOut = async () => {
        const response = await AuthService.logoutPost();
        authContext.logout();
      };
    return (
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        className="modal"
      >
        <Paper className="modal-dialog modal-sm">
          <Box className="modal-content">                      
            <Box className="modal-header">
              <MDTypography
                className="text-center"
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Logout Confirmation
              </MDTypography>
              <IconButton
                color="error"
                className="close_button"
                onClick={closeModal}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box className="modal-body">
              <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to logout?
              </MDTypography>
            </Box>
            <Box className="modal-footer">
              <MDButton
                variant="text"
                color="info"
                onClick={() => {
                    closeModal();
                }}
              >
                No
              </MDButton>
              <MDButton
                color="info"
                onClick={handleLogOut}
              >
                Yes
              </MDButton>
            </Box>
          </Box>
        </Paper>
      </Modal>
    )
}

export default logout