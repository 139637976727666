import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import { Typography, FormLabel } from "@mui/material";
import LoadingData from "loader/loader";
import MenuItem from "@mui/material/MenuItem";
import MDButton from "components/MDButton";
import axios from "../../axios/axios";

function ReportDownload({ open, handleClose }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loader, setLoader] = useState(false);
  const [partnerData, setPartnerData] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [dateRangeError, setDateRangeError] = useState("");
  const [partnerError, setPartnerError] = useState("");

  const handleDownloadCSV = () => {
    setDateRangeError("");
    setPartnerError("");
    setStartDateError("");
    const currentDate = new Date().toISOString().split("T")[0];

    if (!selectedPartner) {
      setPartnerError("Please select a partner");
      return;
    }
    if (!startDate) {
      setStartDateError("Date is Required");
      return;
    }
    if (startDate > currentDate) {
      setStartDateError("Selected date should not be in the future");
      return;
    }
    if (!endDate) {
      setDateRangeError("Date is Required");
      return;
    }

    if (startDate > endDate) {
      setDateRangeError("Invalid date range");
      return;
    }
    if (startDate > currentDate || endDate > currentDate) {
      setDateRangeError("Selected date should not be in the future");
      return;
    }

    const paramsData = {
      partner_id: selectedPartner,
      from_date: startDate,
      to_date: endDate,
    };

    setLoader(true); // Set loader to true before making the request

    axios
      .post("/admin/partner-report", paramsData)
      .then((res) => {
        setLoader(false);
        const csvContent = res.data.data
          .map((entry) => Object.values(entry).join(","))
          .join("\n");

        // Create a Blob and initiate download
        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "PARTNER_REPORT.csv";
        link.click();
      })
      .catch((err) => {
        setLoader(false);
        console.error("Error downloading report", err);
        // Add additional error handling or show a user-friendly error message
      });
  };

  const getPartner = async () => {
    setLoader(true);
    await axios
      .get("/admin/partners")
      .then((res) => {
        setLoader(false);
        console.log(res.data.data);
        setPartnerData(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getPartner();
  }, []);
  // const handleDownloadCSV = () => {
  //         const data = [
  //           { name: "md", phone: "787878778" },
  //           { name: "raj", phone: "78787877878" },
  //         ];
  //       // Convert data array to CSV string
  //       const csvContent = data
  //         .map((entry) => Object.values(entry).join(","))
  //         .join("\n");

  //       // Create a Blob and initiate download
  //       const blob = new Blob([csvContent], { type: "text/csv" });
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(blob);
  //       link.download = "report.csv";
  //       link.click();
  //     };

  return (
    <>
      {loader && <LoadingData />}
      <Modal className="modal" open={open} onClose={handleClose}>
        <Paper className="modal-dialog">
          <Box className="modal-content">
            <Box className="modal-header">
              <Typography variant="h6" component="h2">
                Report Download
              </Typography>
              <IconButton className="close_button" onClick={handleClose}>
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box className="modal-body">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel className="form-label">
                      Select your partner for the report
                    </FormLabel>
                    <Select
                      id="formBasicOrganization"
                      name="partner"
                      placeholder="Select..."
                      value={selectedPartner}
                      className="member_select_box"
                      onChange={(e) => {
                        setSelectedPartner(e.target.value);
                        setPartnerError("");
                      }}
                      style={{ height: 44 }}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {partnerData.map((val, i) => (
                        <MenuItem key={i} value={val._id}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {partnerError && (
                      <Typography color="error" style={{ marginTop: "8px" }}>
                        {partnerError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel className="form-label">From Date</FormLabel>
                    <MDInput
                      type="date"
                      placeholder="dd-mm-yyyy"
                      name="startDate"
                      value={startDate}
                      onChange={(event) => setStartDate(event.target.value)}
                    />
                    {startDateError && (
                      <Typography color="error" style={{ marginTop: "8px" }}>
                        {startDateError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel className="form-label">To Date</FormLabel>
                    <MDInput
                      type="date"
                      placeholder="dd-mm-yyyy"
                      name="endDate"
                      value={endDate}
                      onChange={(event) => setEndDate(event.target.value)}
                    />
                    {dateRangeError && (
                      <Typography color="error" style={{ marginTop: "8px" }}>
                        {dateRangeError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box className="modal-footer">
              <MDButton onClick={handleDownloadCSV} color="info">
                Download CSV
              </MDButton>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </>
  );
}

export default ReportDownload;
