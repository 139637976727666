import HttpService from "./htttp.service";
import Axios from "../axios/axios";
class AuthService {
  // authEndpoint = process.env.API_URL;

  loginPost = async (payload) => {
    const loginEndpoint = `${Axios.defaults.baseURL}/admin/login`;
    return await HttpService.loginPost(loginEndpoint, payload);
  };

  logoutPost = async (headers) => {
    const logoutEndpoint = `${Axios.defaults.baseURL}/admin/logout`;
    return await HttpService.logoutPost(logoutEndpoint, headers);
  };

  forgotPassword = async (payload) => {
    const logoutEndpoint = `${Axios.defaults.baseURL}/forgot-password`;
    return await HttpService.forgotPasswordPost(logoutEndpoint, payload);
  };
  resetPassword = async (payload) => {
    const logoutEndpoint = `${Axios.defaults.baseURL}/reset-password`;
    return await HttpService.resetPasswordPost(logoutEndpoint, payload);
  };
}

export default new AuthService();
