// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import axios from "../../axios/axios"
import moment from "moment";
import { IconButton, Menu, MenuItem, Paper } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MDAlert from "components/MDAlert";
import Modal from "@mui/material/Modal";
import MDButton from "components/MDButton";
import { useParams, Link } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";


export default function creditData() {
  const [loader, setLoader] = useState(false);
  const [tviPlans, setTviPlans] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [notification, setNotification] = useState(false);

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteItemId(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const getTviPlans = async () => {
    setLoader(true);
    await axios
      .get("/admin/credit-plans")
      .then((res) => {
        setLoader(false);
        setTviPlans(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  }
  useEffect(() => {
    getTviPlans()
  }, [])


  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  const handlePlanDelete = (plan_id) => {
    axios
      .post("/admin/credit-plan-delete", { credit_plan_id: plan_id })
      .then((res) => {
        if (res.data.status === true) {
          setTimeout(() => {
            closeDeleteModal();
            getTviPlans();
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("deleteUser err", err);
      });
    setNotification(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


  const rowsData = tviPlans.map((item) => {
    return (
      {
        name: <Author name={`${item.name}`} email="" />,
        price: <Job title={`${item.price}`} description="" />,
        credit: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.credit}
          </MDTypography>
        ),
        status: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {`${item.price}`}
          </MDTypography>
        ),
        created: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {moment(item.createdAt).format("MM-DD-YYYY HH:mm:ss")}
          </MDTypography>
        ),
        action: (
          <MDBox>
            <IconButton
              aria-label="more"
              aria-controls="dot-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="dot-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                component={Link} // Use Link component here
                to={`/uses-update/${item._id}`}
                variant="caption"
                color="text"
                fontWeight="medium"
                mr={2}
              >
                Edit
              </MenuItem>
              <MenuItem
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  openDeleteModal();
                  setDeleteItemId(item._id);
                }}
              >
                Delete
              </MenuItem>
            </Menu>
            {deleteItemId === item._id && (
              <Modal
                open={isDeleteModalOpen}
                onClose={closeDeleteModal}
                centered
                size="lg"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDBox sx={style} className="email_modal org_modalll">
                  {notification && (
                    <MDAlert color="info" mt="20px">
                      <MDTypography variant="body2" color="white">
                        Your plan has been deleted
                      </MDTypography>
                    </MDAlert>
                  )}
                  <MDTypography
                    className="text-center"
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Delete Confirmation
                  </MDTypography>
                  <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to delete this item?
                  </MDTypography>
                  <div className="btn__group">
                    <MDButton
                      variant="secondary"
                      className="yes_but"
                      onClick={() => {
                        handlePlanDelete(item._id);
                      }}
                    >
                      Yes
                    </MDButton>

                    <MDButton
                      variant="danger"
                      className="no_but"
                      onClick={() => {
                        closeDeleteModal();
                      }}
                    >
                      No
                    </MDButton>
                  </div>
                  <MDButton
                    variant="secondary"
                    className="close_buutt"
                    onClick={closeDeleteModal}
                  >
                    X
                  </MDButton>
                </MDBox>
              </Modal>
            )}
          </MDBox>
        ),
      }
    )

  })

  return {
    // columns: [
    //     { Header: "name", accessor: "name", width: "45%", align: "left" },
    //     { Header: "credit", accessor: "credit", align: "left" },
    //     { Header: "price", accessor: "price", align: "left" },
    //     { Header: "created", accessor: "created", align: "left" },
    //     { Header: "status", accessor: "status", align: "center" },
    //     { Header: "action", accessor: "action", align: "center" },
    //   ],

    columns: [
      {
        name: 'Name',
        selector: row => row.name,
        minWidth: '150px',
        maxWidth: '150px',
        sortable: true,
      },
      {
        name: 'Credit',
        selector: row => row.credit,
        minWidth: '300px',
        //minWidth: '300px',
        //sortable: true,
      },
      {
        name: 'Price',
        selector: row => row.price,
        minWidth: '200px',
        //sortable: true,
      },
      {
        name: 'Status',
        selector: row => row.status,
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'Created',
        selector: row => moment(row.createdAt).format("MM-DD-YYYY HH:mm:ss"),
        sortable: true,
        sortField: 'created',
        minWidth: '150px',
      },


      {
        name: 'Action',
        minWidth: '80px',
        maxWidth: '80px',
        selector: row => {
          return (
            <MDBox>
              <IconButton
                aria-label="more"
                aria-controls="dot-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="dot-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  component={Link} // Use Link component here
                  to={`/credit-update/${row._id}`}
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  mr={2}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    openDeleteModal();
                    setDeleteItemId(row._id);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
              {deleteItemId === row._id && (
                <Modal
                  open={isDeleteModalOpen}
                  onClose={closeDeleteModal}
                  className="modal"
                >
                  <Paper className="modal-dialog modal-sm">
                    <Box className="modal-content">                      
                      <Box className="modal-header">
                        <MDTypography
                          className="text-center"
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Delete Confirmation
                        </MDTypography>
                        <IconButton
                          className="close_button"
                          onClick={closeDeleteModal}
                        >
                          <CloseOutlinedIcon />
                        </IconButton>
                      </Box>
                      <Box className="modal-body">
                        {notification && (
                          <Box mb={2}>
                            <MDAlert color="info">
                                Your plan has been deleted
                            </MDAlert>
                          </Box>
                        )}
                        <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
                          Are you sure you want to delete this item?
                        </MDTypography>
                      </Box>
                      <Box className="btn__group"> 
                        <MDButton
                          variant="text"
                          color="info"
                          onClick={() => {
                            closeDeleteModal();
                          }}
                        >
                          No
                        </MDButton>
                        <MDButton
                          color="error"
                          onClick={() => {
                            handlePlanDelete(row._id);
                          }}
                        >
                          Yes
                        </MDButton>
                      </Box>
                      
                    </Box>
                  </Paper>
                </Modal>
              )}
            </MDBox>
          )
        },
        //sortable: true,
      }
    ],

    rows: tviPlans,
    loader: loader

  };
}
