import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import LoadingData from "../../loader/loader";
import Footer from "examples/Footer";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  FormControl,
  FormLabel
} from "@mui/material";

import axios from "../../axios/axios";

const OrgUpdate = () => {
  const { id } = useParams(); // Access URL parameters
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [notification, setNotification] = useState(false);
  const [orgUpdate, setOrgUpdate] = useState({
    name: "",
    address: "",
    phone: "",
    url: "",
    description: "",
    facebook: "",
    instagram: "",
    twitter: "",
    linkedin: "",
    wordpress: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
    addressError: false,
    phoneError: false,
    descriptionError: false,
    // facebookError: false,
    // instagramError: false,
    // twitterError: false,
    // linkedinError: false,
    // wordpressError: false,
  });

  const getOrgById = async () => {
    setLoader(true);
    await axios
      .get(`/admin/organization/${id}`)
      .then((res) => {
        setLoader(false);

        const responseData = res.data.data;

        setOrgUpdate({
          name: responseData.name,
          address: responseData.address,
          phone: responseData.phone,
          url: responseData.url,
          description: responseData.description,
          facebook: responseData.facebook,
          instagram: responseData.instagram,
          twitter: responseData.twitter,
          linkedin: responseData.linkedin,
          wordpress: responseData.wordpress,
        });
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getOrgById();
  }, []);

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    setOrgUpdate({
      ...orgUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // Check if orgUpdate is defined and not null
    if (!orgUpdate) {
      console.error("orgUpdate is undefined or null");
      return;
    }

    // Initialize errors object
    let newErrors = {
      nameError: orgUpdate.name ? orgUpdate.name.trim().length === 0 : true,
      addressError: orgUpdate.address
        ? orgUpdate.address.trim().length === 0
        : true,
      phoneError: orgUpdate.phone ? orgUpdate.phone.trim().length === 0 : true,
      descriptionError: orgUpdate.description
        ? orgUpdate.description.trim().length === 0
        : true,
      // facebookError: orgUpdate.facebook
      //   ? orgUpdate.facebook.trim().length === 0
      //   : true,
      // instagramError: orgUpdate.instagram
      //   ? orgUpdate.instagram.trim().length === 0
      //   : true,
      // twitterError: orgUpdate.twitter
      //   ? orgUpdate.twitter.trim().length === 0
      //   : true,
      // linkedinError: orgUpdate.linkedin
      //   ? orgUpdate.linkedin.trim().length === 0
      //   : true,
      // wordpressError: orgUpdate.wordpress
      //   ? orgUpdate.wordpress.trim().length === 0
      //   : true,
    };

    // Check if any errors exist
    if (Object.values(newErrors).some((error) => error)) {
      // If there are errors, set them and return
      setErrors(newErrors);
      return;
    }

    // Call the API for update
    const paramsData = {
      org_id: id,
      name: orgUpdate.name,
      address: orgUpdate.address,
      phone: orgUpdate.phone,
      description: orgUpdate.description,
      facebook: orgUpdate.facebook,
      instagram: orgUpdate.instagram,
      twitter: orgUpdate.twitter,
      linkedin: orgUpdate.linkedin,
      wordpress: orgUpdate.wordpress,
    };

    axios
      .post("/admin/update-organization", paramsData)
      .then((res) => {
        if (res.data.status === true) {
          setIsRedirecting(true);
        }
      })
      .catch((err) => {
        console.log("update err", err);
      });

    // Reset errors and show notification
    setErrors({
      nameError: false,
      addressError: false,
      phoneError: false,
      descriptionError: false,
      // facebookError: false,
      // instagramError: false,
      // twitterError: false,
      // linkedinError: false,
      // wordpressError: false,
    });

    setNotification(true);
  };

  if (isRedirecting) {
    setTimeout(() => {
      navigate("/organization-management");
    }, 2000);
  }

  return (
    <>
      {loader && <LoadingData />}
      <DashboardNavbar />
      <DashboardLayout>
      <MDBox className="primary_container" pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center" 
              >
                <MDTypography variant="h5" color="white" sx={{ textTransform: 'uppercase' }}>Edit Organization
                </MDTypography>
              </MDBox>                  
              <MDBox
                component="form"
                role="form"
                onSubmit={submitHandler}
                px={2}
                py={4}
              >
                {notification && (
                  <Box mb={2}>
                    <MDAlert color="success">Your organization has been updated</MDAlert>
                  </Box>
                )}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Organization Name<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="name"
                        name="name"
                        value={orgUpdate.name}
                        onChange={changeHandler}
                        error={errors.nameError}
                      />
                      {errors.nameError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The Organization name can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Organization Address<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="text"
                        name="address"
                        value={orgUpdate.address}
                        onChange={changeHandler}
                        error={errors.addressError}
                      />
                      {errors.addressError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The Organization address can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Organization Phone<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="text"
                        name="phone"
                        value={orgUpdate.phone}
                        onChange={changeHandler}
                        error={errors.phoneError}
                      />
                      {errors.phoneError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The Organization phone can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">About The Organization<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="text"
                        name="description"
                        value={orgUpdate.description}
                        onChange={changeHandler}
                        error={errors.descriptionError}
                      />
                      {errors.descriptionError && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The organization about can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Organization website address (Optional)</FormLabel>
                      <MDInput
                        type="text"
                        name="url"
                        value={orgUpdate.url}
                        onChange={changeHandler}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Facebook page link (Optional)</FormLabel>
                      <MDInput
                        type="text"
                        name="facebook"
                        value={orgUpdate.facebook}
                        onChange={changeHandler}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Instagram page link (Optional)</FormLabel>
                      <MDInput
                        type="text"
                        name="instagram"
                        value={orgUpdate.instagram}
                        onChange={changeHandler}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Twitter page link (Optional)</FormLabel>
                      <MDInput
                        type="text"
                        name="twitter"
                        value={orgUpdate.twitter}
                        onChange={changeHandler}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Linkedin page link (Optional)</FormLabel>
                      <MDInput
                        type="text"
                        name="linkedin"
                        value={orgUpdate.linkedin}
                        onChange={changeHandler}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Blog page link (Optional)</FormLabel>
                      <MDInput
                        type="text"
                        name="wordpress"
                        value={orgUpdate.wordpress}
                        onChange={changeHandler}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <MDButton variant="gradient" color="info" type="submit">
                      Save changes
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
    </>
  );
};

export default OrgUpdate;
