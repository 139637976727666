// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import axios from "../../axios/axios"
import moment from 'moment'

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function loginData() {
  const [loader, setLoader] = useState(false);
  const [loginHistory, setLoginHistory] = useState([]);

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );

  // const Job = ({ title, description }) => (
  //   <MDBox lineHeight={1} textAlign="left">
  //     <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
  //       {title}
  //     </MDTypography>
  //     <MDTypography variant="caption">{description}</MDTypography>
  //   </MDBox>
  // );

  const getFilterLoginHistory = async (orgIdAttr) => {
    setLoader(true);
    await axios
      .get("/admin/login-history/" + orgIdAttr)
      .then((res) => {
        setLoader(false);
        setLoginHistory(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };


  const getLoginHistory = async () => {
    setLoader(true);
    await axios
      .get("/admin/login-history")
      .then((res) => {
        setLoader(false);
        setLoginHistory(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  }
  useEffect(() => {
    getLoginHistory()
  }, [])

  // const rowsData = loginHistory.map((item) => {
  //   return (
  //     {
  //       name: <Author name={`${item.user_info.name}`} email="" />,
  //       phone: <Job title={`${item.user_info.phone}`} description="" />,
  //       email: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {`${item.user_info.email}`}
  //         </MDTypography>
  //       ),
  //       type: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {`${item.user_info.type}`}
  //         </MDTypography>
  //       ),
  //       login_at: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {moment(item.login_at).format("MM-DD-YYYY HH:mm:ss")}
  //         </MDTypography>
  //       ),
  //       status: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {`${item.user_info.status}`}
  //         </MDTypography>
  //       )
  //     }
  //   )

  // })

  return {
    // columns: [
    //     { Header: "name", accessor: "name", width: "45%", align: "left" },
    //     { Header: "email", accessor: "email", align: "left" },
    //     { Header: "phone", accessor: "phone", align: "left" },
    //     { Header: "type", accessor: "type", align: "left" },
    //     { Header: "login at", accessor: "login_at", align: "left" },
    //     { Header: "status", accessor: "status", align: "center" },
    //   ],

    columns: [
      {
        name: 'Sl No.',
        selector: (row) => row.sl_no,
        minWidth: '80px',
        maxWidth: '80px',
        sortable: true,
      },
      {
        name: 'Member Name',
        selector: row => row.user_info.name,
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'Organization Name',
        selector: row => row?.org_info?.name,
        //maxWidth: '250px',
        minWidth: '250px',
        sortable: true,
      },
      {
        name: 'Member Email',
        selector: row => row.user_info.email,
        minWidth: '250px',
        sortable: true,
      },
      // {
      //   name: 'Member Phone',
      //   selector: row => row.user_info.phone,
      //   width: '150px',
      //   //sortable: true,
      // },
      {
        name: 'Member Type',
        selector: row => row.user_info.type,
        minWidth: '150px',
        sortable: true,
      },
      {
        name: 'Member Last Login',
        selector: row => moment(row.login_at).format("MM-DD-YYYY HH:mm:ss"),
        sortable: true,
        sortField: 'login_at',
        minWidth: '250px',
      },
      {
        name: 'Member Machine Ip',
        selector: row => row?.ip,
        minWidth: '170px',
        sortable: true,
      },
    ],

    rows: loginHistory,
    loaders: loader,
    getFilterLoginHistory: getFilterLoginHistory

  };
}
