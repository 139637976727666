import React from 'react'
import { Oval } from "react-loader-spinner";
import "./loader.css";
function loader() {
  return (
    <div className="loader">
      <Oval
        visible={true}
        height="80"
        width="80"
        color="#0060eb"
        secondaryColor="#ffffff"
        wrapperStyle={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          alignItems: "center",
          zIndex: "9999",
          justifyContent: "center",
          backgroundColor: "rgb(11 11 22 / 85%)",
        }}
        wrapperClass="page_loader"
      />
    </div>
  )
}

export default loader;