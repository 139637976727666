import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "../../axios/axios";
import './plan.css';
import { Box, Card, FormControl, FormLabel, Grid, InputLabel } from "@mui/material";
const AddPlan = () => {
  const { id } = useParams(); // Access URL parameters
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [notification, setNotification] = useState(false);
  const [fields, setFields] = useState(['']);
  const [planAdd, setplanAdd] = useState({
    name: "",
    price: "",
    description: "",
    plan_type: "",
    featureDesc: [],
    for: "",
    credit: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
    priceError: false,
    descriptionError: false,
    plan_typeError: false,
    featureDescError: false,
    forError: false,
    creditError: false,
  });

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    setplanAdd({
      ...planAdd,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (planAdd.name.trim().length === 0) {
      setErrors({ ...errors, nameError: true });
      return;
    }
    if (planAdd.price.length === 0) {
      setErrors({ ...errors, priceError: true });
      return;
    }
    if (planAdd.description.trim().length === 0) {
      setErrors({ ...errors, descriptionError: true });
      return;
    }
    if (fields.length === 0) {
      setErrors({ ...errors, featureDescError: true });
      return;
    }
    if (planAdd.plan_type.trim().length === 0) {
      setErrors({ ...errors, plan_typeError: true });
      return;
    }
    if (planAdd.for.trim().length === 0) {
      setErrors({ ...errors, forError: true });
      return;
    }
    if (planAdd.credit.trim().length === 0) {
      setErrors({ ...errors, creditError: true });
      return;
    }

    let fDescription = [];
    for (let index = 0; index < fields.length; index++) {
      const element = {
        description: fields[index],
        limit: 0
      };
      fDescription.push(element);
    }
    // call api for update
    const paramsData = {
      plan_id: id,
      name: planAdd.name,
      description: planAdd.description,
      plan_type: planAdd.plan_type,
      price: planAdd.price,
      // features: [
      //   {
      //     description: planAdd.featureDesc,
      //     limit: 0,
      //   },
      // ],
      features: fDescription,
      for: planAdd.for,
      credit: planAdd.credit
    };
    axios
      .post("/admin/plan-add", paramsData)
      .then((res) => {
        if (res.data.status === true) {
          setIsRedirecting(true);
        }
      })
      .catch((err) => {
        console.log("Plan add", err);
      });

    // reset errors
    setErrors({
      nameError: false,
      priceError: false,
      descriptionError: false,
      plan_typeError: false,
      featureDescError: false,
      forError: false,
      creditError: false,
    });

    setNotification(true);
  };

  if (isRedirecting) {
    navigate("/plan-management");
  }
  const handleAddField = () => {
    // Add a new empty field to the state array
    setFields([...fields, '']);
  };

  const handleRemoveField = (index) => {
    // Handle edge case: prevent removing the last field
    if (fields.length === 1) {
      alert('Cannot remove the last field!');
      return;
    }

    // Create a copy of the array, slice to exclude the field at `index`
    const updatedFields = fields.slice(0, index).concat(fields.slice(index + 1));
    setFields(updatedFields);
  };

  const handleChangeForMultipleField = (index, value) => {
    // Update the field value at `index` in the state array
    // setFields((prevFields) =>
    //   prevFields.map((field, i) => (i === index ? value : field))
    // );
    const updatedTextFields = [...fields];
    updatedTextFields[index] = value; // Update the value at the given index
    setFields(updatedTextFields);
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox className="primary_container" pt={3} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography 
                      variant="h5" 
                      color="white"
                      sx={{ textTransform: 'uppercase' }}
                    >
                      Add New Plan
                    </MDTypography>
                  </MDBox>                  
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={submitHandler}
                    py={4} px={2}
                  >
                    {notification && (
                      <Box mb={2}>
                        <MDAlert color="success">Your plan has been added</MDAlert>
                      </Box>
                    )}

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <FormLabel className="form-label">Plan Name<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                          <MDInput
                            type="name"
                            name="name"
                            value={planAdd.name}
                            onChange={changeHandler}
                            error={errors.nameError}
                          />
                          {errors.nameError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                            >
                              The plane name can not be null
                            </MDTypography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} >
                        <FormControl fullWidth>
                          <FormLabel className="form-label">Cost ($)<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                          <MDInput
                            type="text"
                            name="price"
                            value={planAdd.price}
                            onChange={changeHandler}
                            error={errors.priceError}
                          />
                          {errors.priceError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                            >
                              The cost can not be null
                            </MDTypography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <FormControl fullWidth>
                          <FormLabel className="form-label">Plan Description<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                          <MDInput
                            type="text"
                            name="description"
                            value={planAdd.description}
                            onChange={changeHandler}
                            error={errors.descriptionError}
                            multiline
                            rows={6}
                            maxRows={10}
                          />
                          {errors.descriptionError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                            >
                              The plan description can not be null
                            </MDTypography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <FormLabel className="form-label">Plan Type<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                          <Select
                            labelId="formBasicOrganizationLabel"
                            id="formBasicOrganization"
                            name="plan_type"
                            value={planAdd.plan_type}
                            onChange={changeHandler}
                            style={{ height: 44 }}
                          >
                            <MenuItem value="Month">Monthly</MenuItem>
                            <MenuItem value="Year">Yearly</MenuItem>
                          </Select>
                          {errors.plan_typeError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                            >
                              The Plan Type can not be null
                            </MDTypography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <FormLabel className="form-label">Select User Type<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                          <Select
                            labelId="formBasicOrganizationLabel"
                            id="formBasicOrganization"
                            name="for"
                            value={planAdd.for}
                            onChange={changeHandler}
                            className="member_select_box"
                            style={{ height: 44 }}
                          >
                            <MenuItem value="Individual">Individual</MenuItem>
                            <MenuItem value="Organization">Organization</MenuItem>
                          </Select>
                          {errors.forError && (
                            <MDTypography variant="caption" color="error">
                              The type can not be null
                            </MDTypography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <FormLabel className="form-label">Set plan credits<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                          <MDInput
                            type="text"
                            name="credit"
                            value={planAdd.credit}
                            onChange={changeHandler}
                            error={errors.creditError}
                          />
                          {errors.creditError && (
                            <MDTypography variant="caption" color="error">
                              The credit can not be null
                            </MDTypography>
                          )}
                        </FormControl>
                      </Grid>                    

                      <Grid item xs={12} md={12} mt={4}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="medium"
                          >
                            Features Description
                          </MDTypography>
                          <MDButton variant="outlined" onClick={handleAddField} color="success" type="button">Add More Field</MDButton>
                        </Box>
                          
                        <Box>
                          {fields.map((field, index) => (
                            <Grid container spacing={3} alignItems="center" mb={2}>
                              <Grid item xs={12} md={10}>
                                <MDInput
                                  type="text"
                                  fullWidth
                                  name="features"
                                  value={field}
                                  onChange={(e) => handleChangeForMultipleField(index, e.target.value)}
                                  multiline
                                  rows={3}
                                  maxRows={10}
                                  placeholder="Type your Features Description..."
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <MDButton variant="outlined" onClick={() => handleRemoveField(index)} color="error" type="button">
                                  Remove
                                </MDButton>
                              </Grid>
                            </Grid>
                          ))}
                          {errors.featureDescError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              fontWeight="light"
                            >
                              The features description can not be null
                            </MDTypography>
                          )}
                        </Box>

                      </Grid>

                      <Grid item xs={12}>
                        <MDButton variant="gradient" color="info" type="submit">
                          Add Plan
                        </MDButton>
                      </Grid>


                    </Grid>


                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default AddPlan;
