// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import LoadingData from "../../loader/loader";

// Dashboard components
import axios from "../../axios/axios";
import React, {useEffect, useState} from "react";

function Dashboard() {
  // const { sales, tasks } = reportsLineChartData;
  const [dashboardData, setDashboardData] = useState([]);
  const [loader, setLoader] = useState(false);

  const getOrganization = async () => {
    setLoader(true);
    await axios
      .get("/admin/statistics")
      .then((res) => {
        setLoader(false);
        setDashboardData(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };
  useEffect(() => {
    getOrganization();
  }, []);

  const member = {
    labels: dashboardData.labels,
    datasets: { label: "Total Members", data: dashboardData.member_datasets },
  }

  const org = {
    labels: dashboardData.labels,
    datasets: { label: "Total Organizations", data: dashboardData.org_datasets },
  }

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox py={3}>
          {loader && <LoadingData/>}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Total Organizations"
                  count={dashboardData.total_org}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+55%",
                  //   label: "than lask week",
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Total Members"
                  count={dashboardData.total_member}
                  // percentage={{
                  //   color: "success",
                  //   amount: "",
                  //   label: "Just updated",
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Today Register"
                  count={dashboardData.daily_register}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+1%",
                  //   label: "than yesterday",
                  // }}
                />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title="Total Organizations"
                    description="Monthly Joined Organizations Revenue"
                    // date="just updated"
                    chart={org}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title="Total Members"
                    description="Monthly Joined Members Revenue"
                    // date="just updated"
                    chart={member}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Dashboard;
