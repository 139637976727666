import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useParams, Link, useNavigate } from "react-router-dom";
import MultiSelect from 'react-select';

import axios from "../../axios/axios";
import { Box, Card, FormControl, FormLabel, Grid } from "@mui/material";

const AddPrompt = () => {
  const { id } = useParams(); // Access URL parameters
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [notification, setNotification] = useState(false);
  const [allOrgData, setallOrgData] = useState([]);
  const [orgReqData, setorgReqData] = useState([]);
  const [orgOptionData, setorgOptionData] = useState([]);
  // const [authData, setAuthData] = useState({});
  const [platformsReqData, setplatformsReqData] = useState([]);
  const [promptData, setpromptData] = useState({
    type: "",
    platforms: [],
    description: "",
    organizations: [],
    hiddenPrompt: "",
  });
  const authData = JSON.parse(localStorage.getItem("adminAuth"));
  const [errors, setErrors] = useState({
    typeError: false,
    platformsError: false,
    descriptionError: false,
    organizationsError: false,
    // hiddenPrompt: false,
  });

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  useEffect(async () => {
    await axios
      .get("/admin/prompt-org-list")
      .then((res) => {
        setallOrgData(res.data.data);
        // console.log(res.data.data);
        const allOPTion = [
          { value: '*', label: 'Select All' }
        ];
        for (let OrgOpIndex = 0; res.data.data.length > OrgOpIndex; OrgOpIndex++) {
          allOPTion.push({
            value: res.data.data[OrgOpIndex]._id,
            label: res.data.data[OrgOpIndex].name
          });
        }

        setorgOptionData(allOPTion);
      })
      .catch((err) => {
        console.log("err", err);
      });

  }, []);

  const changeHandler = (e) => {
    setpromptData({
      ...promptData,
      [e.target.name]: e.target.value,
    });
  };

  const orgchangeHandler = (e) => {
    let flag = true;
    let od = [{
      value: '*',
      label: 'Select All'
    }];
    for (let index = 0; index < e.length; index++) {
      if (e[index].value == "*") {
        flag = false;
        setorgReqData(od);
      }
    }
    if (flag) {
      setorgReqData(e);
    }
  };

  const platformchangeHandler = (e) => {
    let flag = true;
    let od = [{
      value: '*',
      label: 'Select All'
    }];
    for (let index = 0; index < e.length; index++) {
      if (e[index].value == "*") {
        flag = false;
        setplatformsReqData(od);
      }
    }
    if (flag) {
      setplatformsReqData(e);
    }

  };

  const options = [
    { value: '*', label: 'Select All' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'LinkedIn', label: 'LinkedIn' },
    { value: 'Twitter', label: 'Twitter' },
    { value: 'WordPress Blog', label: 'WordPress Blog' },
  ];

  // const submitHandler = async (e) => {
  //   e.preventDefault();

  //   if (planUpdate.name.trim().length === 0) {
  //     setErrors({ ...errors, nameError: true });
  //     return;
  //   }
  //   if (planUpdate.price.length === 0) {
  //     setErrors({ ...errors, priceError: true });
  //     return;
  //   }
  //   if (planUpdate.description.trim().length === 0) {
  //     setErrors({ ...errors, descriptionError: true });
  //     return;
  //   }
  //   if (planUpdate.featureDesc.trim().length === 0) {
  //     setErrors({ ...errors, featureDescError: true });
  //     return;
  //   }
  //   if (planUpdate.plan_type.trim().length === 0) {
  //     setErrors({ ...errors, plan_typeError: true });
  //     return;
  //   }

  //   // call api for update
  //   const paramsData = {
  //     plan_id: id,
  //     name: planUpdate.name,
  //     description: planUpdate.description,
  //     plan_type: planUpdate.plan_type,
  //     price: planUpdate.price,
  //     features: [
  //       {
  //         description: planUpdate.featureDesc,
  //         "limit": 1
  //       }
  //     ]
  //   }
  //   axios
  //     .post("/admin/plan-add", paramsData)
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         setIsRedirecting(true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Plan add", err);
  //     });

  //   // reset errors
  //   setErrors({
  //     nameError: false,
  //     priceError: false,
  //     descriptionError: false,
  //     plan_typeError: false,
  //     featureDescError: false,
  //   });

  //   setNotification(true);
  // };




  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(promptData);
    if (promptData.description != '' && platformsReqData.length > 0 && promptData.type != '') {
      let allOrgValue = [];
      let allPlatformsValue = [];

      for (let pIndex = 0; platformsReqData.length > pIndex; pIndex++) {
        allPlatformsValue.push(platformsReqData[pIndex].value);
      }
      if (orgReqData.length > 0) {
        for (let OrgIndex = 0; orgReqData.length > OrgIndex; OrgIndex++) {
          allOrgValue.push(orgReqData[OrgIndex].value);
        }
      }



      const paramsData = {
        type: promptData.type,
        description: promptData.description,
        organizations: allOrgValue,
        platforms: allPlatformsValue,
        hidden_prompt: promptData.hiddenPrompt,
      }
      axios
        .post("/admin/add-prompt", paramsData)
        .then((res) => {
          if (res.data.status === true) {
            setNotification(true);
            setIsRedirecting(true);
          }
        })
        .catch((err) => {
          console.log("prompt add", err);
        });


    } else {
      if (authData.data.user_info.type == "Admin" || authData.data.user_info.type == "Super Admin") {
        setErrors({
          ...errors,
          descriptionError: (promptData.description != '') ? false : true,
          platformsError: (platformsReqData.length > 0) ? false : true,
          typeError: (promptData.type != '') ? false : true,
          organizationsError: (orgReqData.length > 0) ? false : true,
        })
      } else {
        setErrors({
          ...errors,
          descriptionError: (promptData.description != '') ? false : true,
          platformsError: (platformsReqData.length > 0) ? false : true,
          typeError: (promptData.type != '') ? false : true,
          organizationsError: false,
        })
      }
    }
  };

  if (isRedirecting) {
    navigate('/prompt-management');
  }

  return (
    <>
      <DashboardNavbar />        
      <DashboardLayout>
        <MDBox className="primary_container" pt={6} pb={3}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center" 
                >
                  <MDTypography
                    variant="h5"
                    color="white"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Add Prompt
                  </MDTypography>                
                </MDBox>        
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={submitHandler}
                  px={2} py={3}
                >
                  {notification && (
                    <Box mb={2}>
                      <MDAlert color="info">
                          Your Prompt has been added
                      </MDAlert>
                    </Box>
                  )}
                  <Box>
                    <Box mb={2}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Prompt Type<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MDInput
                          type="type"
                          name="type"
                          value={promptData.type}
                          onChange={changeHandler}
                          error={errors.typeError}
                        />
                        {errors.typeError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The prompt type can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>

                    <Box mb={2}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Prompt Description<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MDInput
                          type="text"
                          fullWidth
                          name="description"
                          value={promptData.description}
                          onChange={changeHandler}
                          error={errors.descriptionError}
                        />
                        {errors.descriptionError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                          >
                            The prompt description can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>

                    <Box mb={2}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Add Hidden Prompt (Optional)</FormLabel>
                        <MDInput
                          type="type"
                          name="hiddenPrompt"
                          value={promptData.hiddenPrompt}
                          onChange={changeHandler}
                          error={errors.hiddenPromptErr}
                        />
                        {errors.hiddenPromptErr && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The hidden prompt type can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>

                    {(authData.data.user_info.type == "Admin" || authData.data.user_info.type == "Super Admin") ? (
                      <Box mb={2}>
                        <FormControl fullWidth>
                          <FormLabel className="form-label">Organization/Partners<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                            <MultiSelect
                              onChange={orgchangeHandler}
                              name="organizations"
                              value={orgReqData}
                              defaultValue={promptData.organizations}
                              options={orgOptionData}
                              isMulti
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  fontSize: '14px',
                                  minHeight: '44px',
                                  height: '44px',
                                  
                                }),
                                option: (base) => ({
                                  ...base,
                                  fontSize: '14px',
                                }),
                              }}
                            />

                            {errors.descriptionError && (
                              <MDTypography
                                variant="caption"
                                color="error"
                                fontWeight="light"
                              >
                                The organization can not be null
                              </MDTypography>
                            )}
                          </FormControl>
                        </Box>
                    ) : (
                    <></>
                    )}

                    <Box mb={2}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Prompt Platforms<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MultiSelect
                          onChange={platformchangeHandler}
                          name="platforms"
                          value={platformsReqData}
                          defaultValue={promptData.platforms}
                          options={options}
                          isMulti
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              fontSize: '14px',
                              minHeight: '44px',
                              height: '44px',
                              
                            }),
                            option: (base) => ({
                              ...base,
                              fontSize: '14px',
                            }),
                          }}
                        />
                        {errors.descriptionError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                          >
                            The platforms can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>

                    <MDButton color="info" type="submit" >
                      Add Prompt
                    </MDButton>

                  </Box>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      <Footer />
      </DashboardLayout>
    </>
  );
};

export default AddPrompt;
