import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Paper,
  Grid,
  Card,
  FormControl,
  FormLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "../../axios/axios";
import MDAlert from "components/MDAlert";
import LoadData from "../../loader/loader";
import { useNavigate } from "react-router-dom";
import MultiSelect from 'react-select';
import Select from "@mui/material/Select";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import LoadingData from "../../loader/loader";
import MenuItem from "@mui/material/MenuItem";
import Moment from 'moment';

const AddCoupon = ({ open, handleClose }) => {
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState(false);
  const [toneName, setToneName] = useState("");
  const [toneNameErr, setToneNameErr] = useState(false);
  const [planData, setplanData] = useState([]);
  const navigate = useNavigate();
  const [planReqData, setplanReqData] = useState([]);
  const [couponTypeAttr, setcouponTypeAttr] = useState(true);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [durationAttr, setdurationAttr] = useState(false);
  const [reqData, setreqData] = useState({
    code: "",
    type: "percentage",
    percent_off: "",
    amount_off: "",
    duration: "",
    duration_in_months: "",
    max_redemptions: "",
    expires_at: "",
    user: "",
    status: "",
    plans: []
  });
  const [reqDataError, setreqDataError] = useState({
    code: false,
    type: false,
    percent_off: false,
    amount_off: false,
    duration: false,
    duration_in_months: false,
    max_redemptions: false,
    expires_at: false,
    user: false,
    plans: false
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const getPlans = async () => {
    setLoader(true);
    await axios
      .get("/admin/plans")
      .then((res) => {
        setLoader(false);
        let option = [];
        for (let index = 0; index < res.data.data.length; index++) {
          let attrData = { value: res.data.data[index]._id, label: res.data.data[index].name };
          option.push(attrData);
        }
        setplanData(option);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getPlans();
  }, []);


  const validationStep = () => {
    setToneNameErr("")
    if (!toneName) {
      setToneNameErr("Tone Name field is required");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  const handleSubmit = async (e) => {
    if (validationStep()) {
      // Perform step 2 submission here if needed
      e.preventDefault();

      const paramsData = {
        name: toneName,
      };
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post("/admin/add-tone", paramsData)
        .then((res) => {
          setLoader(false);
          if (res.data.status === true) {
            setTimeout(() => {
              handleClose();
              //getTones();
              window.location.reload();
            }, 2000);
          } else {
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
      setNotification(true);
    }
  }


  const changeHandler = (e) => {
    if (e.target.name == 'type') {
      if (e.target.value == 'flat') {
        setcouponTypeAttr(false);
      } else {
        setcouponTypeAttr(true);
      }
    }
    if (e.target.name == 'duration') {
      if (e.target.value == 'repeating') {
        setdurationAttr(true);
      } else {
        setdurationAttr(false);
      }
    }
    setreqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };

  const planchangeHandler = (e) => {
    setplanReqData(e);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    let dataPush = [];
    if (planReqData.length > 0) {
      dataPush.push(planReqData);
    }

    console.log(planReqData)
    if (reqData.code != '' && reqData.duration != '' && reqData.expires_at != '') {
      setreqDataError({
        code: false,
        max_redemptions: false,
        duration: false,
        expires_at: false,
        plans: false
      })
      let flag = true;
      let flag_v2 = true;
      if (reqData.type == 'percentage') {
        if (reqData.percent_off == '') {
          flag = false;
        }
      } else if (reqData.type == 'flat') {
        if (reqData.amount_off == '') {
          flag = false;
        }
      }
      if (flag) {
        if (reqData.duration == 'repeating') {
          if (reqData.duration_in_months == '') {
            flag_v2 = false;
          }
        }

        setreqDataError({
          amount_off: false,
          percent_off: false,
        })

        if (flag_v2) {
          setreqDataError({
            duration_in_months: false,
          })
          let planDataValue = []
          console.log(dataPush);
          for (let planAttr = 0; dataPush.length > planAttr; planAttr++) {
            planDataValue.push(dataPush[planAttr].value);
          }
          let paramsData = {};
          if (reqData.type == 'percentage') {
            paramsData = {
              code: reqData.code,
              percent_off: reqData.percent_off,
              duration: reqData.duration,
              duration_in_months: (reqData.duration == 'repeating') ? reqData.duration_in_months : 0,
              max_redemptions: reqData.max_redemptions,
              plans: planDataValue,
              expires_at: Moment(reqData.expires_at).format('YYYY-MM-DD'),
            };
          } else {
            paramsData = {
              code: reqData.code,
              amount_off: reqData.amount_off,
              duration: reqData.duration,
              duration_in_months: (reqData.duration == 'repeating') ? reqData.duration_in_months : 0,
              max_redemptions: reqData.max_redemptions,
              plans: planDataValue,
              expires_at: Moment(reqData.expires_at).format('YYYY-MM-DD'),
            };
          }


          axios
            .post("/admin/add-coupon", paramsData)
            .then((res) => {
              console.log(res.data)
              if (res.data.status === true) {
                setNotification(true);
                setIsRedirecting(true);
              }
            })
            .catch((err) => {
              console.log("coupon add", err);
            });



        } else {
          setreqDataError({
            duration_in_months: true,
          })
        }

      } else {
        setreqDataError({
          amount_off: true,
          percent_off: true,
        })
      }

    } else {
      setreqDataError({
        code: true,
        max_redemptions: true,
        duration: true,
        expires_at: true,
        plans: true
      })
    }
  }

  if (isRedirecting) {
    navigate('/coupon-management');
  }

  return (
    <>
    {loader && <LoadingData />}
    <DashboardNavbar />
    <DashboardLayout>
      <MDBox className="primary_container" pt={6} pb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center" 
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  sx={{ textTransform: 'uppercase' }}
                >
                  Add Coupon
                </MDTypography>
              </MDBox>              
      
              <MDBox
                component="form"
                role="form"
                onSubmit={submitHandler}
                px={2} py={3}
              >
                {notification && (
                  <Box mb={2}>
                    <MDAlert color="success">
                        Your coupon has been added
                    </MDAlert>
                  </Box>
                )}

                <Box>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Plans (optional)</FormLabel>
                      <MultiSelect
                        onChange={planchangeHandler}
                        name="plans"
                        value={planReqData}
                        defaultValue={reqData.plans}
                        options={planData}
                        placeholder="All Plans"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: '14px',
                            minHeight: '44px',
                            height: '44px',
                            
                          }),
                          option: (base) => ({
                            ...base,
                            fontSize: '14px',
                          }),
                        }}
                      />
                      {reqDataError.plans && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The plan can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Coupon Code<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="code"
                        name="code"
                        value={reqData.code}
                        onChange={changeHandler}
                        error={reqDataError.code}
                      />
                      {reqDataError.code && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The coupon code can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Coupon Type<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="formBasicOrganization"
                        name="type"
                        value={reqData.type}
                        onChange={changeHandler}
                        style={{ height: 44 }}
                      >
                        <MenuItem value="percentage">
                          Percentage
                        </MenuItem>
                        <MenuItem value="flat">
                          Flat
                        </MenuItem>
                      </Select>
                      {reqDataError.type && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The coupon type can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  {(couponTypeAttr) ? (
                    <Box mb={2}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Percentage Off (%)<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MDInput
                          type="number"
                          name="percent_off"
                          value={reqData.percent_off}
                          onChange={changeHandler}
                          error={reqDataError.percent_off}
                        />
                        {reqDataError.percent_off && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The coupon percentage can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>
                  ) : (
                    <Box mb={2}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Flat Off ($)<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MDInput
                          type="number"
                          name="amount_off"
                          value={reqData.amount_off}
                          onChange={changeHandler}
                          error={reqDataError.amount_off}
                        />
                        {reqDataError.amount_off && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The coupon flat off can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>
                  )}

                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Coupon Duration<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="formBasicOrganization"
                        name="duration"
                        value={reqData.duration}
                        onChange={changeHandler}
                        style={{ height: 44 }}
                      >
                        <MenuItem value="forever">
                          Forever
                        </MenuItem>
                        <MenuItem value="once">
                          Once
                        </MenuItem>
                        <MenuItem value="repeating">
                          Repeating
                        </MenuItem>
                      </Select>
                      {reqDataError.duration && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The Coupon Duration can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  {(durationAttr) ? (
                    <Box mb={2}>
                      <FormControl fullWidth>
                        <FormLabel className="form-label">Duration in Month<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                        <MDInput
                          type="number"
                          name="duration_in_months"
                          value={reqData.duration_in_months}
                          onChange={changeHandler}
                          error={reqDataError.duration_in_months}
                        />
                        {reqDataError.duration_in_months && (
                          <MDTypography
                            variant="caption"
                            color="error"
                          >
                            The coupon Duration can not be null
                          </MDTypography>
                        )}
                      </FormControl>
                    </Box>
                  ) : (
                    <></>
                  )}


                  <Box mb={2}>
                    <FormControl fullWidth>
                      <FormLabel className="form-label">Coupon Expire Date<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                      <MDInput
                        type="date"
                        name="expires_at"
                        value={reqData.expires_at}
                        onChange={changeHandler}
                        error={reqDataError.expires_at}
                      />
                      {reqDataError.expires_at && (
                        <MDTypography
                          variant="caption"
                          color="error"
                        >
                          The coupon expire can not be null
                        </MDTypography>
                      )}
                    </FormControl>
                  </Box>

                  <MDButton color="info" type="submit">
                    Save changes
                  </MDButton>
                </Box>

              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
    </>
  );
};

export default AddCoupon;
