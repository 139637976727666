// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import axios from "../../axios/axios";
// import Switch from "react-switch";
import moment from "moment";
import { useParams, Link } from "react-router-dom";

// Images
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

import { Box, IconButton, Menu, MenuItem, Paper } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MDAlert from "components/MDAlert";
import Modal from "@mui/material/Modal";
import MDButton from "components/MDButton";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function data() {
  const [loader, setLoader] = useState(false);
  const [prompt, setprompt] = useState([]);
  const [switchStates, setSwitchStates] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };


  const handleClick = (event, getId) => {
    setAnchorEl(event.currentTarget);
    setCurrentItemId(getId._id)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );

  // const Job = ({ title, description }) => (
  //   <MDBox lineHeight={1} textAlign="left">
  //     <MDTypography
  //       display="block"
  //       variant="caption"
  //       color="text"
  //       fontWeight="medium"
  //     >
  //       {title}
  //     </MDTypography>
  //     <MDTypography variant="caption">{description}</MDTypography>
  //   </MDBox>
  // );

  const getPrompt = async () => {
    setLoader(true);
    await axios
      .get("/admin/prompts")
      .then((res) => {
        setLoader(false);
        setprompt(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getPrompt();
  }, []);

  const handlePlanDelete = () => {
    axios
      .post("/admin/delete-prompt", { prompt_id: currentItemId })
      .then((res) => {
        if (res.data.status === true) {
          setTimeout(() => {
            closeDeleteModal();
            getPrompt();
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("deleteUser err", err);
      });
    setNotification(true);
  };

  const handleChange = (id) => (nextChecked) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: nextChecked,
    }));

    const paramsData = {
      plan_id: id,
      status: nextChecked === true ? "Active" : "Inactive",
    };

    axios
      .post("/admin/plan-status-update", paramsData)
      .then((res) => {
        getPrompt();
      })
      .catch((err) => {
        console.log("update status err", err);
      });
  };

  useEffect(() => {
    // Initialize the switchStates with the API data
    const initialSwitchStates = prompt.reduce((acc, item) => {
      acc[item._id] = item.status === "Active" ? true : false;
      return acc;
    }, {});

    setSwitchStates(initialSwitchStates);
  }, [prompt]);

  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 600,
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // };

  // const rowsData = prompt.map((item) => {
  //   return {
  //     type: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {item.type}
  //       </MDTypography>
  //     ),
  //     description: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {item.description}
  //       </MDTypography>
  //     ),
  //     organization: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {item.org_info.map((org) => (<><Stack direction="row" spacing={1} className="mt-2"><Chip label={org.name} /></Stack></>))}

  //       </MDTypography>
  //     ),
  //     platforms: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {item.platforms.map((plat) => (<><Stack direction="row" spacing={1} className="mt-2"><Chip label={plat} variant="outlined" /></Stack></>))}

  //       </MDTypography>
  //     ),
  //     createdAt: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {moment(item.createdAt).format("MM-DD-YYYY HH:mm:ss")}
  //       </MDTypography>
  //     ),
  //     // updatedAt: (
  //     //   <MDTypography variant="caption" color="text" fontWeight="medium">
  //     //     {moment(item.updatedAt).format("MM-DD-YYYY HH:mm:ss")}
  //     //   </MDTypography>
  //     // ),
  //     action: (
  //       <MDBox>
  //         <IconButton
  //           aria-label="more"
  //           aria-controls="dot-menu"
  //           aria-haspopup="true"
  //           onClick={handleClick}

  //         >
  //           <MoreVertIcon data-id={item._id} />
  //         </IconButton>
  //         <Menu
  //           id="dot-menu"
  //           anchorEl={anchorEl}
  //           open={Boolean(anchorEl)}
  //           onClose={handleClose}
  //         >
  //           <MenuItem
  //             component={Link} // Use Link component here
  //             to={`/prompt-management-update/${updateId}`}
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             mr={2}
  //           >
  //             Edit
  //           </MenuItem>
  //           <MenuItem
  //             component="a"
  //             href="#"
  //             variant="caption"
  //             color="text"
  //             fontWeight="medium"
  //             onClick={() => {
  //               openDeleteModal();
  //               setDeleteItemId(updateId);
  //             }}
  //           >
  //             Delete
  //           </MenuItem>
  //         </Menu>
  //         {deleteItemId === updateId && (
  //           <Modal
  //             open={isDeleteModalOpen}
  //             onClose={closeDeleteModal}
  //             centered
  //             size="lg"
  //             aria-labelledby="modal-modal-title"
  //             aria-describedby="modal-modal-description"
  //           >
  //             <MDBox sx={style} className="email_modal org_modalll">
  //               {notification && (
  //                 <MDAlert color="info" mt="20px">
  //                   <MDTypography variant="body2" color="white">
  //                     Your plan has been deleted
  //                   </MDTypography>
  //                 </MDAlert>
  //               )}
  //               <MDTypography
  //                 className="text-center"
  //                 id="modal-modal-title"
  //                 variant="h6"
  //                 component="h2"
  //               >
  //                 Delete Confirmation
  //               </MDTypography>
  //               <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
  //                 Are you sure you want to delete this item?
  //               </MDTypography>
  //               <div className="btn__group">
  //                 <MDButton
  //                   variant="secondary"
  //                   className="yes_but"
  //                   onClick={() => {
  //                     handlePlanDelete(updateId);
  //                   }}
  //                 >
  //                   Yes
  //                 </MDButton>

  //                 <MDButton
  //                   variant="danger"
  //                   className="no_but"
  //                   onClick={() => {
  //                     closeDeleteModal();
  //                   }}
  //                 >
  //                   No
  //                 </MDButton>
  //               </div>
  //               <MDButton
  //                 variant="secondary"
  //                 className="close_buutt"
  //                 onClick={closeDeleteModal}
  //               >
  //                 X
  //               </MDButton>
  //             </MDBox>
  //           </Modal>
  //         )}
  //       </MDBox>
  //     ),
  //   };
  // });

  return {
    // columns: [
    //   { Header: "type", accessor: "type", width: "45%", align: "left" },
    //   { Header: "description", accessor: "description", align: "center" },
    //   { Header: "organization", accessor: "organization", align: "center" },
    //   { Header: "platforms", accessor: "platforms", align: "center" },
    //   { Header: "created", accessor: "createdAt", align: "center" },
    //   { Header: "updated", accessor: "updatedAt", align: "center" },
    //   // { Header: "action", accessor: "action", align: "center" },
    // ],

    columns: [
      {
        name: 'SL No',
        selector: (row) => row.sl_no,
        maxWidth: '90px',
        minWidth: '90px',
      },
      {
        name: 'Prompt Type',
        selector: row => row.type,
        //maxWidth: '130px',
        minWidth: '130px',
        sortable: true,
      },
      {
        name: 'Prompt Description',
        selector: row => {
          return (
            <div style={{whiteSpace:'normal'}}>
              {row.description}
            </div>
          )
        },
        //maxWidth: '200px',
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'Organization/Partners',
        selector: row => {
          return (
            <Stack style={{whiteSpace: 'normal'}} spacing={.5} direction="row" useFlexGap flexWrap="wrap">
              {row.org_info.map((org) => (
                <Chip variant="outlined" label={org.name} />
              ))}
            </Stack>
          )
        },
        //maxWidth: '350px',
        minWidth: '350px',
        //sortable: true,
      },
      {
        name: 'Prompt Platforms',
        selector: row => {
          return (
            <Stack style={{whiteSpace: 'normal'}} spacing={.5} direction="row" useFlexGap flexWrap="wrap">
              {row.platforms.map((plat) => (
                <Chip label={plat} variant="outlined" />
              ))}
            </Stack>
          )
        },
        //maxWidth: '250px',
        minWidth: '250px',
      },
      {
        name: 'Hidden Prompt',
        selector: row => row?.hidden_prompt,
        //maxWidth: '180px',
        minWidth: '180px',
        sortable: true,
      },
      {
        name: 'Prompt Created',
        selector: row => moment(row.createdAt).format("MM-DD-YYYY HH:mm:ss"),
        sortable: true,
        sortField: 'created',
        //maxWidth: '150px',
        minWidth: '150px',
      },
      {
        name: 'Action',
        maxWidth:'80px',
        minWidth: '80px',
        selector: row => {
          return (
            <MDBox>
              <IconButton
                aria-label="more"
                aria-controls="dot-menu"
                aria-haspopup="true"
                onClick={(e) => handleClick(e, row)}

              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="dot-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem
                  component={Link} // Use Link component here
                  to={`/prompt-management-update/${currentItemId}`}
                  variant="caption"
                  color="text"
                >
                  Edit
                </MenuItem>
                <MenuItem
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  onClick={() => {
                    openDeleteModal();
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
              {currentItemId === row._id && (
                <Modal
                  className="modal"
                  open={isDeleteModalOpen}
                  onClose={closeDeleteModal}
                >
                  <Paper className="modal-dialog modal-sm">
                    <Box className="modal-content">
                      {notification && (
                        <Box mb={2}>
                          <MDAlert color="success">
                              Your plan has been deleted
                          </MDAlert>
                        </Box>
                      )}
                      <Box className="modal-header">
                        <MDTypography
                          className="text-center"
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Delete Confirmation
                        </MDTypography>
                        <IconButton
                          className="close_button"
                          onClick={closeDeleteModal}
                        >
                          <CloseOutlinedIcon />
                        </IconButton>
                      </Box>
                      <Box className="modal-body">
                        <MDTypography>
                          Are you sure you want to delete this item?
                        </MDTypography>
                      </Box>
                      <Box className="modal-footer">
                      <MDButton
                          variant="text"
                          color="info"
                          onClick={() => {
                            closeDeleteModal();
                          }}
                        >
                          No
                        </MDButton>
                        <MDButton
                          color="error"
                          onClick={() => {
                            handlePlanDelete();
                          }}
                        >
                          Yes
                        </MDButton>
                        
                      </Box>                      
                    </Box>
                  </Paper>
                </Modal>
              )}
            </MDBox>
          )
        },
        //sortable: true,
      }
    ],

    rows: prompt,

    loader: loader,
  };
}
