/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import axios from "../../axios/axios"
import moment from "moment";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function orgdata() {
  const [loader, setLoader] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionsDesc, setTransactionsDesc] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const getFilterTransactions = async (orgIdAttr) => {
    setLoader(true);
    await axios
      .get("/admin/transactions/" + orgIdAttr)
      .then((res) => {
        setLoader(false);
        setTransactions(res.data.data);
        let daT = [];
        let ind = 1;
        for (let i = res.data.data.length - 1; i >= 0; i--) {
          let dataOb = Object.assign({ sl_no: ind }, res.data.data[i]);
          daT.push(dataOb);
          ind++;
        }
        setTransactionsDesc(daT);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };


  const getTransactions = async () => {
    setLoader(true);
    await axios
      .get("/admin/transactions")
      .then((res) => {
        setLoader(false);
        setTransactions(res.data.data);
        let daT = [];
        let ind = 1;
        for (let i = res.data.data.length - 1; i >= 0; i--) {
          let dataOb = Object.assign({ sl_no: ind }, res.data.data[i]);
          daT.push(dataOb);
          ind++;
        }
        setTransactionsDesc(daT);
        // console.log(daT);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  }
  useEffect(() => {
    getTransactions()
  }, [])

  // const rowsData = transactions.map((item) => {
  //   return (
  //     {
  //       name: <Author name={`${item.user_info.name}`} email="" />,
  //       phone: <Job title={`${item.user_info.phone}`} description="" />,
  //       email: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {`${item.user_info.email}`}
  //         </MDTypography>
  //       ),
  //       status: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.status}
  //         </MDTypography>
  //       ),
  //       amount: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.total_amount}
  //         </MDTypography>
  //       ),
  //       currency: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.gateway_response.currency}
  //         </MDTypography>
  //       ),
  //       payment_method: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.gateway_response.payment_method_types}
  //         </MDTypography>
  //       ),
  //       transaction_id: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.gateway_transaction_id}
  //         </MDTypography>
  //       ),
  //       created: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {moment(item.createdAt).format("MM-DD-YYYY HH:mm:ss")}
  //         </MDTypography>
  //       )
  //     }
  //   )

  // })

  return {
    // columns: [
    //     { Header: "name", accessor: "name", width: "45%", align: "left" },
    //     { Header: "email", accessor: "email", align: "left" },
    //     { Header: "phone", accessor: "phone", align: "left" },
    //     { Header: "amount", accessor: "amount", align: "left" },
    //     { Header: "currency", accessor: "currency", align: "left" },
    //     { Header: "payment method", accessor: "payment_method", align: "left" },
    //     { Header: "transaction id", accessor: "transaction_id", align: "left" },
    //     { Header: "created", accessor: "created", align: "left" },
    //     { Header: "status", accessor: "status", align: "center" },
    //   ],

    columns: [
      {
        name: 'Sl No.',
        selector: row => row.sl_no,
        maxWidth: '90px',
        minWidth: '90px',
        sortable: true,
      },
      {
        name: 'Member Name',
        selector: row => row.user_info.name,
        //maxWidth: '170px',
        minWidth: '170px',
        sortable: true,
      },
      {
        name: 'Organization Name',
        selector: row => row?.org_info?.name,
        //maxWidth: '250px',
        minWidth: '250px',
        sortable: true,
      },
      {
        name: "Member Contact Details",
        selector: (row) => {
          return (
            <MDBox lineHeight={1}>
              Email: <MDTypography variant="caption">{row.user_info.email}</MDTypography>
              <br />
              Phone: <MDTypography variant="caption">{row.user_info.phone}</MDTypography>
            </MDBox>
          );
        },
        //maxWidth: "250px",
        minWidth: "250px",
        sortable: true,
      },
      // // 
      // {
      //   name: 'Member Email',
      //   selector: row => row.user_info.email,
      //   maxWidth: '250px',
      //   minWidth: '250px',
      //   sortable: true,
      // },
      // {
      //   name: 'Member Phone',
      //   selector: row => row.user_info.phone,
      //   maxWidth: '180px',
      //   minWidth: '180px',
      //   //sortable: true,
      // },
      {
        name: 'Amount',
        selector: row => "$"+row.total_amount,
        //maxWidth: '130px',
        minWidth: '130px',
        //sortable: true,
      },
      // {
      //   name: 'Currency',
      //   selector: row => row.gateway_response.currency,
      //   width: '150px',
      //   sortable: true,
      // },
      // {
      //   name: 'Payment Method',
      //   selector: row => row?.gateway_response?.payment_method_types,
      //   maxWidth: '160px',
      //   minWidth: '160px',
      //   sortable: true,
      // },
      // {
      //   name: 'Transaction Id',
      //   selector: row => row.gateway_transaction_id,
      //   maxWidth: '200px',
      //   minWidth: '200px',
      //   //sortable: true,
      // },
      {
        name: 'Payment date',
        selector: row => moment(row.createdAt).format("MM-DD-YYYY HH:mm:ss"),
        sortable: true,
        sortField: 'createdAt',
        //maxWidth: '200px',
        minWidth: '200px', // set default sorting order to descending
      },
      {
        name: 'Payment Status',
        selector: row => row.status,
        sortable: true,
      }
    ],

    rows: transactionsDesc,
    loaders: loader,
    getFilterTransactions: getFilterTransactions

  };
}
