import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {
  Input,
  InputLabel,
  Button,
  Grid,
  Card,
  Box,
  Tab,
  Tabs,
  Typography,
  Switch,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import LoadingData from "../../loader/loader";
import axios from "../../axios/axios";
import { FormLabel } from "react-bootstrap";

function profile() {
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showNewCpass, setShowNewCpass] = useState(false);
  const [switchStates, setSwitchStates] = useState(false);
  const [devKey, setDevKey] = useState(false);
  const [liveKey, setLiveKey] = useState(false);
  const [mess, setMess] = useState("");
  const [value, setValue] = useState(0);

  const [profileData, setProfileData] = useState({
    adminName: "",
    adminPhone: "",
    adminEmail: "",
    oldPassword: "",
    newPassword: "",
    newCpassword: "",
  });
  const [errors, setErrors] = useState({
    adminNameError: false,
    adminPhoneError: false,
    oldPasswordError: false,
    newPasswordError: false,
    newCpasswordError: false,
  });

  const [paymentDetails, setPaymentDetails] = useState({
    devApiKey: "",
    liveApiKey: "",
  });
  const [paymentDetailsErrors, setPaymentDetailsErrors] = useState({
    devApiKeyError: false,
    liveApiKeyError: false,
  });

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 2000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  const changeHandlerPayment = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOldPasswordVisibility = () => {
    setShowOldPass((prevShowPassword) => !prevShowPassword);
  };
  const handleNewPasswordVisibility = () => {
    setShowNewPass((prevShowPassword) => !prevShowPassword);
  };
  const handleNewCpasswordVisibility = () => {
    setShowNewCpass((prevShowPassword) => !prevShowPassword);
  };

  const handleApiKeyVisibility = () => {
    setDevKey((prevShowPassword) => !prevShowPassword);
  };
  const handleSecretKeyVisibility = () => {
    setLiveKey((prevShowPassword) => !prevShowPassword);
  };

  const getSuperAdmin = async () => {
    setLoader(true);
    await axios
      .get("/admin/profile-info")
      .then((res) => {
        setLoader(false);

        const responseData = res.data.data;
        setProfileData({
          adminName: responseData.name,
          adminEmail: responseData.email,
          adminPhone: responseData.phone,
        });
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const getPaymentSettings = async () => {
    setLoader(true);
    await axios
      .post("/admin/setting-by-name", { name: "Payment Setting" })
      .then((res) => {
        setLoader(false);
        setPaymentDetails({
          devApiKey: res.data.data.details.stripe_dev_secret,
          liveApiKey: res.data.data.details.stripe_live_secret,
        });
        setSwitchStates(res.data.data.details.mode === "Dev" ? false : true);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getSuperAdmin();
    getPaymentSettings();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    let newErrors = {};

    if (value === 0) {
      newErrors = {
        adminNameError: profileData.adminName
          ? profileData.adminName.trim().length === 0
          : true,
        adminPhoneError: profileData.adminPhone
          ? profileData.adminPhone.length === 0
          : true,
      };
    } else if (value === 1) {
      newErrors = {
        oldPasswordError: profileData.oldPassword
          ? profileData.oldPassword.trim().length === 0
          : true,
        newPasswordError: profileData.newPassword
          ? profileData.newPassword.trim().length === 0 ||
            profileData.newPassword.trim().length < 6
          : true,
        newCpasswordError:
          profileData.newCpassword === "" ||
          profileData.newCpassword !== profileData.newPassword,
      };
    }

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return;
    }

    const paramsData = {
      name: profileData.adminName,
      phone: profileData.adminPhone,
      old_password: profileData.oldPassword,
      new_password: profileData.newPassword,
    };

    setLoader(true);
    axios
      .post("/admin/update-profile", paramsData)
      .then((res) => {
        setLoader(false);
        if (res.data.status === true) {
          setMess("Profile update successfull");
        } else {
          setMess("Profile update failed");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("Plan add", err);
      });

    // Reset errors based on the value
    if (value === 0) {
      setErrors({
        adminNameError: false,
        adminPhoneError: false,
      });
    } else if (value === 1) {
      setErrors({
        oldPasswordError: false,
        newPasswordError: false,
        newCpasswordError: false,
      });
    }

    setNotification(true);
  };

  const handleChangePayment = async (e) => {
    e.preventDefault();
  
    let newErrors = {
      devApiKeyError: paymentDetails.devApiKey ? paymentDetails.devApiKey.trim().length === 0 : true,
      liveApiKeyError: paymentDetails.liveApiKey ? paymentDetails.liveApiKey.length === 0 : true,
    };
  
    if (Object.values(newErrors).some((error) => error)) {
      setPaymentDetailsErrors(newErrors); // Use setPaymentDetailsErrors instead of setErrors
      return;
    }
  
    const paramsData = {
      name: "Payment Setting",
      details: {
        stripe_dev_secret: paymentDetails.devApiKey,
        stripe_live_secret: paymentDetails.liveApiKey,
        mode: switchStates === true ? "Live" : "Dev",
      },
    };
  
    setLoader(true);
    axios
      .post("/admin/update-setting", paramsData)
      .then((res) => {
        setLoader(false);
        if (res.data.status === true) {
          setMess("Payment setting update successful");
        } else {
          setMess("Payment setting update failed");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("update", err);
      });
  
    setPaymentDetailsErrors({
      devApiKeyError: false,
      liveApiKeyError: false,
    });
  
    setNotification(true);
  };
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <>
      {loader && <LoadingData />}
      <DashboardNavbar />
      <DashboardLayout>
        {notification && (
          <MDAlert color="info" mt="20px">
            <MDTypography variant="body2" color="white">
              {mess}
            </MDTypography>
          </MDAlert>
        )}
        <MDBox pt={3} pb={4} component="form" role="form">
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h5" color="white">
                    Edit Profile
                  </MDTypography>
                </MDBox>
                <Box sx={{ px: 2, py: 5 }}>
                  {/* <Box mb={3}>
                    <MDInput
                      type="text"
                      fullWidth
                      label="Your Name"
                      variant="outlined"
                      name="adminName"
                      value={profileData.adminName}
                      onChange={changeHandler}
                    />
                    {errors.adminNameError && (
                      <MDTypography
                        variant="caption"
                        color="error"
                        fontWeight="light"
                      >
                        The name can not be null
                      </MDTypography>
                    )}
                  </Box>
                  <Box mb={3}>
                    <MDInput
                      type="email"
                      fullWidth
                      label="Your Email"
                      variant="outlined"
                      name="email"
                      ReadOnly
                      value={profileData.adminEmail}
                      onChange={changeHandler}
                    />
                  </Box>
                  <Box mb={3}>
                    <MDInput
                      type="number"
                      fullWidth
                      label="Your Phone No."
                      variant="outlined"
                      name="adminPhone"
                      value={profileData.adminPhone}
                      onChange={changeHandler}
                    />
                    {errors.adminPhoneError && (
                      <MDTypography
                        variant="caption"
                        color="error"
                        fontWeight="light"
                      >
                        The phone number can not be null
                      </MDTypography>
                    )}
                  </Box>
                  <Box mb={3}>
                    <MDInput
                      type={showOldPass ? "text" : "password"}
                      fullWidth
                      label="Old Password"
                      variant="outlined"
                      name="oldPassword"
                      value={profileData.oldPassword}
                      onChange={changeHandler}
                      error={errors.oldPasswordError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleOldPasswordVisibility}
                            >
                              {showOldPass ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.oldPasswordError && (
                      <MDTypography
                        variant="caption"
                        color="error"
                        fontWeight="light"
                      >
                        The old password can not be null
                      </MDTypography>
                    )}
                  </Box>
                  <Box mb={3} >
                    <MDInput
                      type={showNewPass ? "text" : "password"}
                      fullWidth
                      label="New Password"
                      variant="outlined"
                      name="newPassword"
                      ReadOnly
                      value={profileData.newPassword}
                      onChange={changeHandler}
                      error={errors.newPasswordError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleNewPasswordVisibility}
                            >
                              {showNewPass ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.newPasswordError && (
                      <MDTypography
                        variant="caption"
                        color="error"
                        fontWeight="light"
                      >
                        The new password can not be null
                      </MDTypography>
                    )}
                  </Box>
                  <Box mb={3}>
                    <MDInput
                      type={showNewCpass ? "text" : "password"}
                      fullWidth
                      label="Confirm Password"
                      variant="outlined"
                      name="newCpassword"
                      value={profileData.newCpassword}
                      onChange={changeHandler}
                      error={errors.newCpasswordError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleNewCpasswordVisibility}
                            >
                              {showNewCpass ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.newCpasswordError && (
                      <MDTypography
                        variant="caption"
                        color="error"
                        fontWeight="light"
                      >
                        Passwords do not match
                      </MDTypography>
                    )}
                  </Box> */}

                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="form tabs example"
                    >
                      <Tab label="Your Profile Details" />
                      <Tab label="Change Password" />
                      <Tab label="Payment Settings" />
                    </Tabs>

                    <Box mx={-3}>
                      <TabPanel value={value} index={0}>
                        <Box mb={3}>
                          <MDInput
                            type="text"
                            fullWidth
                            label="Your Name"
                            variant="outlined"
                            name="adminName"
                            value={profileData.adminName}
                            onChange={changeHandler}
                          />
                          {errors.adminNameError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              fontWeight="light"
                            >
                              The name can not be null
                            </MDTypography>
                          )}
                        </Box>
                        <Box mb={3}>
                          <MDInput
                            type="email"
                            fullWidth
                            label="Your Email"
                            variant="outlined"
                            name="email"
                            ReadOnly
                            value={profileData.adminEmail}
                            onChange={changeHandler}
                          />
                        </Box>
                        <Box mb={3}>
                          <MDInput
                            type="number"
                            fullWidth
                            label="Your Phone No."
                            variant="outlined"
                            name="adminPhone"
                            value={profileData.adminPhone}
                            onChange={changeHandler}
                          />
                          {errors.adminPhoneError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              fontWeight="light"
                            >
                              The phone number can not be null
                            </MDTypography>
                          )}
                        </Box>
                        <Box>
                          <MDButton
                            fullWidth
                            variant="gradient"
                            color="info"
                            type="button"
                            onClick={submitHandler}
                          >
                            Save Changes
                          </MDButton>
                        </Box>
                      </TabPanel>

                      <TabPanel value={value} index={1}>
                        <Box mb={3}>
                          <MDInput
                            type={showOldPass ? "text" : "password"}
                            fullWidth
                            label="Old Password"
                            variant="outlined"
                            name="oldPassword"
                            value={profileData.oldPassword}
                            onChange={changeHandler}
                            error={errors.oldPasswordError}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={handleOldPasswordVisibility}
                                  >
                                    {showOldPass ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors.oldPasswordError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              fontWeight="light"
                            >
                              The old password can not be null
                            </MDTypography>
                          )}
                        </Box>
                        <Box mb={3}>
                          <MDInput
                            type={showNewPass ? "text" : "password"}
                            fullWidth
                            label="New Password"
                            variant="outlined"
                            name="newPassword"
                            value={profileData.newPassword}
                            onChange={changeHandler}
                            error={errors.newPasswordError}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={handleNewPasswordVisibility}
                                  >
                                    {showNewPass ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors.newPasswordError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              fontWeight="light"
                            >
                              The new password can not be null
                            </MDTypography>
                          )}
                        </Box>
                        <Box mb={3}>
                          <MDInput
                            type={showNewCpass ? "text" : "password"}
                            fullWidth
                            label="Confirm Password"
                            variant="outlined"
                            name="newCpassword"
                            value={profileData.newCpassword}
                            onChange={changeHandler}
                            error={errors.newCpasswordError}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={handleNewCpasswordVisibility}
                                  >
                                    {showNewCpass ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors.newCpasswordError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              fontWeight="light"
                            >
                              Passwords do not match
                            </MDTypography>
                          )}
                        </Box>
                        <Box>
                          <MDButton
                            fullWidth
                            variant="gradient"
                            color="info"
                            type="submit"
                            onClick={submitHandler}
                          >
                            Save Changes
                          </MDButton>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <Box mb={3}>
                          <MDInput
                            type={devKey ? "text" : "password"}
                            fullWidth
                            label="Dev Stripe API Secret"
                            name="devApiKey"
                            variant="outlined"
                            value={paymentDetails.devApiKey}
                            onChange={changeHandlerPayment}
                            error={paymentDetailsErrors.devApiKeyError}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={handleApiKeyVisibility}
                                  >
                                    {devKey ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                           {paymentDetailsErrors.devApiKeyError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              fontWeight="light"
                            >
                              The dev api key secret can not be null
                            </MDTypography>
                          )}
                        </Box>
                        <Box mb={3}>
                          <MDInput
                            type={liveKey ? "text" : "password"}
                            fullWidth
                            label="Live Stripe API Secret"
                            variant="outlined"
                            name="liveApiKey"
                            value={paymentDetails.liveApiKey}
                            onChange={changeHandlerPayment}
                            error={paymentDetailsErrors.liveApiKeyError}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={handleSecretKeyVisibility}
                                  >
                                    {liveKey ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {paymentDetailsErrors.liveApiKeyError && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              fontWeight="light"
                            >
                              The live api key secret can not be null
                            </MDTypography>
                          )}
                        </Box>
                        <Box style={{ alignItems: "center" }}>
                          <FormLabel className="form-label">
                            Dev Payment Mode
                          </FormLabel>
                          <Switch
  onChange={() => setSwitchStates((prevState) => !prevState)} // Toggle between true and false
  checked={switchStates}
  className="react-switch"
  onColor="#49a3f1"
/>
                          <FormLabel className="form-label">
                            Live Payment Mode
                          </FormLabel>
                          <MDButton
                            fullWidth
                            variant="gradient"
                            color="info"
                            type="submit"
                            onClick={handleChangePayment}
                          >
                            Save Changes
                          </MDButton>
                        </Box>
                      </TabPanel>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default profile;
