import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useParams, Link, useNavigate} from "react-router-dom";
import axios from "../../axios/axios";

const creditUpdate = () => {
  const { id } = useParams(); // Access URL parameters
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [notification, setNotification] = useState(false);
  const [creditUpdate, setCreditUpdate] = useState({
    name: "",
    credit: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
    creditError: false,
  });

  const getPlanById = async () => {
    setLoader(true);
    await axios
      .get(`/admin/credit-plan/${id}`)
      .then((res) => {
        setLoader(false);

        const responseData = res.data.data;

        setCreditUpdate({
          name: responseData._id,
          credit: responseData.credit
        });
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(()=>{
    getPlanById();
  },[])

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 5000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    setCreditUpdate({
      ...creditUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (creditUpdate.name.length === 0) {
      setErrors({ ...errors,credit_plan_idError: true });
      return;
    }
    if (creditUpdate.credit.length === 0) {
      setErrors({ ...errors, creditError: true });
      return;
    }

    // call api for update
    const paramsData = {
      credit_plan_id: creditUpdate.name,
      credit: creditUpdate.credit
  }
    axios
      .post("/admin/credit-plan-update", paramsData)
      .then((res) => {
        if(res.data.status===true){
          setIsRedirecting(true);
        }
      })
      .catch((err) => {
        console.log("update err", err);
      });

    // reset errors
    setErrors({
      nameError: false,
      creditError: false,
    });

    setNotification(true);
  };

  if (isRedirecting) {
    setTimeout(()=>{
      navigate('/uses-management');
    },2000)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {/* <Header name={user.name}> */}
      {notification && (
        <MDAlert color="info" mt="20px">
          <MDTypography variant="body2" color="white">
            Your credit has been updated
          </MDTypography>
        </MDAlert>
      )}
      <MDBox
        component="form"
        role="form"
        // onSubmit={submitHandler}
        display="flex"
        flexDirection="column"
      >
        <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            mr={2}
          >
            <MDTypography
              variant="body2"
              color="text"
              ml={1}
              fontWeight="regular"
            >
              Plane Id
            </MDTypography>
            <MDBox mb={2} width="100%">
              <MDInput
                readOnly
                type="name"
                fullWidth
                name="name"
                value={creditUpdate.name}
                onChange={changeHandler}
                error={errors.nameError}
              />
              {errors.nameError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The plan name can not be null
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            ml={2}
          >
            <MDTypography
              variant="body2"
              color="text"
              ml={1}
              fontWeight="regular"
            >
              Credit
            </MDTypography>
            <MDBox mb={1} width="100%">
              <MDInput
                type="text"
                fullWidth
                name="credit"
                value={creditUpdate.credit}
                onChange={changeHandler}
                error={errors.creditError}
              />
              {errors.creditError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The credit can not be null
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      <div className="invitation_submit">
        <MDButton
          onClick={submitHandler}
          type="button"
          className="type_submit_butt"
        >
          Submit
        </MDButton>
      </div>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default creditUpdate;
