import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// import Footer from "examples/Footer";
import LoadingData from "../../loader/loader";
import axios from "../../axios/axios";
import { Box, Card, FormControl, FormLabel } from "@mui/material";

function forgotPassword() {
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState(false);
  const [profileData, setProfileData] = useState({
    adminEmail: "",
  });
  const [errors, setErrors] = useState({
    adminEmailError: false,
  });

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 2000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    let newErrors = {
      adminEmailError: profileData.adminEmail
        ? !validateEmail(profileData.adminEmail)
        : true,
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return;
    }
    const paramsData = {
      email: profileData.adminEmail,
    };

    setLoader(true);
    axios
      .post("/forgot-password", paramsData)
      .then((res) => {
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
    setErrors({
      adminEmailError: false,
    });

    setNotification(true);
  };
  return (
    <>
      <BasicLayoutLanding image={bgImage}>
        {loader && <LoadingData />}
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
          >          
            <MDTypography variant="h4" fontWeight="medium" color="white">
              Forgot Password
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              component="form"
              role="form"
              onSubmit={submitHandler}
              sx={{textAlign: 'left'}}
            >
              {notification && (
                <Box mb={2}>
                  <MDAlert color="success">Password reset mail sent successfuly</MDAlert>
                </Box>
              )}
              <Box mb={2}>
                <FormControl fullWidth>
                  <FormLabel className="form-label">Your Email</FormLabel>
                    <MDInput
                      type="email"
                      name="adminEmail"
                      value={profileData.adminEmail}
                      onChange={changeHandler}
                      error={errors.adminEmailError}
                    />
                    {errors.adminEmailError && (
                      <MDTypography
                        variant="caption"
                        color="error"
                      >
                        The email can not be null
                      </MDTypography>
                    )}
                </FormControl>
              </Box>
              <MDButton variant="gradient" color="info" type="submit" fullWidth>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayoutLanding>
    </>
  );
}

export default forgotPassword;
